export const HOME = '/';
export const OUR_VISION = '/our-vision';
export const OUR_INNOVATION = '/our-innovation';
export const DELIVERY = '/delivery';
export const WASTE_RECYCLING = '/waste-recycling';
export const SOCIAL = '/social';
export const DOCUMENTS = '/documents';
export const ROAD_MAP = '/road-map';
export const NEWS_ARTICLE = '/news/:id';
export const BLOG_POST = '/blog/:id';

export const STRATEGY = '/our-vision/strategy';
export const NET_ZERO = '/our-vision/net-zero';
export const REDUCE_REUSE_RECYCLE = '/our-vision/reduce-reuse-recycle';
export const RESPONSIBLE_ENERGY_RESOURCES =
  '/our-vision/responsible-energy-and-resources';
export const UN_SDGS = '/our-vision/un-sdgs';

export const VISION_THIRTY = '/our-innovation/vision-thirty';
export const AUTO_DELIVERIES = '/our-innovation/autonomous-deliveries';
export const SUSTAINABLE_FLEET = '/our-innovation/sustainable-fleet';

export const KEEPING_TRACK = '/delivery/keeping-track';
export const CHARGING_SOLUTIONS = '/delivery/charging-solutions';

export const REDUCE = '/waste-recycling/reduce';
export const REUSE = '/waste-recycling/reuse';
export const RECYCLE = '/waste-recycling/recycle';
export const AVOIDING_LANDFILL = '/waste-recycling/avoiding-landfill';

export const ECO_FUND = '/social/eco-fund';
export const FARESHARE = '/social/fareshare';
export const INSPIRE = '/social/inspire';

// Social sublinks to go here

export const NAVIGATION = [
  {
    link: HOME,
    name: 'Home',
  },
  {
    link: OUR_VISION,
    name: 'Our Vision',
    subLinks: [
      {
        link: STRATEGY,
        name: 'Our 1-2-3-4 Strategy',
      },
      {
        link: NET_ZERO,
        name: 'Net Zero',
      },
      {
        link: REDUCE_REUSE_RECYCLE,
        name: 'Reduce, Reuse, Recycle',
      },
      {
        link: RESPONSIBLE_ENERGY_RESOURCES,
        name: 'Responsible Energy and Resources',
      },
      {
        link: UN_SDGS,
        name: 'UN SDGs',
      },
    ],
  },
  {
    link: OUR_INNOVATION,
    name: 'Our Innovation',
    subLinks: [
      {
        link: VISION_THIRTY,
        name: 'Vision 30',
      },
      {
        link: AUTO_DELIVERIES,
        name: 'Autonomous Deliveries',
      },
      {
        link: SUSTAINABLE_FLEET,
        name: 'Sustainable Fleet',
      },
    ],
  },
  {
    link: DELIVERY,
    name: 'Delivery',
    subLinks: [
      {
        link: KEEPING_TRACK,
        name: 'Keeping Track',
      },
      {
        link: CHARGING_SOLUTIONS,
        name: 'Charging Solutions',
      },
    ],
  },
  {
    link: WASTE_RECYCLING,
    name: 'Waste & Recycling',
    subLinks: [
      {
        link: REDUCE,
        name: 'Reduce',
      },
      {
        link: REUSE,
        name: 'Reuse',
      },
      {
        link: RECYCLE,
        name: 'Recycle',
      },
      {
        link: AVOIDING_LANDFILL,
        name: 'Avoiding Landfill',
      },
    ],
  },
  {
    link: SOCIAL,
    name: 'Social',
    subLinks: [
      {
        link: ECO_FUND,
        name: 'DPD Eco Fund',
      },
      {
        link: FARESHARE,
        name: 'FareShare Food Donations',
      },
      {
        link: INSPIRE,
        name: "DPD's Inspire",
      },
    ],
  },
  {
    link: DOCUMENTS,
    name: 'Documents',
  },
  {
    link: ROAD_MAP,
    name: 'Road Map',
  },
];
