import { Link } from '@mui/material';
import PropTypes from 'prop-types';

const ExternalLink = ({ to, styles, children }) => {
  return (
    <Link href={to} target="_blank" rel="noreferrer" sx={styles}>
      {children}
    </Link>
  );
};

export default ExternalLink;

ExternalLink.propTypes = {
  to: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node,
};
