import { Typography, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { AUTONOMOUS_DELIVERIES } from '../../../data/analytics';

import autoDeliveriesHeader from '../../../images/OurInnovation/autoDeliveriesHeader.png';
import autoDeliveriesBackground from '../../../images/OurInnovation/autoDeliveriesBackground.png';
import autoDeliveriesImage from '../../../images/OurInnovation/autoDeliveriesImage.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import VideoContainer from '../../../components/videoContainer/VideoContainer.jsx';
import TextOnBackground from '../../../components/textOnBackground/TextOnBackground.jsx';
import { AUTO_DELIVERIES_VIDEO } from '../../../data/links';

const AutonomousDeliveries = () => {
  return (
    <Trackable
      interfaceId={AUTONOMOUS_DELIVERIES.INTERFACE_ID}
      loadId={AUTONOMOUS_DELIVERIES.ON_LOAD}
    >
      <PageHeader image={autoDeliveriesHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Our Sustainable Innovation
        </Typography>
        <Typography
          variant={useMediaQuery(theme.breakpoints.down('md')) ? 'h2' : 'h1'}
          color="#FFF"
        >
          Autonomous Deliveries
        </Typography>
      </PageHeader>
      <TextOnBackground
        background={autoDeliveriesBackground}
        textSide="right"
        verticalPadding={45}
        mobileVerticalPadding={4}
      >
        <Typography
          variant="h2Light"
          gutterBottom
          color={theme.palette.primary.green}
        >
          DPD loves to innovate.
        </Typography>
        <Typography variant="h4Light" color="#000">
          This is why we&apos;ve now started using autonomous robot deliveries
          beginning in Milton Keynes and Raunds.{' '}
        </Typography>
      </TextOnBackground>
      <SideBySide
        image={autoDeliveriesImage}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={20}
        radiusBorder={1}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          gutterBottom
          color={theme.palette.primary.green}
        >
          The robots will navigate the city&apos;s traffic-free network to
          access residential neighbourhoods and will find their way to the
          delivery address autonomously.
        </Typography>
        <Typography
          variant="h4Light"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 6 }
          }
        >
          Customers will be able to track the robot&apos;s progress on a map and
          will be notified when it reaches their property. Consumers then use a
          code to open the secure compartment and access their parcel.
        </Typography>
      </SideBySide>
      <VideoContainer
        text="Watch our short film to find out more."
        background={theme.palette.primary.citySelectorGreenOne}
        videoLink={AUTO_DELIVERIES_VIDEO}
      />
    </Trackable>
  );
};

export default AutonomousDeliveries;
