import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { AVOIDING_LANDFILL } from '../../../data/analytics';

import landfillHeader from '../../../images/WasteRecycling/landfillHeader.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import { AVOIDING_LANDFILL_VIDEO } from '../../../data/links';
import usePadding from '../../../utils/usePadding';
import usePageWidth from '../../../utils/usePageWidth';

const AvoidingLandfill = () => {
  return (
    <Trackable
      interfaceId={AVOIDING_LANDFILL.INTERFACE_ID}
      loadId={AVOIDING_LANDFILL.ON_LOAD}
    >
      <PageHeader image={landfillHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Waste & Recycling
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Avoiding Landfill
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 8,
          backgroundColor: theme.palette.primary.citySelectorGreenOne,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h4Light'
                : 'h2Light'
            }
            color="#FFF"
            textAlign="center"
            gutterBottom
          >
            More than 90% of the waste we produce is reused or recycled, whilst
            we also focus on reducing the amount of waste we produce in the
            first place, achieving a 10% reduction this year.
          </Typography>
          <Typography
            variant="h5Light"
            color="#FFF"
            textAlign="center"
            paddingBottom={2}
          >
            The waste we can't recycle is converted to energy to help reduce the
            UK's dependency on imported fossil fuels - this short film shows you
            how.
          </Typography>
          <iframe
            title={AVOIDING_LANDFILL_VIDEO}
            src={AVOIDING_LANDFILL_VIDEO}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height="550px"
            width="100%"
          />
        </Box>
      </Container>
    </Trackable>
  );
};

export default AvoidingLandfill;
