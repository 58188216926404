import { useEffect, useState } from 'react';

import ContactButton from '../contactButton/ContactButton.jsx';
import ContactUs from '../contactUs/ContactUs.jsx';

const ContactContainer = () => {
  const [contactOpen, setContactOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isParcelQuery, setIsParcelQuery] = useState(false);
  const [noOptionSelected, setNoOptionSelected] = useState(true);

  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [acceptContact, setAcceptContact] = useState(false);
  const [recaptcha, setRecaptcha] = useState(
    process.env.REACT_APP_SENTRY_ENV !== 'LIVE'
      ? process.env.REACT_APP_RECAPTCHA_KEY_TESTING
      : ''
  );

  const handleCloseContact = () => {
    setContactOpen(false);
  };

  useEffect(() => {
    if (!contactOpen) {
      setFormSubmitted(false);
      setSelectedSubject('');
      setEmail('');
      setSubject('');
      setMessage('');
      setAcceptContact(false);
      setRecaptcha(
        process.env.REACT_APP_SENTRY_ENV !== 'LIVE'
          ? process.env.REACT_APP_RECAPTCHA_KEY_TESTING
          : ''
      );
    }
  }, [contactOpen]);

  return (
    <>
      {!contactOpen && <ContactButton setContactOpen={setContactOpen} />}
      <ContactUs
        contactOpen={contactOpen}
        handleCloseContact={handleCloseContact}
        formSubmitted={formSubmitted}
        setFormSubmitted={setFormSubmitted}
        selectedSubject={selectedSubject}
        setSelectedSubject={setSelectedSubject}
        isParcelQuery={isParcelQuery}
        setIsParcelQuery={setIsParcelQuery}
        noOptionSelected={noOptionSelected}
        setNoOptionSelected={setNoOptionSelected}
        email={email}
        setEmail={setEmail}
        subject={subject}
        setSubject={setSubject}
        message={message}
        setMessage={setMessage}
        acceptContact={acceptContact}
        setAcceptContact={setAcceptContact}
        recaptcha={recaptcha}
        setRecaptcha={setRecaptcha}
      />
    </>
  );
};

export default ContactContainer;
