import { ThemeProvider, useMediaQuery } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { TrackerProvider } from '@dpdgroupuk/react-event-tracker';
import { StatsProvider } from './context/statsContext.jsx';

import tracker from './utils/analytics';

import ContactContainer from './components/contactContainer/ContactContainer.jsx';
import Footer from './components/footer/Footer.jsx';
import Navbar from './components/navigation/Navbar.jsx';
import MobileNavbar from './components/navigation/MobileNavbar.jsx';
import ScrollTop from './components/scrollTop/ScrollTop.jsx';
import Router from './router/Router.jsx';
import theme from './theme';
import ScrollTopTrigger from './components/scrollTopTrigger/ScrollTopTrigger.jsx';

function App() {
  return (
    <TrackerProvider tracker={tracker}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollTopTrigger />
          <div id="back-to-top-anchor">
            {useMediaQuery(theme.breakpoints.down('md')) ? (
              <MobileNavbar />
            ) : (
              <Navbar />
            )}
          </div>
          <StatsProvider>
            <Router />
          </StatsProvider>
          <Footer />
          <ContactContainer />
          <ScrollTop />
        </BrowserRouter>
      </ThemeProvider>
    </TrackerProvider>
  );
}

export default App;
