import * as Sentry from '@sentry/react';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_SENTRY_ENV,
} = process.env;

Sentry.init({
  debug: process.env.NODE_ENV === 'development',
  enabled: process.env.NODE_ENV === 'production',
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  ignoreErrors: [
    'SecurityError',
    // Random plugins/extensions
    'top.GLOBALS',
    "Can't find variable: ga",
    /(ResizeObserver loop limit exceeded)/,
  ],
});
