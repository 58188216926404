import { Typography, Grid, Box, Container, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { UNSDGS } from '../../../data/analytics';

import unsdgsHeaderBackground from '../../../images/OurVision/unsdgsHeaderBackground.png';
import unsdgsHeader from '../../../images/OurVision/unsdgsHeader.png';
import unsdgsHeaderSmall from '../../../images/OurVision/unsdgsHeaderSmall.png';
import unsdgsBackground from '../../../images/OurVision/unsdgsBackground.png';
import unsdgsBackgroundMobile from '../../../images/OurVision/unsdgsBackgroundMobile.png';
import unsdgsGoals from '../../../images/OurVision/unsdgsGoals.png';
import unsdgsTargetsBackground from '../../../images/OurVision/unsdgsTargetsBackground.png';

import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import theme from '../../../theme';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const Unsdgs = () => {
  return (
    <Trackable interfaceId={UNSDGS.INTERFACE_ID} loadId={UNSDGS.ON_LOAD}>
      <PageHeader image={unsdgsHeaderBackground}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item md={6} sx={{ maxWidth: '100%' }}>
            <Typography
              variant="body1"
              color={theme.palette.primary.accentGreen}
            >
              Our Vision
            </Typography>
            <Typography
              variant="h1Light"
              color={theme.palette.secondary.accentGreen}
              sx={{ wordWrap: 'break-word' }}
            >
              UN Sustainable Development Goals
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box
              component="img"
              src={
                useMediaQuery(theme.breakpoints.down('md'))
                  ? unsdgsHeaderSmall
                  : unsdgsHeader
              }
              sx={{ maxWidth: '100%' }}
            />
          </Grid>
        </Grid>
      </PageHeader>
      <BoxOnBackground
        background={
          useMediaQuery(theme.breakpoints.down('md'))
            ? unsdgsBackgroundMobile
            : unsdgsBackground
        }
        boxSide="left"
        verticalPadding={25}
        mobileBottomPadding={35}
      >
        <Typography variant="h3Light" gutterBottom color="#FFF">
          The United Nations Sustainable Development Goals
        </Typography>
        <Typography variant="body1" gutterBottom color="#FFF">
          UN SDGs are a set of 17 goals which were adopted as a universal call
          to action to end poverty, safeguard the planet and ensure that
          everyone is living in peace and prosperity by 2030.
        </Typography>
        <Typography variant="body1" color="#FFF">
          The 17 goals are integrated and interwoven, recognising that action in
          one area will have an impact on others.
        </Typography>
      </BoxOnBackground>
      <Container
        disableGutters
        sx={{
          backgroundImage: `url(${unsdgsTargetsBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 3 : 30,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography variant="h3Light" gutterBottom color="#FFF">
            The goals were discussed and agreed upon at the 2015 United Nations
            Sustainable Development Summit in New York
          </Typography>
          <Typography variant="h5Light" gutterBottom color="#FFF">
            Along with the 17 goals, 169 targets were created to help meet the
            goals by 2030.
          </Typography>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item sm={6} sx={{ width: '100%' }}>
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: theme.palette.primary.infoGreen,
                  px: 4,
                  py: 6,
                  height: '100%',
                }}
              >
                <Typography variant="h2Light" color="#FFF" textAlign="center">
                  17 Goals
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={6} sx={{ width: '100%' }}>
              <Box
                sx={{
                  borderRadius: 1,
                  backgroundColor: theme.palette.primary.infoGreen,
                  px: 4,
                  py: 6,
                  height: '100%',
                }}
              >
                <Typography variant="h2Light" color="#FFF" textAlign="center">
                  169 Targets
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h5Light" color="#FFF" sx={{ pt: 2 }}>
            The summit consisted of 196 heads of state and saw the goals
            accepted by 193 countries worldwide, creating a universal agenda for
            sustainable development.
          </Typography>
        </Box>
      </Container>
      <SideBySide
        image={unsdgsGoals}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={25}
      >
        <Typography
          variant="h2Light"
          gutterBottom
          color={theme.palette.primary.accentGreen}
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && {
              textAlign: 'center',
            }
          }
        >
          At DPD we are playing our part to support the push to protect the
          planet in line with the UN SDGs.
        </Typography>
        <Typography
          variant="h4Light"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && {
              paddingBottom: 6,
              textAlign: 'center',
            }
          }
        >
          We have a particular focus on SDG 13, Climate Action, whilst also
          focusing on 8, decent work and economic growth, 11, Sustainable Cities
          and Communities, and 17, Partnerships for the goals.
        </Typography>
      </SideBySide>
    </Trackable>
  );
};

export default Unsdgs;
