import { Container, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';
import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';

const TextOnBackground = ({
  background,
  textSide,
  verticalPadding,
  children,
  mobileVerticalPadding,
}) => {
  return (
    <Container
      disableGutters
      sx={{
        minWidth: '100%',
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        py: useMediaQuery(theme.breakpoints.down('md'))
          ? mobileVerticalPadding
          : verticalPadding || 20,
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: usePageWidth(), px: usePadding() }}>
        <Box sx={textSide && { float: textSide, maxWidth: 500 }}>
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export default TextOnBackground;

TextOnBackground.propTypes = {
  textSide: PropTypes.oneOf(['left', 'right']),
  verticalPadding: PropTypes.number,
  background: PropTypes.string,
  children: PropTypes.node,
  mobileVerticalPadding: PropTypes.number,
};
