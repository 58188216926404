import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { KEEPING_TRACK } from '../../data/analytics';

import theme from '../../theme';

const VanCard = ({ van, showVanStats }) => {
  const tracker = useTracker();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.citySelectorGreenOne,
        borderRadius: 1,
        py: 2,
        px: 2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box component="img" src={van.image} />
      </Box>
      <Typography
        variant="h2Light"
        color="#FFF"
        gutterBottom
        textAlign="center"
        sx={!van.model && { pb: '37.197px' }}
      >
        {van.make}
      </Typography>
      <Typography
        variant="h4Light"
        color="#FFF"
        gutterBottom
        textAlign="center"
      >
        {van.model}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => {
            showVanStats(van);
            tracker.logEvent(KEEPING_TRACK.CLICK_VIEW_STATS);
          }}
          sx={{
            backgroundColor: 'transparent',
            borderColor: '#FFF',
            borderRadius: 1,
            py: '15px',
            px: '30px',
            '&:hover': {
              borderColor: '#FFF',
              boxShadow: 1,
            },
          }}
          variant="outlined"
        >
          <Typography variant="button" color="#FFF">
            View The Stats
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default VanCard;

VanCard.propTypes = {
  van: PropTypes.object,
  showVanStats: PropTypes.func,
};
