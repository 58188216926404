import { useMediaQuery } from '@mui/material';
import theme from '../theme';

const usePageWidth = () => {
  if (useMediaQuery(theme.breakpoints.down('md'))) {
    return '100%';
  } else {
    return '1024px';
  }
};

export default usePageWidth;
