import { Typography, Container, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { REDUCE } from '../../../data/analytics';
import { REFILL_APP } from '../../../data/links';

import wasteRecyclingHeader from '../../../images/WasteRecycling/wasteRecyclingHeader.png';
import reduceWaterBottle from '../../../images/WasteRecycling/reduceWaterBottle.png';
import reducePhone from '../../../images/WasteRecycling/reducePhone.png';
import reduceDrop from '../../../images/WasteRecycling/reduceDrop.png';
import reduceBackground from '../../../images/WasteRecycling/reduceBackground.png';
import reduceBackgroundMobile from '../../../images/WasteRecycling/reduceBackgroundMobile.png';
import reduceBox from '../../../images/WasteRecycling/reduceBox.png';
import reduceRecycleImage from '../../../images/WasteRecycling/reduceRecycleImage.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import TextOnBackground from '../../../components/textOnBackground/TextOnBackground.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';
import ActionButton from '../../../components/actionButton/ActionButton.jsx';

const Reduce = () => {
  return (
    <Trackable interfaceId={REDUCE.INTERFACE_ID} loadId={REDUCE.ON_LOAD}>
      <PageHeader image={wasteRecyclingHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Waste & Recycling
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Reduce
        </Typography>
      </PageHeader>
      <SideBySide
        image={reduceWaterBottle}
        imageSide="right"
        background={theme.palette.primary.background}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
        >
          DPD is fully committed to reducing single use plastics across the
          business. We have given out reusable metal water bottles to our people
          since 2020 which, coupled with the installation of water refill
          stations across our sites, has helped us reduce single use plastic.
        </Typography>
        <Typography
          variant="body1"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 5 }
          }
        >
          We have also removed all plastic bottles from our hub vending
          machines, which previously sold over 100,000 bottles a year.
        </Typography>
      </SideBySide>
      <SideBySide
        image={reducePhone}
        imageSide="left"
        background={theme.palette.secondary.background}
        mobileDirection="column-reverse"
      >
        <Box component="img" src={reduceDrop} sx={{ pb: 2 }} />
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h2Light' : 'h1Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
        >
          We're supporting the Refill campaign.
        </Typography>
        <Typography
          variant="h4Light"
          color="#000"
          sx={{
            pb: 2,
          }}
        >
          The award-winning Refill app helps everyone live with less plastic by
          connecting our people to places they can refill whilst out and about
          delivering.
        </Typography>
        <ActionButton href={REFILL_APP} text="Find Out More" />
      </SideBySide>
      <TextOnBackground
        background={
          useMediaQuery(theme.breakpoints.down('sm'))
            ? reduceBackgroundMobile
            : reduceBackground
        }
        textSide="left"
        verticalPadding={35}
        mobileVerticalPadding={6}
      >
        <Typography variant="h4Light" color="#000" gutterBottom>
          Our canteens no longer use plastic cutlery and drinking cups and we
          are working with our supply chain to establish other key plastic free
          items.
        </Typography>
        <Typography
          variant="h2Light"
          color={theme.palette.primary.green}
          paddingBottom={useMediaQuery(theme.breakpoints.down('md')) ? 50 : 0}
        >
          We will remove all single use plastic from our entire operation.
        </Typography>
      </TextOnBackground>
      <SideBySide
        image={reduceBox}
        imageSide="left"
        background={theme.palette.primary.background}
        verticalPadding={30}
        mobileDirection="column-reverse"
      >
        <Typography variant="h4Light" color="#000" gutterBottom>
          Our latest innovation is a groundbreaking new tote box, designed to be
          self sealing, which has the potential to remove the use of single use
          cable ties from our entire operation,
        </Typography>
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color={theme.palette.primary.green}
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 6 }
          }
        >
          avoiding 8 tonnes of plastic waste a year.
        </Typography>
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 35,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', pb: 4 }}>
            <Box component="img" src={reduceRecycleImage} />
          </Box>
          <Typography
            variant="h3Light"
            color="#000"
            gutterBottom
            textAlign="center"
          >
            We are currently trialling a reusable seal to be used on the bags
            that carry parcels around our sites.
          </Typography>
          <Typography
            variant="h4Light"
            color="#000"
            gutterBottom
            textAlign="center"
          >
            This will avoid a further estimated 3 tonnes of plastic waste.
          </Typography>
          <Typography variant="body1" color="#000" textAlign="center">
            We are looking at every aspect of our operation to ensure we
            minimise or eliminate plastic waste.
          </Typography>
        </Box>
      </Container>
    </Trackable>
  );
};

export default Reduce;
