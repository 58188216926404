import { Grid, Stack, Typography, Box, useMediaQuery } from '@mui/material';
import { Container } from '@mui/system';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

import { NAVIGATION } from '../../router/routes';
import theme from '../../theme';
import ExternalLink from '../externalLink/ExternalLink.jsx';

import instagramIcon from '../../images/Footer/instagramIcon.png';
import linkedinIcon from '../../images/Footer/linkedinIcon.png';
import {
  FOOTER_INSTAGRAM,
  FOOTER_LINKEDIN,
  FOOTER_PRIVACY,
  FOOTER_TANDC,
} from '../../data/links';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';

const Footer = () => {
  const footerLinkStyles = {
    textDecoration: 'none',
    color: theme.palette.primary.midGrey,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.midGrey,
      color: theme.palette.primary.midGrey,
    },
  };

  const footerIconStyles = {
    color: theme.palette.primary.midGrey,
    '&:hover': {
      color: theme.palette.primary.midGrey,
    },
  };

  return (
    <Container
      disableGutters
      sx={{
        backgroundColor: theme.palette.primary.background,
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        py: 6,
      }}
    >
      <Box sx={{ width: usePageWidth(), px: usePadding() }}>
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={useMediaQuery(theme.breakpoints.down('md')) ? 2 : 3}
          wrap={useMediaQuery(theme.breakpoints.down('md')) ? 'wrap' : 'nowrap'}
        >
          {NAVIGATION.map(nav => (
            <Grid
              item
              key={nav.link}
              xs={12}
              sm={6}
              sx={{ display: 'flex', justifyContent: 'left' }}
            >
              <Box>
                <NavLink to={nav.link} style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: theme.palette.secondary.green,
                      pb: 1,
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        textDecoration: 'underline',
                        textDecorationColor: theme.palette.secondary.green,
                      },
                    }}
                  >
                    {nav.name}
                  </Typography>
                </NavLink>
                {nav.subLinks && (
                  <Stack>
                    {nav.subLinks.map(subLink => (
                      <NavLink
                        to={subLink.link}
                        key={subLink.link}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: '#000',
                            opacity: 0.5,
                            pb: 1,
                            '&:hover': {
                              textDecoration: 'underline',
                              textDecorationColor: '#000',
                            },
                          }}
                        >
                          {subLink.name}
                        </Typography>
                      </NavLink>
                    ))}
                  </Stack>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent={
            useMediaQuery(theme.breakpoints.down('md'))
              ? 'center'
              : 'space-between'
          }
          sx={{ pt: 4, color: theme.palette.primary.midGrey }}
        >
          <Grid item xs={12} md={8}>
            <Typography
              variant="body2"
              textAlign={
                useMediaQuery(theme.breakpoints.down('md')) && 'center'
              }
              paddingBottom={useMediaQuery(theme.breakpoints.down('md')) && 2}
            >
              ©{format(new Date(), 'yyyy')} |{' '}
              <ExternalLink to={FOOTER_PRIVACY} styles={footerLinkStyles}>
                Privacy and Cookie Policy
              </ExternalLink>{' '}
              |{' '}
              <ExternalLink to={FOOTER_TANDC} styles={footerLinkStyles}>
                Terms & Conditions
              </ExternalLink>
            </Typography>
          </Grid>
          <Grid item>
            <ExternalLink to={FOOTER_INSTAGRAM} styles={footerIconStyles}>
              <Box component="img" src={instagramIcon} sx={{ pr: 1 }} />
            </ExternalLink>
            <ExternalLink to={FOOTER_LINKEDIN} styles={footerIconStyles}>
              <Box component="img" src={linkedinIcon} />
            </ExternalLink>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Footer;
