import { Grid, Box, Typography, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';

import connectingLine from '../../images/RoadMap/connectingLine.png';

const RoadMapCard = ({
  sizeLg,
  date,
  background,
  text,
  textVariant,
  align,
  image,
}) => {
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid item md={sizeLg || 3} xs={12}>
      {date && (
        <Box sx={{ width: 'min-content' }}>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.green,
              borderRadius: 1,
              borderColor: theme.palette.primary.infoGreen,
              borderStyle: 'solid',
              borderWidth: 1,
              width: 'min-content',
              p: 1,
            }}
          >
            <Typography size="h6" color="#FFF">
              {date}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box component="img" src={connectingLine} />
          </Box>
        </Box>
      )}
      <Box
        sx={[
          {
            backgroundColor: background || '',
            borderRadius: 1,
            borderColor: theme.palette.primary.infoGreen,
            borderStyle: 'solid',
            borderWidth: 1,
            px: text ? 3 : 0,
            display: 'flex',
            justifyContent: image ? 'center' : '',
            alignItems: 'center',
            mt: date || mediaQuery ? 0 : 6,
          },
          mediaQuery
            ? { minHeight: sizeLg === 6 ? 108 : 180 }
            : { height: sizeLg === 6 ? 108 : 180 },
        ]}
      >
        {text && (
          <Typography
            color={
              background === theme.palette.secondary.innovationInfoGreen
                ? theme.palette.primary.accentGreen
                : '#FFF'
            }
            variant={textVariant || 'body1'}
            textAlign={align || 'left'}
          >
            {text}
          </Typography>
        )}
        {image && (
          <Box
            component="img"
            src={image}
            sx={[
              { width: '100%', objectFit: 'fill' },
              mediaQuery ? { minHeight: '100%' } : { height: '100%' },
            ]}
          />
        )}
      </Box>
    </Grid>
  );
};

export default RoadMapCard;

RoadMapCard.propTypes = {
  image: PropTypes.string,
  background: PropTypes.string,
  sizeLg: PropTypes.number,
  date: PropTypes.string,
  text: PropTypes.string,
  textVariant: PropTypes.string,
  align: PropTypes.string,
};
