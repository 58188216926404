import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const StatsContext = createContext();

const StatsProvider = ({ children }) => {
  const [stats, setStats] = useState();

  const value = {
    stats,
    setStats,
  };

  return (
    <StatsContext.Provider value={value}>{children}</StatsContext.Provider>
  );
};

StatsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StatsContext, StatsProvider };
