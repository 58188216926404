export const HOME = {
  INTERFACE_ID: 'MZQyXEGFYFAUCpak',
  ON_LOAD: 'xoPqXEGFYFAUCpZe',
  BACK_SCROLL: '4AqsuMGGAqCIach4',
  NEXT_SCROLL: 'n6PMuMGGAqCIacec',
  READ_MORE: 'IcLlww6FYFxi.BW.',
};

export const ARTICLE = {
  INTERFACE_ID: 'vsGKXEGFYFAUCo.t',
  ON_LOAD: 'WKb5uMGGAqCIaavE',
  BACK_SCROLL: 'oqb5uMGGAqCIaavT',
  NEXT_SCROLL: 'Lqb5uMGGAqCIaavk',
  READ_MORE: 'pab5uMGGAqCIaav1',
};

export const NET_ZERO = {
  INTERFACE_ID: 'H4WhXEGFYFAUCtZS',
  ON_LOAD: '_nc9XEGFYFAUCmBf',
};

export const REDUCE_REUSE_RECYCLE = {
  INTERFACE_ID: 'NEWhXEGFYFAUCtZv',
  ON_LOAD: 'oIl9XEGFYFAUCmEI',
  VIDEO: 'xQl9XEGFYFAUCmDv',
};

export const RESPONSIBLE_ENERGY_RESOURCES = {
  INTERFACE_ID: 'Y4WhXEGFYFAUCtY1',
  ON_LOAD: '_5TdXEGFYFAUCl_A',
};

export const UNSDGS = {
  INTERFACE_ID: 'EGDDXEGFYFAUCjvj',
  ON_LOAD: 'WpBDXEGFYFAUCjpN',
};

export const STRATEGY = {
  INTERFACE_ID: 'sYWhXEGFYFAUCtYZ',
  ON_LOAD: 'JIEdXEGFYFAUCl8a',
};

export const VISION_THIRTY = {
  INTERFACE_ID: 'QURQekGGAqCIa2eC',
  ON_LOAD: 'kl6wekGGAqCIa2uU',
  CLICK_CITY: 'jIrbjMGGAqCIaZux',
  CLICK_DROPDOWN: '0R6wekGGAqCIa2tk',
  VIDEO: 'E2fPjMGGAqCIaak_',
};

export const AUTONOMOUS_DELIVERIES = {
  INTERFACE_ID: 'DsDYekGGAqCIa3cN',
  ON_LOAD: 'c3G4ekGGAqCIa3ss',
  VIDEO: 'vnG4ekGGAqCIa3sU',
};

export const PROJECT_BREATHE = {
  INTERFACE_ID: 'UqSkekGGAqCIa3zw',
  ON_LOAD: 'fjQUekGGAqCIa33E',
  CLICK_AIR_QUALITY: 'osWUekGGAqCIa34r',
  VIDEO: 'tDQUekGGAqCIa32q',
};

export const SUSTAINABLE_LINEHAUL = {
  INTERFACE_ID: 'hFXMekGGAqCIa4VJ',
  ON_LOAD: 'XlysekGGAqCIa4YH',
};

export const KEEPING_TRACK = {
  INTERFACE_ID: 'i8EcekGGAqCIa4mC',
  ON_LOAD: 'LLDCekGGAqCIa5Ij',
  CLICK_VIEW_STATS: 'F0ZoPMGGAqCIaVBv',
  VIDEO: 'ZzDCekGGAqCIa5II',
};

export const VAN_STATS_DIALOG = {
  INTERFACE_ID: '4luiekGGAqCIaxNA',
  ON_LOAD: 'Pi7iekGGAqCIaxUC',
  CLICK_CLOSE: 'GgiUPMGGAqCIaVjI',
  VIDEO: '087iekGGAqCIaxSh',
};

export const CHARGING_SOLUTIONS = {
  INTERFACE_ID: 'Z3hKekGGAqCIaxoc',
  ON_LOAD: 'G.AqekGGAqCIaxq7',
};

export const WASTE_RECYCLING = {
  INTERFACE_ID: '9QL6ekGGAqCIayLp',
  ON_LOAD: 'FIBWekGGAqCIayrs',
  CLICK_AVOIDING_LANDFILL: 'f1SKgsGGAqCIaZ4U',
  CLICK_RECYCLE: 'AxPygsGGAqCIaZ3C',
  CLICK_REDUCE: 'GwBWekGGAqCIayrS',
  CLICK_REUSE: 'rBVygsGGAqCIaZ1n',
};

export const REDUCE = {
  INTERFACE_ID: '2b42ekGGAqCIaytv',
  ON_LOAD: 'C3d2ekGGAqCIayx8',
};

export const REUSE = {
  INTERFACE_ID: 'PUKOekGGAqCIay0Q',
  ON_LOAD: 'Di5OekGGAqCIay2Y',
};

export const RECYCLE = {
  INTERFACE_ID: 'LIXuekGGAqCIay8a',
  ON_LOAD: 'ZYSeekGGAqCIay.w',
};

export const AVOIDING_LANDFILL = {
  INTERFACE_ID: '_xMuekGGAqCIay4a',
  ON_LOAD: '5VhuekGGAqCIay6k',
};

export const ECO_FUND = {
  INTERFACE_ID: '344RgsGGAqCIaRQg',
  ON_LOAD: 'XBYxgsGGAqCIaSMT',
  VIDEO: 'g.YxgsGGAqCIaSK2',
};

export const RELOVE = {
  INTERFACE_ID: '1WalgsGGAqCIaTFp',
  ON_LOAD: 'OYjlgsGGAqCIaTKB',
  VIDEO: 'sojlgsGGAqCIaTJo',
};

export const FARESHARE = {
  INTERFACE_ID: 'bld1gsGGAqCIaTTH',
  ON_LOAD: 'RnoNgsGGAqCIaTXI',
};

export const INSPIRE = {
  INTERFACE_ID: 'H6kdgsGGAqCIabgR',
  ON_LOAD: 'opw9gsGGAqCIabtF',
};

export const DOCUMENTS = {
  INTERFACE_ID: 'nj7BYsGGAqCIaTjn',
  ON_LOAD: 'hkKFYsGGAqCIaUfw',
  CLICK_CNDC: '2uiVYsGGAqCIaUm6',
  CLICK_ISO: 'TUKFYsGGAqCIaUgJ',
  CLICK_CO2: 'EOVlYsGGAqCIaUkf',
  CLICK_WASTE: 'cNAVYsGGAqCIaUlm',
  CLICK_SUSTAINABILITY_REPORT: 'lxPVYsGGAqCIaUpV',
  CLICK_SUSTAINABILITY_POLICY: 'B65VYsGGAqCIaUoO',
  CLICK_VIDEO_MODAL: '5YKFYsGGAqCIaUeS',
};

export const SPEAKER_DIALOG = {
  INTERFACE_ID: '4P7BYsGGAqCIaTvG',
  ON_LOAD: '8a0DYsGGAqCIaacJ',
  CLICK_CLOSE: 'F60DYsGGAqCIaacp',
  VIDEO: 'Ty0DYsGGAqCIaaaN',
};

export const ROAD_MAP = {
  INTERFACE_ID: 'f2bjYsGGAqCIaayb',
  ON_LOAD: 'CrGTYsGGAqCIabMs',
};
