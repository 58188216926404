import { Routes, Route, Navigate } from 'react-router-dom';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import * as routes from './routes';

// Pages
import Home from '../pages/Home/Home.jsx';
import Article from '../components/article/Article.jsx';
import Strategy from '../pages/OurVision/Strategy/Strategy.jsx';
import NetZero from '../pages/OurVision/NetZero/NetZero.jsx';
import ReduceReuseRecycle from '../pages/OurVision/ReduceReuseRecycle/ReduceReuseRecycle.jsx';
import ResponsibleEnergyResources from '../pages/OurVision/ResponsibleEnergyResources/ResponsibleEnergyResources.jsx';
import Unsdgs from '../pages/OurVision/Unsdgs/Unsdgs.jsx';

import VisionThirty from '../pages/OurInnovation/VisionThirty/VisionThirty.jsx';
import AutonomousDeliveries from '../pages/OurInnovation/AutonomousDeliveries/AutonomousDeliveries.jsx';
import SustainableLinehaul from '../pages/OurInnovation/SustainableLinehaul/SustainableLinehaul.jsx';

import KeepingTrack from '../pages/Delivery/KeepingTrack/KeepingTrack.jsx';
import ChargingSolutions from '../pages/Delivery/ChargingSolutions/ChargingSolutions.jsx';

import WasteRecycling from '../pages/WasteRecycling/WasteRecycling.jsx';
import Reduce from '../pages/WasteRecycling/Reduce/Reduce.jsx';
import Reuse from '../pages/WasteRecycling/Reuse/Reuse.jsx';
import Recycle from '../pages/WasteRecycling/Recycle/Recycle.jsx';
import AvoidingLandfill from '../pages/WasteRecycling/AvoidingLandfill/AvoidingLandfill.jsx';

import EcoFund from '../pages/Social/EcoFund/EcoFund.jsx';
import Fareshare from '../pages/Social/Fareshare/Fareshare.jsx';
import Inspire from '../pages/Social/Inspire/Inspire.jsx';

import Documents from '../pages/Documents/Documents.jsx';
import RoadMap from '../pages/RoadMap/RoadMap.jsx';

const Router = () => {
  const tracker = useTracker();

  tracker.setContext({
    userAgent: navigator.userAgent,
  });

  return (
    <Routes>
      <Route path={routes.HOME} element={<Home />} />

      <Route path={routes.NEWS_ARTICLE} element={<Article />} />

      <Route
        path={routes.OUR_VISION}
        element={<Navigate to={routes.STRATEGY} replace />}
      />
      <Route path={routes.STRATEGY} element={<Strategy />} />
      <Route path={routes.NET_ZERO} element={<NetZero />} />
      <Route
        path={routes.REDUCE_REUSE_RECYCLE}
        element={<ReduceReuseRecycle />}
      />
      <Route
        path={routes.RESPONSIBLE_ENERGY_RESOURCES}
        element={<ResponsibleEnergyResources />}
      />
      <Route path={routes.UN_SDGS} element={<Unsdgs />} />

      <Route
        path={routes.OUR_INNOVATION}
        element={<Navigate to={routes.VISION_THIRTY} replace />}
      />
      <Route path={routes.VISION_THIRTY} element={<VisionThirty />} />
      <Route path={routes.AUTO_DELIVERIES} element={<AutonomousDeliveries />} />
      <Route
        path={routes.SUSTAINABLE_FLEET}
        element={<SustainableLinehaul />}
      />

      <Route
        path={routes.DELIVERY}
        element={<Navigate to={routes.KEEPING_TRACK} replace />}
      />
      <Route path={routes.KEEPING_TRACK} element={<KeepingTrack />} />
      <Route path={routes.CHARGING_SOLUTIONS} element={<ChargingSolutions />} />

      <Route path={routes.WASTE_RECYCLING} element={<WasteRecycling />} />
      <Route path={routes.REDUCE} element={<Reduce />} />
      <Route path={routes.REUSE} element={<Reuse />} />
      <Route path={routes.RECYCLE} element={<Recycle />} />
      <Route path={routes.AVOIDING_LANDFILL} element={<AvoidingLandfill />} />

      <Route
        path={routes.SOCIAL}
        element={<Navigate to={routes.ECO_FUND} replace />}
      />
      <Route path={routes.ECO_FUND} element={<EcoFund />} />
      <Route path={routes.FARESHARE} element={<Fareshare />} />
      <Route path={routes.INSPIRE} element={<Inspire />} />

      <Route path={routes.DOCUMENTS} element={<Documents />} />

      <Route path={routes.ROAD_MAP} element={<RoadMap />} />
    </Routes>
  );
};

export default Router;
