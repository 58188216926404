import { createTheme } from '@mui/material';
import './index.scss';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1Light: 'h1',
          h2Light: 'h2',
          h3Light: 'h3',
          h4Light: 'h4',
          h5Light: 'h5',
          button: 'h6',
        },
      },
    },
  },
  typography: {
    fontFamily: 'PlutoSansDPD, sans-serif',
    h1: {
      fontSize: 56,
      fontWeight: 370,
    },
    h1Light: {
      fontSize: 56,
      fontWeight: 350,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    h2: {
      fontSize: 40,
      fontWeight: 370,
    },
    h2Light: {
      fontSize: 40,
      fontWeight: 350,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    h3: {
      fontSize: 32,
      fontWeight: 370,
    },
    h3Light: {
      fontSize: 32,
      fontWeight: 350,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    h4: {
      fontSize: 24,
      fontWeight: 370,
    },
    h4Light: {
      fontSize: 24,
      fontWeight: 350,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    h5: {
      fontSize: 20,
      fontWeight: 400,
    },
    h5Light: {
      fontSize: 20,
      fontWeight: 370,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    h6: {
      fontSize: 14,
      fontWeight: 370,
    },
    body1: {
      fontSize: 16,
      fontWeight: 370,
      lineHeight: '19.76px',
    },
    body2: {
      fontSize: 12,
      fontWeight: 370,
      lineHeight: '14.82px',
      letterSpacing: '0.3px',
    },
    label: {
      fontSize: 18,
      fontWeight: 400,
      fontFamily: 'PlutoSansDPD, sans-serif',
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
      fontFamily: 'PlutoSansDPD, sans-serif',
      lineHeight: '15px',
    },
    annotation: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: '12px',
    },
  },
  palette: {
    primary: {
      main: '#414042',
      red: '#DC0032',
      green: '#509E2F',
      background: '#F8FBF4',
      infoGreen: '#83C06F',
      accentGreen: '#385A4F',
      citySelectorGreenOne: '#66B553',
      citySelectorGreenThree: '#90C685',
      midGrey: '#808285',
      lightGrey: '#E6E7E8',
      lightGreySubLink: '#F5F5F5',
      warmGrey: '#CAC4BE',
    },
    secondary: {
      main: '#414042', // Replace this when secondary.main is needed
      green: '#77B25E',
      background: '#FDFFFB',
      backgroundWebApp: '#F5F5F5',
      backgroundLavendar: '#FBFBFF',
      infoGreen: '#C1DEBA',
      accentGreen: '#6C9E03',
      innovationInfoGreen: '#E1EFE3',
    },
  },
});

theme = createTheme(theme, {
  padding: {
    paddingX: window.innerWidth < theme.breakpoints.values.md ? 4 : 3,
    pageWidth: '1024px',
    halfPageWidth: '512px',
  },
});

export default theme;
