import { Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';

const ActionButton = ({ text, onclick, href, fullWidth }) => {
  return (
    <Button
      fullWidth={fullWidth}
      sx={{
        color: '#FFF',
        backgroundColor: theme.palette.primary.green,
        borderRadius: 1,
        py: '15px',
        px: '30px',
        '&:hover': {
          backgroundColor: theme.palette.primary.green,
          opacity: 0.8,
          color: '#FFF',
        },
      }}
      onClick={onclick}
      href={href}
      target="_blank"
      rel="noreferrer"
    >
      <Typography variant="button">{text}</Typography>
    </Button>
  );
};

export default ActionButton;

ActionButton.propTypes = {
  text: PropTypes.string,
  onclick: PropTypes.func,
  href: PropTypes.string,
  fullWidth: PropTypes.bool,
};
