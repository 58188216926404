import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import theme from '../../theme';

const BlogTable = () => {
  return (
    <TableContainer
      sx={{
        display: 'block',
        overflow: 'auto',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.citySelectorGreenThree,
        borderWidth: 1,
        borderRadius: 1,
        textAlign: 'center',
      }}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.lightGreen,
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                Biodiesel
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: theme.palette.primary.lightGreen,
              }}
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                HVO
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                60% emissions reductions
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *1
                </sup>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                90% emissions reductions
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *1
                </sup>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                Long-term use in a diesel engine leads to a deterioration of
                engine cold operability.
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *2
                </sup>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                Proven by vehicle manufacturers to lead to no loss in vehicle
                performance.
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *3
                </sup>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                Does not meet expectations needed to be considered an
                environmentally friendly diesel substitute.
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *4
                </sup>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                color={theme.palette.primary.accentGreen}
              >
                Awarded with an International Sustainability and Carbon
                Certification (ISCC).
                <sup
                  style={{
                    fontSize: '0.7rem',
                  }}
                >
                  *5
                </sup>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BlogTable;
