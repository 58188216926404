import { KeyboardArrowUp } from '@mui/icons-material';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import theme from '../../theme';

const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Fab
        onClick={handleClick}
        sx={{
          zIndex: 2000,
          width: '48px',
          height: '48px',
          backgroundColor: 'transparent',
          position: 'fixed',
          left: 50,
          bottom: 50,
          boxShadow: 'none',
          color: theme.palette.primary.main,
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: theme.palette.primary.main,
          '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      >
        <KeyboardArrowUp />
      </Fab>
    </Zoom>
  );
};

export default ScrollTop;
