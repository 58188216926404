import { useContext, useEffect, useState, useCallback } from 'react';
import {
  Card,
  Typography,
  Grid,
  Container,
  useMediaQuery,
} from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { KEEPING_TRACK } from '../../../data/analytics';
import { getStats } from '../../../apis/green';
import { StatsContext } from '../../../context/statsContext.jsx';

import keepingTrackHeader from '../../../images/Delivery/keepingTrackHeader.png';
import maxus594 from '../../../images/Delivery/maxus594.png';
import ford393 from '../../../images/Delivery/ford393.png';
import man100 from '../../../images/Delivery/man100.png';
import fuso2 from '../../../images/Delivery/fuso2.png';
import maxus300 from '../../../images/Delivery/maxus300.png';
import nissan571 from '../../../images/Delivery/nissan571.png';
import paxter from '../../../images/Delivery/paxter.png';
import eav4 from '../../../images/Delivery/eav4.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import VanCard from '../../../components/vanCard/VanCard.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import VanStatsTable from '../../../components/vanStatsTable/VanStatsTable.jsx';
import VanStatsDialog from '../../../components/vanStatsDialog/VanStatsDialog.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const KeepingTrack = () => {
  const statsContext = useContext(StatsContext);
  const [headerStats, setHeaderStats] = useState([
    {
      title: 'Our EV Fleet',
      stat: 0,
    },
    {
      title: 'Our All\nElectric Cities*',
      subtext: '*Final Mile Delivery',
      stat: 0,
    },
    {
      title: 'Our EV Fleet (%)',
      stat: '0.00%',
    },
    {
      title: 'Total Parcels Delivered All Electric (YTD)',
      stat: 0,
    },
  ]);

  const getGreenStats = useCallback(async () => {
    const contextStats = statsContext.stats;
    const setContextStats = statsContext.setStats;
    if (!contextStats) {
      const newStats = await getStats();
      setContextStats(newStats);
      setHeaderStats([
        {
          title: 'Our EV Fleet',
          stat: newStats.ev_count.toLocaleString(),
        },
        {
          title: 'Our All\nElectric Cities*',
          subtext: '*Final Mile Delivery',
          stat: newStats.green_cities_live.toLocaleString(),
        },
        {
          title: 'Our EV Fleet (%)',
          stat: newStats.fleet_ev,
        },
        {
          title: 'Total Parcels Delivered All Electric (YTD)',
          stat: newStats.green_deliveries.toLocaleString(),
        },
      ]);
    } else {
      setHeaderStats([
        {
          title: 'Our EV Fleet',
          stat: contextStats.ev_count.toLocaleString(),
        },
        {
          title: 'Our All\nElectric Cities*',
          subtext: '*Final Mile Delivery',
          stat: contextStats.green_cities_live.toLocaleString(),
        },
        {
          title: 'Our EV Fleet (%)',
          stat: contextStats.fleet_ev,
        },
        {
          title: 'Total Parcels Delivered All Electric (YTD)',
          stat: contextStats.green_deliveries.toLocaleString(),
        },
      ]);
    }
  }, [statsContext]);

  useEffect(() => {
    getGreenStats();
  }, [getGreenStats]);

  const vans = {
    threeFiveTonne: [
      {
        category: '3.5T VEHICLES',
        make: 'Maxus',
        model: 'ED9',
        image: maxus594,
        stats: {
          payload: '1000kg - 1200kg',
          capacity: '9.7 m\u00B3 or 11m\u00B3 ',
          range: '219 miles',
          battery: '51.5 kWh or 72 kWh or 88.55 kWh',
        },
      },
      {
        category: '3.5T VEHICLES',
        make: 'Ford',
        model: 'E-transit',
        image: ford393,
        stats: {
          payload: '940 kg',
          capacity: '12.4 m\u00B3',
          range: '150 miles',
          battery: '75 kWh (68 kWh available)',
        },
      },
      {
        category: '3.5T VEHICLES',
        make: 'Man',
        model: 'ETGE',
        image: man100,
        stats: {
          payload: '1.0 to 1.75 tonnes',
          capacity: '11 m\u00B3',
          range: '86 miles',
          battery: '36 kWh',
        },
      },
    ],
    fuso2: {
      category: '7.5T & 18T VEHICLES',
      make: 'Fuso',
      model: 'eCanter',
      image: fuso2,
      stats: {
        payload: '4.2 tonnes',
        capacity: '26 m\u00B3',
        range: '62 miles',
        battery: '83 kWh',
      },
    },
    lite: [
      {
        category: 'LITE DELIVERY SOLUTIONS',
        make: 'Maxus',
        model: 'ED3',
        image: maxus300,
        stats: {
          payload: '865kg - 1202kg',
          capacity: '4.8 m\u00B3 or 6.3 m\u00B3',
          range: '213 miles',
          battery: '35 kWh or 52.5 kWh',
        },
      },
      {
        category: 'LITE DELIVERY SOLUTIONS',
        make: 'Nissan',
        model: 'e-NV200',
        image: nissan571,
        stats: {
          payload: '660 kg',
          capacity: '4.2 m\u00B3',
          range: '122 miles',
          battery: '40 kWh',
        },
      },
      {
        category: 'LITE DELIVERY SOLUTIONS',
        make: 'Paxster',
        image: paxter,
        stats: {
          payload: '200 kg',
          capacity: '1m\u00B3',
          range: '60 miles',
          battery: '9.2 kwh',
        },
      },
      {
        category: 'LITE DELIVERY SOLUTIONS',
        make: 'EAV',
        image: eav4,
        stats: {
          payload: '150 kg',
          capacity: '100 parcels',
          range: '15 mph electrically assisted',
          battery: '250 watt',
        },
      },
    ],
  };

  const [selectedVan, setSelectedVan] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const showVanStats = van => {
    setSelectedVan(van);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedVan();
  };

  return (
    <Trackable
      interfaceId={KEEPING_TRACK.INTERFACE_ID}
      loadId={KEEPING_TRACK.ON_LOAD}
    >
      <PageHeader image={keepingTrackHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Final Mile Delivery
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Keeping Track
        </Typography>
        <Card sx={{ backgroundColor: '#FFF', maxWidth: 'fit-content' }}>
          <Grid
            container
            spacing={7}
            justifyContent="flex-start"
            alignItems="flex-end"
            sx={{ pt: 4, pb: 8, px: 5 }}
          >
            {headerStats?.map((stat, index) => (
              <Grid item key={stat.title} xs={12} md={index === 3 ? 3.5 : 2.75}>
                <Typography
                  variant="h6"
                  color={theme.palette.primary.accentGreen}
                >
                  {stat.title.includes('\n')
                    ? stat.title.split('\n').map(title => (
                        <p key={title} style={{ marginBottom: 0 }}>
                          {title}
                        </p>
                      ))
                    : stat.title}
                </Typography>
                {stat.subtext && (
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.accentGreen}
                    sx={{ fontSize: 10 }}
                  >
                    {stat.subtext}
                  </Typography>
                )}
                <Typography
                  variant="h3Light"
                  color={theme.palette.primary.citySelectorGreenOne}
                  sx={{
                    textDecoration: 'underline',
                    textDecorationColor: theme.palette.primary.accentGreen,
                    textDecorationThickness: 1,
                    textUnderlineOffset: 18,
                    pt: 1,
                    fontSize: '28px',
                  }}
                >
                  {stat.stat}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Card>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          width: usePageWidth(),
          px: usePadding(),
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 20,
          backgroundColor: theme.palette.secondary.background,
        }}
      >
        <Typography
          variant="h1Light"
          color={theme.palette.primary.green}
          gutterBottom
          textAlign="center"
        >
          3.5t Vehicles
        </Typography>
        <Grid container spacing={2}>
          {vans.threeFiveTonne.map(van => (
            <Grid item md={4} xs={12} key={van.make + van.model}>
              <VanCard van={van} showVanStats={showVanStats} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <SideBySide
        image={vans.fuso2.image}
        imageSide="left"
        background={theme.palette.primary.background}
        verticalPadding={12}
      >
        <Typography
          variant="h1Light"
          gutterBottom
          color={theme.palette.primary.green}
        >
          7.5t Vehicles
        </Typography>
        <Typography variant="h2Light" color={theme.palette.primary.accentGreen}>
          {vans.fuso2.make}
        </Typography>
        <Typography variant="h4Light" color="#000" gutterBottom>
          {vans.fuso2.model}
        </Typography>
        {/* Video embed here */}
        <VanStatsTable stats={vans.fuso2.stats} />
      </SideBySide>
      <Container
        disableGutters
        sx={{
          width: usePageWidth(),
          px: usePadding(),
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
        }}
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.green}
          gutterBottom
          textAlign="center"
        >
          Lite delivery solutions
        </Typography>
        <Grid container spacing={2}>
          {vans.lite.map(van => (
            <Grid item md={6} xs={12} key={van.make + van.model}>
              <VanCard van={van} showVanStats={showVanStats} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {selectedVan && (
        <VanStatsDialog
          modalOpen={modalOpen}
          selectedVan={selectedVan}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Trackable>
  );
};

export default KeepingTrack;
