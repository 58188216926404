import { useMediaQuery } from '@mui/material';
import theme from '../theme';

const usePageHalfWidth = () => {
  if (useMediaQuery(theme.breakpoints.down('md'))) {
    return '100%';
  } else {
    return '512px';
  }
};

export default usePageHalfWidth;
