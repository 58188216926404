import { Typography, Box } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { RESPONSIBLE_ENERGY_RESOURCES } from '../../../data/analytics';

import responsibleEnergyHeader from '../../../images/OurVision/responsibleEnergyHeader.png';
import responsibleEnergyNightBackground from '../../../images/OurVision/responsibleEnergyNightBackground.jpg';
import responsibleEnergyWindturbine from '../../../images/OurVision/responsibleEnergyWindturbine.png';
import responsibleEnergyBackground from '../../../images/OurVision/responsibleEnergyBackground.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import TextOnBackground from '../../../components/textOnBackground/TextOnBackground.jsx';

const ResponsibleEnergyResources = () => {
  return (
    <Trackable
      interfaceId={RESPONSIBLE_ENERGY_RESOURCES.INTERFACE_ID}
      loadId={RESPONSIBLE_ENERGY_RESOURCES.ON_LOAD}
    >
      <PageHeader image={responsibleEnergyHeader}>
        <Typography variant="h5" gutterBottom color="#FFF">
          Our Vision
        </Typography>
        <Typography
          variant="h1"
          gutterBottom
          color="#FFF"
          sx={{ wordBreak: 'break-word' }}
        >
          Responsible Energy and Resources
        </Typography>
      </PageHeader>
      <BoxOnBackground
        background={responsibleEnergyNightBackground}
        boxSide="left"
        verticalPadding={35}
        mobileBottomPadding={53}
      >
        <Typography variant="h3Light" gutterBottom color="#FFF">
          We install LED lighting in all new depots and are retrofitting all
          other sites.
        </Typography>
        <Typography variant="h5Light" gutterBottom color="#FFF">
          LEDs are at least 75% more efficient than incandescent lighting.
        </Typography>
      </BoxOnBackground>
      <TextOnBackground
        background={responsibleEnergyWindturbine}
        textSide="left"
        verticalPadding={35}
        mobileVerticalPadding={4}
      >
        <Typography
          variant="h2Light"
          gutterBottom
          color={theme.palette.primary.green}
        >
          Since 2022 all of the electricity we have directly purchased has come
          from renewable sources*.
        </Typography>
        <Box
          sx={{
            borderRadius: 1,
            backgroundColor: theme.palette.primary.infoGreen,
            px: 4,
            py: 6,
          }}
        >
          <Typography variant="h6" color="#FFF">
            which has saved
          </Typography>
          <Typography variant="h2Light" color="#FFF">
            13,402 Tonnes CO₂e
          </Typography>
          <Typography variant="body1" color="#FFF">
            in 2023 alone.
          </Typography>
        </Box>
        <Typography variant="annotation">
          *Accounting for 99% of all electricity we consume
        </Typography>
      </TextOnBackground>
      <BoxOnBackground
        background={responsibleEnergyBackground}
        boxSide="right"
        verticalPadding={25}
        mobileBottomPadding={53}
      >
        <Typography variant="h3Light" gutterBottom color="#FFF">
          We have over 18,000 solar PV systems across our sites, which generated
          over 3.9 million kWh in 2023.
        </Typography>
        <Typography variant="h5Light" gutterBottom color="#FFF">
          We plan to further expand our solar PV network in the future.
        </Typography>
      </BoxOnBackground>
    </Trackable>
  );
};

export default ResponsibleEnergyResources;
