import { Container, Typography, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';
import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';

const VideoContainer = ({ text, videoLink, background, textColor }) => {
  const mediaQuery = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container
      disableGutters
      sx={{
        minWidth: '100%',
        backgroundColor: background,
        py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 20,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: usePageWidth(), px: usePadding() }}>
        <Typography
          variant="h3Light"
          paddingBottom={3}
          color={textColor || '#FFF'}
          sx={{ textAlign: 'center' }}
        >
          {text}
        </Typography>
        {videoLink && (
          <iframe
            style={{ display: 'block' }}
            title={videoLink}
            src={videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={mediaQuery ? '195px' : '550px'}
            width="100%"
          />
        )}
      </Box>
    </Container>
  );
};

export default VideoContainer;

VideoContainer.propTypes = {
  text: PropTypes.string,
  videoLink: PropTypes.string,
  background: PropTypes.string,
  textColor: PropTypes.string,
};
