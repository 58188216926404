import { Typography, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { REUSE } from '../../../data/analytics';
import { REUSE_VIDEO } from '../../../data/links';

import reuseHeader from '../../../images/WasteRecycling/reuseHeader.png';
import reuseLaptop from '../../../images/WasteRecycling/reuseLaptop.png';
import reusePhone from '../../../images/WasteRecycling/reusePhone.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import VideoContainer from '../../../components/videoContainer/VideoContainer.jsx';

const Reuse = () => {
  return (
    <Trackable interfaceId={REUSE.INTERFACE_ID} loadId={REUSE.ON_LOAD}>
      <PageHeader image={reuseHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Waste & Recycling
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Reuse
        </Typography>
      </PageHeader>
      <SideBySide
        image={reuseLaptop}
        imageSide="right"
        background={theme.palette.primary.background}
        verticalPadding={25}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h4Light"
          color="#000"
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Across our network we generated
        </Typography>
        <Typography
          variant="h2Light"
          color={theme.palette.primary.green}
          gutterBottom
        >
          7.4 tonnes
        </Typography>
        <Typography variant="h4Light" color="#000" gutterBottom>
          of waste electrical and electronic equipment (WEEE) in of 2023, of
          which
        </Typography>
        <Typography variant="h2Light" color={theme.palette.primary.green}>
          98% was prepared for reuse to avoid waste, with the remaining 2%
          recycled for the raw materials.
        </Typography>
      </SideBySide>
      <SideBySide
        image={reusePhone}
        imageSide="left"
        background={theme.palette.secondary.background}
        mobileDirection="column-reverse"
      >
        <Typography variant="h4Light" color="#000" gutterBottom>
          We have worked with a number of our major customers to launch our
          groundbreaking new service whereby all clothing that's had its day can
          find a new home and be reloved - and we've got a carbon efficient way
          to provide this service.
        </Typography>
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
        >
          The service is called ReLove, and the clothes will be donated to six
          national charities
        </Typography>
        <Typography
          variant="h5Light"
          color="#000"
          sx={
            useMediaQuery(theme.breakpoints.down('md')) && { paddingBottom: 3 }
          }
        >
          who are all keen to work with us and make this a new way to breathe
          life into preloved clothing.
        </Typography>
      </SideBySide>
      <VideoContainer
        text="Watch our short film to find out more."
        background={theme.palette.primary.citySelectorGreenOne}
        videoLink={REUSE_VIDEO}
      />
    </Trackable>
  );
};

export default Reuse;
