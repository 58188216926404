import { Typography, Box } from '@mui/material';
import ExternalLink from '../components/externalLink/ExternalLink.jsx';

import article1 from '../images/NewsArticles/article1.jpeg';
import article2 from '../images/NewsArticles/article2.jpeg';
import article3 from '../images/NewsArticles/article3.jpeg';
import article4 from '../images/NewsArticles/article4.jpg';
import article5 from '../images/NewsArticles/article5.jpg';
import article6 from '../images/NewsArticles/article6.png';
import article7 from '../images/NewsArticles/article7.jpg';
import article8 from '../images/NewsArticles/article8.jpg';
import article10 from '../images/NewsArticles/article10.png';
import article11 from '../images/NewsArticles/article11.png';
import article12 from '../images/NewsArticles/article12.png';
import article13 from '../images/NewsArticles/article13.jpg';
import article14 from '../images/NewsArticles/article14.png';
import article15 from '../images/NewsArticles/article15.jpg';
import article16 from '../images/NewsArticles/article16.jpg';
import article17 from '../images/NewsArticles/article17.jpg';
import article18 from '../images/NewsArticles/article18.png';
import article19 from '../images/NewsArticles/article19.png';
import article20 from '../images/NewsArticles/article20.png';
import article22 from '../images/NewsArticles/article22.jpg';
import article23 from '../images/NewsArticles/article23.png';
import article24 from '../images/NewsArticles/article24.jpg';
import article25 from '../images/NewsArticles/article25.jpg';
import article26 from '../images/NewsArticles/article26.jpg';
import article27 from '../images/NewsArticles/article27.jpg';
import article28 from '../images/NewsArticles/article28.jpg';
import article29 from '../images/NewsArticles/article29.png';
import article30 from '../images/NewsArticles/article30.png';
import article31 from '../images/NewsArticles/article31.png';
import article32 from '../images/NewsArticles/article32.png';
import article33 from '../images/NewsArticles/article33.jpg';
import article34 from '../images/NewsArticles/article34.png';
import article35 from '../images/NewsArticles/article35.png';
import article36 from '../images/NewsArticles/article36.png';
import article37 from '../images/NewsArticles/article37.png';
import article38 from '../images/NewsArticles/article38.png';
import article39 from '../images/NewsArticles/article39.png';
import article40 from '../images/NewsArticles/article40.jpeg';
import article41 from '../images/NewsArticles/article41.png';
import article42 from '../images/NewsArticles/article42.png';
import article42Home from '../images/NewsArticles/article42Home.png';
import article43 from '../images/NewsArticles/article43.png';
import article44 from '../images/NewsArticles/article44.png';

import article32Img2 from '../images/NewsArticles/article32Img2.png';

import article1Embed from '../images/NewsArticles/article1Embed.png';
import article2Embed from '../images/NewsArticles/article2Embed.png';
import article3Embed from '../images/NewsArticles/article3Embed.png';
import article4Embed from '../images/NewsArticles/article4Embed.png';
import article5Embed from '../images/NewsArticles/article5Embed.png';
import article7Embed from '../images/NewsArticles/article7Embed.png';
import article8Embed from '../images/NewsArticles/article8Embed.png';
import article10Embed from '../images/NewsArticles/article10Embed.png';
import article12Embed from '../images/NewsArticles/article12Embed.png';
import article14Embed from '../images/NewsArticles/article14Embed.png';

import { TREEAPP_VIDEO } from '../data/links.js';

import article14Mobile from '../images/NewsArticles/article14Mobile.jpg';

import { SBTiCertificate } from './index.js';

import BlogTable from '../components/blogTable/BlogTable.jsx';

import theme from '../theme.js';

export const NEWS_ARTICLES = [
  {
    id: 44,
    date: 'October 2024',
    cardTitle: 'Lending a hand',
    subtitle: '',
    image: article44,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Last month, Hinckley & Bosworth Borough Council launched an appeal to
          local businesses and schools in the area to support their 2024 A5
          litter campaign.
        </Typography>
        <br />
        <Typography gutterBottom>
          The A5 has sadly become overrun with litter and waste material; for
          Hinckley & Bosworth Borough Council alone, cleaning this road costs
          over £5,000 annually, necessitating partial road closures that
          inconvenience residents and road users alike.
        </Typography>
        <br />
        <Typography gutterBottom>
          While the council had already taken significant steps to make this
          campaign successful, through posters, banners, and national highways
          signs being placed along the route, along with advertising at highly
          visited petrol stations, they were seeking collaboration with local
          businesses to drive awareness and engagement.
        </Typography>
        <br />
        <Typography gutterBottom>
          Hub 5 is our largest hub facility, with our Hub 4 building not far
          away, both located in Hinckley. Given the large number of our
          colleagues who frequently travel along this route, we thought this was
          a great campaign to raise awareness of littering in the local area and
          inspire action.
        </Typography>
        <br />
        <Typography gutterBottom>
          Members of the Hub 5 team took to the streets, along with some of the
          other local businesses and schools, to do their bit in tidying up the
          A5. Over 25 bags of rubbish were collected during the one-hour
          clean-up! This really brought home the scale of the problem and how
          important it is to continue to spread awareness. We were pleased to be
          able to further support the campaign by sharing the posters across our
          hubs and including the information in staff briefings.
        </Typography>
      </>
    ),
  },
  {
    id: 43,
    date: 'October 2024',
    cardTitle: 'Partnering in restoration ',
    subtitle: '',
    image: article43,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          We recently announced that we had awarded funding to the RSPB from our
          Eco Fund for wetland restoration at Pulborough Brooks, situated in the
          Arun Valley.
        </Typography>
        <br />
        <Typography gutterBottom>
          This wetland site is in desperate need of track improvements to allow
          staff and volunteers to carry out essential maintenance and assist in
          the management of grazing animals – but it also requires significant
          upgrades to water control structures to improve climate resilience.
        </Typography>
        <br />
        <Typography gutterBottom>
          Due to climate change, the UK is experiencing more intense and
          sustained periods of rainfall. 2023 was one of the eleven wettest
          years since records began in 1836, with flooding being a significant
          issue for many areas. Wetlands function like a sponge, helping to
          absorb this extra water and releasing it slowly to allow water
          filtering to reduce pollution, and maintaining ideal habitats for
          nesting birds.
        </Typography>
        <br />
        <Typography gutterBottom>
          When a wetland functions optimally it acts as a carbon sink, trapping
          and storing carbon from the atmosphere more effectively than
          rainforests. This makes wetlands an important contributor to the fight
          against climate change, alongside being a crucial habitat for the
          protection and enhancement of biodiversity for the species that call
          it home.
        </Typography>
        <br />
        <Typography gutterBottom>
          We were fortunate to be able to visit the site in June to see the
          enhancement works that are required to enable the RSPB and its
          volunteers to continue to manage the site effectively. While we were
          there we spoke with Oriole Wagstaff, Senior Site Manager for
          Pulborough Brooks, to get a first-hand account of the impact our
          funding will have and an overview of how critical our wetlands are.
          You can listen to our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://dpduk.fireside.fm/dpd-rspb-partnership"
          >
            podcast
          </ExternalLink>{' '}
          or check out our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://vimeo.com/dpdgroup/review/972576451/38db606ef6"
          >
            video
          </ExternalLink>{' '}
          to take a look at the site!
        </Typography>
      </>
    ),
  },
  {
    id: 42,
    date: 'September 2024',
    cardTitle: 'Treeapp Impact Report',
    subtitle: '',
    image: article42Home,
    bannerImage: '',
    hideImage: true,
    content: (
      <>
        <Typography fontWeight="bold" gutterBottom>
          Celebrating our partnerships
        </Typography>
        <Typography gutterBottom>
          For a number of years we have been supporting businesses and charities
          in the fight against climate change and biodiversity loss through our
          Eco Fund. Through this initiative we have been partnered with Treeapp
          since 2021 and we’re really proud of the impact this partnership has
          had to date, and will continue to have as we head towards 2025.
        </Typography>
        <Typography gutterBottom>
          Treeapp recently celebrated the success of our partnership and we’re
          pleased to be able to share their communication below that highlights
          the incredible impact we’ve achieved together!
        </Typography>
        <iframe
          title={TREEAPP_VIDEO}
          src={TREEAPP_VIDEO}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          height="550px"
          width="100%"
          style={{ margin: '2rem 0' }}
        />
        <Typography fontWeight="bold" gutterBottom>
          Background on the Partnership with DPD
        </Typography>
        <Typography gutterBottom>
          In 2021, Treeapp and DPD teamed up with a shared goal: to make a
          positive mark on our planet. The mission of this partnership was
          clear—to embed sustainability into DPD’s operations by planting a tree
          for every ten tracked electric vehicle deliveries made through the DPD
          app. This initiative has blossomed into a whopping 250,000+ trees
          planted all across the world by July 2024
        </Typography>
        <br />
        <Typography fontWeight="bold" gutterBottom>
          Objective: Sustainability Commitment and Tree Planting Integration
        </Typography>
        <Typography gutterBottom>
          DPD’s dedication to sustainability isn’t just a tagline; it’s part of
          their operating model.
        </Typography>
        <Typography gutterBottom>
          Their deliberate choice of Treeapp as a partner is grounded in our
          unique approach, which guarantees that reforestation initiatives have
          a direct and positive impact on local communities, with contracts
          securing long-term land ownership. This, coupled with our meticulous
          selection of native species and the continuous care and maintenance of
          our planting sites, has yielded remarkable results. Based on a survey
          conducted in 2023, on average across the majority of our planting
          sites, over 80% of the trees planted have matured into effective
          carbon stores - with an impressive 92% maturity rate in Indonesia.
        </Typography>
        <Typography gutterBottom>
          Another reason for choosing Treeapp lies in our stringent rules
          towards massive tree plantations and focus on diverse species planting
          techniques. This unique approach ensures the trees go beyond capturing
          carbon; they actively contribute to fighting drought, stabilising
          soil, and enhancing biodiversity—making a tangible difference in
          ecosystem restoration.
        </Typography>
        <br />
        <Typography fontWeight="bold" gutterBottom>
          The In-Person Tree Planting Experience
        </Typography>
        <br />
        <Box
          component="img"
          sx={{ maxWidth: '100%' }}
          alt="The In-Person Tree Planting Experience"
          src={article42}
        />
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="quote">
            November 2023: Team DPD planting trees in Northampton
          </Typography>
        </Box>
        <br />
        <Typography gutterBottom>
          As part of its tree planting efforts in the UK, the Treeapp team
          occasionally invites corporate partners to participate in tree
          planting during the planting season, which typically runs from late
          November to March (though it may extend slightly longer in Scotland).
          Treeapp manages numerous planting sites across England, Wales, and
          Scotland, where they plant new trees and monitor those planted in
          previous seasons. To ensure that all trees are planted correctly and
          adequately protected, foresters supervise the planting process and,
          once all visitors have left, conduct a final inspection of the planted
          trees.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability at DPD, along with his team and
          representatives from other companies, spent a full day planting native
          trees at{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://www.hideawaywood.co.uk/"
          >
            Hideaway Wood Farm
          </ExternalLink>{' '}
          — a former landfill now transformed into a thriving ecosystem. During
          the event, Olly emphasised DPD’s dedication to achieving net-zero
          emissions and the role of their Eco Fund in driving environmental
          projects.
        </Typography>
        <Typography gutterBottom>
          As Olly told the Treeapp team, “It’s not just about planting trees;
          it’s about actively contributing to habitat restoration.”
        </Typography>
        <br />
        <Typography fontWeight="bold" gutterBottom>
          Results: Making a Difference Across Continents
        </Typography>
        <Typography gutterBottom>
          The collaborative efforts between Treeapp and DPD are truly inspiring.
          DPD has achieved the milestone of planting over 250,000+ trees in just
          3 years. Their planting strategy is strategically designed to generate
          positive environmental effects. From Indonesia’s coral reefs to
          Tanzania’s biodiversity hotspots to areas in the UK with low woodland
          cover, each tree contributes to a story of global impact.
        </Typography>
        <Typography gutterBottom>
          An example of a highly successful planting project that emerged from
          this partnership is the coastal afforestation initiative on Lombok
          Island, Indonesia. DPD provided vital funding for extensive mangrove
          planting efforts, focusing on the large-scale planting of various
          local species to combat soil erosion. This initiative has been
          instrumental in restoring the island’s coastal ecosystems.
        </Typography>
        <Typography gutterBottom>
          A key highlight of the project was its educational outreach, which
          involved collaborating with five local schools to teach children about
          the importance of protecting coastal areas. This focus on educating
          future generations has fostered a deeper understanding of
          environmental stewardship within the community. As a result, the
          project has not only revitalised local fisheries and coral reefs but
          also delivered significant benefits to both the environment and the
          local population.
        </Typography>
        <Typography gutterBottom>
          “DPD is an exceptional supporter of our planting activities. With the
          hands-on efforts of their team and their strong commitment to tree
          planting as part of their delivery process, we’ve successfully planted
          over 150 acres of diverse tree species worldwide. We look forward to
          reaching the 1 million trees milestone".
        </Typography>
        <Typography gutterBottom>
          &emsp;&bull; Godefroy Harito, Co-Founder of Treeapp
        </Typography>
        <br />
        <Typography gutterBottom>
          As DPD and Treeapp continue their journey together, their shared
          commitment to sustainability is leaving a lasting legacy. It’s not
          just about numbers; it’s about crafting a healthier, more sustainable
          planet. DPD’s dedication to sustainability isn’t confined to their
          operations; it’s a practical contribution to positive environmental
          change.
        </Typography>
      </>
    ),
  },
  {
    id: 41,
    date: 'September 2024',
    cardTitle: 'Team building at Dimmingsdale',
    subtitle: '',
    image: article41,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          In July we had the opportunity to visit Dimmingsdale in the Churnet
          Valley, which is one of the sites we have supported through our
          multi-year partnership with Forestry England. Not only did we get to
          tour Dimmingsdale and see the areas that will benefit from the 4,000
          trees we have funded at this site through the{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://green.dpd.co.uk/social/eco-fund"
          >
            Eco Fund
          </ExternalLink>
          , we were also able to get involved in removing invasive species.
        </Typography>
        <Typography gutterBottom>
          Invasive species such as Himalayan Balsam have a negative impact on
          forests in a number of ways. As fast-growing weeds they can crowd out
          native species, impacting their ability to survive. They can also
          alter the soil typology, cause unstable ground, and spread disease.
          Housing multiple Sites of Special Scientific Interest, Dimmingsdale
          has many delicate species and ecosystems, some of which are the last
          in the country. Removing invasive species helps to protect these
          important natives such as wood anemones and bluebells.
        </Typography>
        <Typography gutterBottom>
          Our team of volunteers were able to make great progress pulling the
          Himalayan Balsam from an area of Dimmingsdale. Each plant is capable
          of producing 800 seeds, so every plant removed can make a huge
          difference!
        </Typography>
        <Typography gutterBottom>
          You can listen to our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://dpduk.fireside.fm/dpd-forestry-england-partnership"
          >
            podcast
          </ExternalLink>{' '}
          where we speak to Forestry England about the history of the site and
          our partnership, or we would love for you to check out our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://dpdgroup.wistia.com/medias/nwys9e62dp"
          >
            video
          </ExternalLink>
          !
        </Typography>
      </>
    ),
  },
  {
    id: 40,
    date: 'July 2024',
    cardTitle: 'Time to reflect',
    subtitle: '',
    image: article40,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          It is always useful at the halfway point to reflect on progress made,
          regroup, and redefine the priorities for the remainder of the year.
          And what a first half of 2024 it’s been!
        </Typography>
        <Typography gutterBottom>
          So far, we are making excellent progress in achieving our carbon
          budget for the year. Our carbon budget is how we map out the annual
          reductions required to reach our near-term 2030 reduction target of
          43% compared to our 2020 baseline. Achieving this target puts us in an
          excellent position to achieve our 2040 net zero target.
        </Typography>
        <Typography gutterBottom>
          The main contributors to our decarbonisation efforts are the roll-out
          of electric vehicles (EVs) in our collection and delivery fleet,
          deployment of renewable energy generation and procurement, and the use
          of hydrogenated vegetable oil (HVO) in our transport fleet.
        </Typography>
        <Typography gutterBottom>
          By the end of 2023 more than 32% of our C&D fleet was electric. We
          have seen this number increase slightly to 34% through the first half
          of 2024 due to de-fleeting of some of our diesel vehicles; however, we
          have spent considerable time this year ensuring that the
          infrastructure we need to expand our electric fleet is in place.
          <br />
          Operating an electric fleet is more than just buying and deploying
          vehicles: we have to consider route mileage, charger availability,
          both at home, in public, and on our sites, and the transition of our
          drivers from diesel to electric vehicles.
        </Typography>
        <Typography gutterBottom>
          Some of these transition risks have been formalised as we went through
          our first climate-related disclosure, and we have needed to order a
          small number of diesel vehicles this year alongside new EVs to
          maintain our fleet numbers as we work to overcome some of the
          challenges associated with the deployment of EVs.
        </Typography>
        <Typography gutterBottom>
          Taking care of our people and our communities has always been at the
          heart of what we do. We’ve seen our people come together to support
          each other and their communities through a number of exciting events
          this year.
        </Typography>
        <Typography gutterBottom>
          DPD were proud to have a float at this year’s Birmingham Pride, with
          more support planned for the Pride festivals in Brighton, Stafford,
          and Manchester later this year.
        </Typography>
        <Typography gutterBottom>
          As the corporate sponsor for the Big Help Out, many of our colleagues
          across the business got stuck in with volunteering events in their
          local area during the Big Help Out weekend from 7–9 June. Described by
          those taking part as a great opportunity to give back to the
          community, this event will be running across the summer too, so we
          look forward to seeing more positive stories from across the business!
        </Typography>
        <Typography gutterBottom>
          Continuing the success of our Eco Fund we have already seen some big
          milestones this year. We have surpassed £1 million raised for the fund
          through our recycling initiatives and have planted over 200,000 trees
          with Treeapp since our partnership began!
        </Typography>
        <Typography gutterBottom>
          We recently went through another funding round and awarded support to
          the RSPB for the restoration of a critically important UK wetlands
          site, and to City to Sea to support World Refill Day and spread their
          Refill Campaign to more communities.
        </Typography>
        <Typography gutterBottom>
          We’re very excited to have received feedback from an award we made in
          2022 for Our Only World in Cornwall who used our funding to install a
          number of Living Seawalls panels in Plymouth. These have been a great
          success and we look forward to sharing more details soon. We recognise
          that reaching net zero is more than just decarbonisation; we also need
          to make sure our business has a positive impact. Our Eco Fund enables
          us to support crucial biodiversity protection and enhancement, climate
          resilience, and community equity projects.
        </Typography>
        <Typography gutterBottom>
          Sharing our progress internally is a key component of bringing all of
          our colleagues on the net zero journey with us. We have produced a
          number of podcasts this year talking about all the great things we’re
          doing, but also making sure we’re transparent about our challenges and
          tackling some of the most frequently asked questions. We have also
          implemented a monthly sustainability update to bring the headline
          stats and news to the business more frequently.
        </Typography>
        <Typography gutterBottom>
          Climate Fresk, an interactive and collaborative course teaching the
          fundamentals of climate change, has also begun rolling out through the
          business, raising awareness of how our target relates to action. More
          than 300 people have now been through this training and we hope to
          reach many more of our colleagues before the end of the year.
        </Typography>
        <Typography gutterBottom>
          Deployment and utilisation of EVs, renewable fuels, and renewable
          energy procurement and generation remain priorities for the rest of
          the year as we continue to make progress on achieving our near-term
          target. Despite facing some challenges we are confident that we are on
          track to end 2024 on a high!
        </Typography>
      </>
    ),
  },
  {
    id: 39,
    date: 'July 2024',
    cardTitle: 'Education in our communities',
    subtitle: '',
    image: article39,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Recently DPD paid a special visit to Bournville School to teach the
          kids all about road safety. Evie the robot also took time out of her
          busy schedule delivering around Milton Keynes to join us as the guest
          of honour.
        </Typography>
        <Typography gutterBottom>
          The kids watched a video where Evie taught them a variety of safety
          tips when near traffic, particularly where the blind spots are on an
          HGV. Evie then made her surprise appearance as she rolled into the
          classroom, delighting the children.
        </Typography>
        <Typography gutterBottom>
          The kids were then given an activity book which included a challenge
          to draw the blind spots on a diagram of an HGV. After this they went
          outside where our road safety truck was waiting. Cones were positioned
          around the yard, each one representing a blind spot for the driver –
          kids took turns getting inside the cab to see if they could spot the
          cones from the driver's seat.
        </Typography>
        <Typography gutterBottom>
          It was an incredible and educational experience which excited the
          children (and we understand Evie loved it, too), so keep an eye out as
          we visit other schools in the future.
        </Typography>
      </>
    ),
  },
  {
    id: 38,
    date: 'June 2024',
    cardTitle: 'Stepping Up For Reuse',
    subtitle: '',
    image: article38,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          June 16 was World Refill Day, where we’re all encouraged to ditch
          single-use plastics to help tackle the growing problem of
          environmental plastic pollution.
        </Typography>
        <Typography gutterBottom>
          Plastic waste can stick around in landfills for centuries as it often
          does not decompose. This litter can then find its way into our rivers
          and oceans, which has a devastating impact on the species that call
          these habitats home. In the UK, climate and resources charity WRAP
          suggest that plastic bottles were the largest single source of
          packaging produced from consumer goods, with only around 73% of these
          collected from households for recycling between 2020 and 2021.
        </Typography>
        <Typography gutterBottom>
          We recently announced that we had awarded funding to City to Sea from
          our Eco Fund to assist them in delivering and expanding this year’s
          Refill Campaign. We were delighted to be joined by Jane Martin, CEO of
          City to Sea, on our sustainability podcast ahead of World Refill Day
          to talk more about this worthy campaign and how our funding impacts
          what they can achieve.
        </Typography>
        <Typography gutterBottom>
          You can listen to the podcast{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://dpduk.fireside.fm/world-refill-day"
          >
            here
          </ExternalLink>
          .
        </Typography>
      </>
    ),
  },
  {
    id: 37,
    date: 'June 2024',
    cardTitle: 'Supporting World Environment Day',
    subtitle: '',
    image: article37,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          The 5th of June was World Environment Day, an annual global event
          celebrated since 1974 to raise awareness of and encourage action to
          protect our environment. This year the theme was land restoration,
          desertification, and drought resilience.
        </Typography>
        <Typography gutterBottom>
          Desertification is the process by which land becomes degraded through
          drought, deforestation, or poor agricultural practices. With more than
          one-fifth of the Earth&apos;s land area currently degraded, taking
          action to redress the balance is crucial to ensure food security for
          our and future generations and fight climate change.
        </Typography>
        <Typography gutterBottom>
          Tree planting can help to reverse this damage and promote drought
          resilience through improved soil fertility and reduced soil erosion.
        </Typography>
        <Typography gutterBottom>
          To commemorate World Environment Day we shared part of our journey
          with Treeapp through{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://www.linkedin.com/feed/update/urn:li:ugcPost:7204137380822269954/?actorCompanyId=55410"
          >
            LinkedIn
          </ExternalLink>
          , highlighting our recent milestone of over 200,000 trees planted
          through this initiative. This work is supported by our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="/social/eco-fund"
          >
            Eco Fund
          </ExternalLink>
          , through which we also have a multi-year partnership with Forestry
          England.
        </Typography>
        <Typography gutterBottom>
          Our relationship with Forestry England began in 2020 following a
          devastating fire at Wareham. Our donation helped to fund the purchase
          of fire break equipment and plant new trees. Building on this project,
          we embarked on a four-year partnership to support tree planting and
          local community initiatives across four other sites. To date, we have
          volunteered our time to plant nearly 1,800 trees alongside the
          planting completed by Forestry England, and the aim is to have planted
          12,100 by the end of our partnership.
        </Typography>
        <Typography gutterBottom>
          In July we will be volunteering our time once again to help remove
          invasive Himalayan Balsam at the Dimmingsdale site. Dimmingsdale forms
          part of the Churnet Valley in the Staffordshire Moorlands and is
          classed as a Planted Ancient Woodland site (PAWS). DPD’s support will
          allow Forestry England to expand the wooded area and restore the PAWS
          back to native broadleaf.
        </Typography>
      </>
    ),
  },
  {
    id: 36,
    date: 'May 2024',
    cardTitle: 'Engaging our colleagues on the road to net zero',
    subtitle: '',
    image: article36,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Since the beginning of this year we have been rolling out Climate
          Fresk training across our business and are pleased to have reached
          over 300 of our colleagues so far!
        </Typography>
        <Typography gutterBottom>
          Originating in France, the Climate Fresk NGO has been working to raise
          awareness of and enable business action towards climate transition
          since 2018. The course itself is interactive and collaborative,
          teaching the fundamentals of climate science and enabling business
          decarbonisation actions to be placed into context.
        </Typography>
        <Typography gutterBottom>
          Transitioning to a low-carbon world and stopping the worst impacts of
          climate change is a collective responsibility; however, general
          awareness of what climate change means to our planet is not widely
          understood. Participation in a Climate Fresk session raises
          participants' awareness of the impacts of climate change, how we
          contribute both individually and as a business, and the actions we
          need to take to reduce our emissions.
        </Typography>
        <Typography gutterBottom>
          Following their session, Mandy Hamilton, Head of Marketing and
          Communications said, “We aren’t all climate scientists but Climate
          Fresk helped us to understand a lot more about the science and helped
          us to see the role we can all play in finding solutions for the
          planet, and of course for DPD achieving net zero by 2040”.
        </Typography>
        <Typography gutterBottom>
          We look forward to bringing this informative and engaging course to
          more of our colleagues across the business.
        </Typography>
      </>
    ),
  },
  {
    id: 35,
    date: 'April 2024',
    cardTitle: 'New milestones and partnerships!',
    subtitle: '',
    image: article35,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD has been supporting projects aimed at tackling climate change,
          biodiversity loss, and pollution for a number of years through our Eco
          Fund. In April, we reached a new milestone of £1 million raised
          through the fund since its inception!
        </Typography>
        <Typography gutterBottom>
          We now are pleased to announce that we have recently awarded further
          funding to two charities!
        </Typography>
        <br />
        <Typography gutterBottom>RSPB</Typography>
        <Typography gutterBottom>
          Pulborough Brooks, situated in the Arun Valley and one of the most
          biodiverse floodplain wetlands in England, faces threats from climate
          change, development, fragmentation, and pollution. As a protected Site
          of Special Scientific Interest, it is a managed area of wet grassland
          benefiting breeding waders and internationally important wintering
          wildfowl.
        </Typography>
        <Typography gutterBottom>
          We will be supporting the restoration of wetlands at Pulborough Brooks
          through track improvements and upgrades to water control structures,
          essential upgrades that allow water loss from the site to be
          controlled. This provides nesting sites and food resources during the
          breeding season, alongside enabling effective access for management
          and animal grazing and improving the site’s climate resilience.
        </Typography>
        <br />
        <Typography gutterBottom>City to Sea</Typography>
        <Typography gutterBottom>
          Having previously promoted the Refill app to our drivers, showing
          where they can access free drinking water to refill their reusable
          bottles while carrying out their deliveries, we are now supporting the
          further rollout of the Refill campaign.
        </Typography>
        <Typography gutterBottom>
          Our support will enable increased community action to tackle the
          billions of pieces of plastic used and thrown away each year from
          single-use water bottles, takeaway coffee cups, lunch packaging, and
          supermarket display packaging, encouraging the use of reusable
          packaging and reaching more diverse communities.
        </Typography>
        <br />
        <Typography gutterBottom>Reforestation</Typography>
        <Typography gutterBottom>
          Following the selection of these projects we also engaged our
          colleagues in voting for a third cause that they would like to support
          through this funding round. Out of the options of terrestrial
          biodiversity, marine biodiversity, reforestation, and plastic
          pollution, reforestation was the winner – so we will be looking to
          award more funding to a suitable project in the coming weeks!
        </Typography>
        <Typography gutterBottom>
          We look forward to keeping you up-to-date with the rollout of these
          projects through 2024.
        </Typography>
      </>
    ),
  },
  {
    id: 34,
    date: 'April 2024',
    cardTitle: 'Earth Day 2024',
    subtitle: '',
    image: article34,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Earth Day is a global annual event that focuses on environmental
          protection: the need to protect and conserve the Earth’s natural
          resources for future generations.
        </Typography>
        <Typography gutterBottom>
          The WWF estimates that an area of forest the size of a football pitch
          is lost every two seconds due to deforestation. Consumption of timber
          products plays a role in driving deforestation, as does agriculture.
          Forests provide essential habitats for many species, alongside
          influencing rainfall and acting as a carbon sink, absorbing a quarter
          of the CO2 emissions released by human activities. Protecting our
          forests is an essential step in the fight against climate change.
        </Typography>
        <Typography gutterBottom>
          Since 2021, DPD have been working with Treeapp, who plant one tree for
          every ten deliveries received on an electric delivery by a customer
          using our app. This planting is funded via our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="/social/eco-fund"
          >
            Eco Fund
          </ExternalLink>
          .
        </Typography>
        <Typography gutterBottom>
          During 2023, we reached over 200,000 trees planted since this
          initiative began and we have supported projects restoring biodiversity
          and engaging local communities, both nationally and internationally.
          Treeapp have recently published their latest impact report, now
          available on{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="/static/media/TreeappImpactReport2024.8d5cf2bd1214cb59bfe9.pdf"
          >
            our website
          </ExternalLink>
          , where you can read more about the projects supported by us and their
          wider partnership network.
        </Typography>
      </>
    ),
  },
  {
    id: 33,
    date: 'April 2024',
    cardTitle: '2023 at a glance',
    subtitle: '',
    image: article33,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          We are pleased to launch our Sustainability in Focus document for
          2023, providing an in-depth look at some of the achievements of last
          year.
        </Typography>
        <Typography gutterBottom>
          2023 saw great progress for DPD UK across our decarbonisation
          initiatives as we get closer to our net zero 2040 target. At the
          outset, we had committed to increasing our electric final-mile
          delivery fleet to enable us to deliver lower carbon services to our
          customers, reducing their business impact alongside our own. We also
          had a focus on utilising lower-emission fuels and trialling
          alternative low-emission delivery methods.
        </Typography>
        <Typography gutterBottom>
          Overall, we made a 34% reduction in our carbon emissions compared to a
          2020 baseline, which is more than 84,000 tonnes of carbon saved in
          2023! This has been achieved through the growth of our electric
          final-mile delivery fleet to over 3,600 vehicles, enabling us to
          deliver more than 64 million parcels on electric vehicles throughout
          the year. We also achieved over 95% conversion of our transport fleet
          to hydrogenated vegetable oil (HVO) fuel, a much lower emission fuel
          than diesel.
        </Typography>
        <Typography gutterBottom>
          These achievements mean that we are on track to achieve our near-term
          2030 emissions target. The introduction of a carbon budget for 2023
          provided oversight for the business on those key decisions that were
          needed, and at what pace, to maintain our net zero trajectory.
        </Typography>
        <Typography gutterBottom>
          Our social initiatives have also had a significant impact this year
          through our continued support of Forestry England, Treeapp, and the
          Woodmor Foundation, alongside project support for the RSPB. These
          partnerships have provided benefits to both biodiversity and the
          communities within which they operate.
        </Typography>
        <Typography gutterBottom>
          We will not be slowing down in 2024!
        </Typography>
        <Typography gutterBottom>
          We look forward to quantifying the results of our EcoLaunchpad
          program, trialling innovations in buildings, transport, and robotics
          to reduce emissions and tackle challenges on our net zero journey
          around charging infrastructure. Further electric vehicles will be
          joining our fleet to ensure we can continue to deliver all-electric in
          inner cities. Our social initiatives will also continue following a
          further Eco Fund funding round in the first quarter of 2024.
        </Typography>
      </>
    ),
  },
  {
    id: 32,
    date: 'March 2024',
    cardTitle: 'DPD win at edie Awards',
    subtitle: '',
    image: article32,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Express parcel delivery company DPD has won the edie Award for Net
          Zero Innovation of the Year at a gala ceremony in London on March 6.
        </Typography>
        <Typography gutterBottom>
          DPD was awarded the prize for their innovative automated robot
          deliveries, which were trialled in Milton Keynes and are now being
          expanded across the country. The company is committed to being net
          zero by 2040 and is deploying new innovations to help meet this
          target.
        </Typography>
        <Typography gutterBottom>
          The edie Awards recognise businesses that are committed to making a
          positive impact on their industry – particularly where it concerns
          sustainability.
        </Typography>
        <Typography gutterBottom>
          DPD CEO Elaine Kerr had this to say: “It’s a delight for us to have
          won the Innovation of the Year award at last night’s edie Awards. DPD
          is working hard to meet our net zero by 2040 goal, a mission we’re on
          track to achieve. To meet this objective we’re always looking at
          innovative ways to transform our business, and our robot deliveries
          are one of the most exciting initiatives we’ve piloted - especially
          now that they’re expanding to new depots across the country. Thank you
          to the edie team for recognising our hard work in this area.”
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src={article32Img2}
            sx={{ maxWidth: '60%', m: 4 }}
          />
        </Box>
      </>
    ),
  },
  {
    id: 31,
    date: 'December 2023',
    cardTitle: 'Tree planting with the sustainability team',
    subtitle: '',
    image: article31,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Last week, the sustainability team at DPD UK had the pleasure of
          volunteering at a tree planting event in Northamptonshire as part of
          our partnership with Treeapp!
        </Typography>
        <Typography gutterBottom>
          Last month, we reached the milestone of planting over 100,000 trees in
          2023 with Treeapp, a tree having been planted for every ten deliveries
          received by a customer using the DPD app on an electric vehicle this
          year. It was great to have the opportunity to go out and plant some of
          these trees ourselves and learn more about the vital work we are
          supporting through this partnership.
        </Typography>
        <Typography gutterBottom>
          The site had most recently been a landfill, but prior to this had
          areas of natural wetland and native tree species. The aim of the
          replanting was to secure habitat highways for local mammal species,
          such as voles and shrews, alongside providing habitat for bird
          populations. The long-term goal for this site is to restore the
          natural wetlands, an important biodiversity habitat, and to provide
          educational spaces for local schools and community groups.
        </Typography>
        <Typography gutterBottom>
          Paul Herring, DPD UK Sustainability Manager said, “Since our
          partnership began in 2021 we have planted nearly 200,000 trees with
          Treeapp. Our day planting really gave an insight into how that number
          of trees changes a landscape - the areas we planted on the day went
          from being uninteresting and sterile to places that can support all
          kinds of wildlife. There were some really interesting insights on how
          trees can be used to dry out waterlogged land and individual trees can
          support many hundreds of different types of organisms. We were the
          second group to plant on behalf of DPD; it will be great to offer this
          experience to other groups within the business in the future.”
        </Typography>
      </>
    ),
  },
  {
    id: 30,
    date: 'November 2023',
    cardTitle: 'Inspiring our future workforce',
    title: 'Inspiring our future workforce',
    subtitle: '',
    image: article30,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          At DPD UK we believe that everyone should be given the opportunity to
          flourish in a job that they love and the importance of removing
          barriers to employment for those in marginalised communities. That’s
          why 5 years ago we launched our Inspire programme.
        </Typography>
        <Typography gutterBottom>
          Currently in the UK, funding is provided for young people with special
          educational needs who have an Education, Health and Care (EHC) plan,
          up to the age of 25. After this, the funding ends and for many of
          these young adults this also sees the end of their support, access to
          clubs and ability to engage in the community. In many cases, these
          young people will have had little opportunity to gain experience in a
          workplace and may not have a means of supporting themselves.
        </Typography>
        <Typography gutterBottom>
          The Inspire programme offers supported internships to those in receipt
          of funding before they turn 25 to ensure they have the chance to
          access career-building opportunities prior to the cessation of
          funding. Since launch we have supported 20 students through our
          internships, many of whom have gone on to complete further training
          and secured permanent employment within our business.
        </Typography>
        <Typography gutterBottom>
          We run an inclusive recruitment process for prospective interns,
          allowing them to decide whether DPD is the right place for them. For
          our most recent applicants we have teamed up with the Woodmor
          Foundation to offer additional skills building alongside the roles
          they will perform for DPD.
        </Typography>
        <Typography gutterBottom>
          The Woodmor Foundation offers employability initiatives designed to
          improve participants’ self-confidence and to demonstrate their skills.
          Once they have completed their time on the programme the interns will
          be provided with a ‘Me Movie’ – a digital CV that showcases their
          newly acquired skills.
        </Typography>
        <Typography gutterBottom>
          As part of this partnership we have introduced two bee hives at our
          Smethwick hub site to facilitate the employability initiatives for the
          interns joining us from January. The interns will help to care for the
          bees, harvest, and sell their honey. A great educational session
          provided by the Woodmor Foundation was enjoyed by the prospective
          interns followed by a meet- and-greet with our bees!
        </Typography>
      </>
    ),
  },
  {
    id: 29,
    date: 'November 2023',
    cardTitle: 'DPD takes home Last Mile Delivery award',
    title: 'DPD takes home Last Mile Delivery award',
    subtitle: '',
    image: article29,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Recently at the CiTTi awards in London DPD were very proud to take
          home the Last Mile Delivery award.
        </Typography>
        <Typography gutterBottom>
          The prestigious awards celebrate the achievements of the transport
          industry, and for another year we’re humbled to be able to take home a
          trophy.
        </Typography>
        <Typography gutterBottom>
          In our Last Mile Delivery submission, we focussed on everything
          sustainable we’re doing from increasing the number of electric
          vehicles we have on the road to our robot deliveries being scaled up
          to 10 new towns and cities, with support from Cartken.
        </Typography>
        <Typography gutterBottom>CEO Elaine Kerr had this to say:</Typography>
        <Typography gutterBottom>
          “I’m delighted that DPD have once again won at the CiTTi awards. We’re
          working hard to meet our net zero 2040 goal, and this award is a
          recognition that our efforts are having a real impact. We’d also like
          to give special thanks to Cartken with whom we’ve worked closely to
          deploy our incredible delivery robots, which helped us take home this
          trophy. This is a big win for both of us, and we hope to be back again
          next year.”
        </Typography>
      </>
    ),
  },
  {
    id: 28,
    date: 'November 2023',
    cardTitle: 'DPD Docklands officially opens!',
    title: 'DPD Docklands officially opens!',
    subtitle: '',
    image: article28,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD’s new flagship site in London Docklands was officially opened
          yesterday by CEO Elaine Kerr and Secretary of State for Transport,
          Mark Harper MP. The ground-breaking £40 million eco-regional sortation
          centre will sort all intra-London parcels for next-day delivery,
          instead of trunking them to the Midlands and back, as well as
          operating 500 all-electric delivery routes around the capital.
        </Typography>
        <Typography gutterBottom>
          32 major customers attended the ceremonial opening event, including
          directors from O2, Mindful Chef, Google and Harrods.
        </Typography>
        <Typography gutterBottom>
          Elaine commented: “This is a momentous day for DPD, I’m really proud
          of what’s been achieved here by all the teams involved in making this
          amazing site a reality. DPD Docklands represents a key milestone in
          our ambitious decarbonisation journey and was inspired by listening to
          our customers and understanding that going green as quickly as
          possible is now crucially important to them.”
        </Typography>
        <Typography gutterBottom>
          Mark Harper added: “It’s wonderful to see what happens behind the
          scenes at DPD. You are showing true leadership and ambition with your
          commitment to being Net Zero by 2040 – ten years ahead of the 2050
          target set by the UK government. Logistics companies are the economic
          arteries of UK plc and this incredibly green and high-tech facility is
          living proof that private sector investment drives growth.”
        </Typography>
      </>
    ),
  },
  {
    id: 27,
    date: 'November 2023',
    cardTitle: 'Over 50 million parcels delivered on EVs this year!',
    title: 'Over 50 million parcels delivered on EVs this year!',
    subtitle:
      'DPD UK is committed to being net zero by 2040 as part of Geopost’s SBTi validated target. ',
    image: article27,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          There are three key focus areas on our decarbonisation pathway: we
          procure 100% renewable energy and generate power through over 15,000
          solar panels; we adopt HVO (hydrogenated vegetable oil) in our
          linehaul fleet, for which we are on track to reach 100% by the end of
          2023; and we electrify of our final-mile delivery fleet.
        </Typography>
        <Typography gutterBottom>
          We have grown our fleet of electric vans from 70 in 2018 to over 3,600
          in 2023, 32% of our entire final-mile fleet. This has enabled us to
          increase our electric deliveries to more than 50 million parcels so
          far this year! We are proud to be able to offer this service to our
          customers, supporting them on their own decarbonisation journeys.
        </Typography>
        <Typography gutterBottom>
          With our electric van fleet set to expand further in 2024, we are
          excited to build on this achievement in the coming years and we are on
          track to have a 100% EV final-mile collection and delivery fleet by
          2030.
        </Typography>
      </>
    ),
  },
  {
    id: 26,
    date: 'October 2023',
    cardTitle: 'The future of HGV fleets',
    title: 'The future of HGV fleets',
    subtitle: '',
    image: article26,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          It was disappointing to see a company developing a zero emissions HGV
          solution declaring bankruptcy recently. DPD UK have previously
          partnered with Volta Trucks to carry out trials for their Volta Zero
          HGV. Being a trial partner affords technology providers valuable
          insight into how these vehicles are used in real-life scenarios.
        </Typography>
        <Typography gutterBottom>
          According to research from the former Government department BEIS
          (Department for Business, Energy & Industrial Strategy), HGVs account
          for 18% of greenhouse gas emissions for road transport in the UK.
          Consequently, as operators of a large HGV fleet we have a
          responsibility to continue to trial alternative technologies to see
          which is likely to meet the specific needs of our business. We can
          integrate these innovations into our carbon budget and net zero
          decarbonisation pathway.
        </Typography>
        <Typography gutterBottom>
          While we have made significant progress on HGV decarbonisation through
          the adoption of HVO (hydrogenated vegetable oil) fuel across 61% of
          our fleet – aiming for 100% by the end of 2023 – this can only get us
          to a maximum 90% reduction in emissions and is not a long-term
          solution.
        </Typography>
        <Typography gutterBottom>
          Our achievement of net zero by 2040 is reliant on innovation and
          associated infrastructure for any proposed solutions. It was therefore
          especially welcome news to hear that the government has announced £200
          million in funding for 370 zero emission trucks and their required
          infrastructure.
        </Typography>
        <Typography gutterBottom>
          We will shortly be responding to the Department for Transport’s open
          call for evidence around the future requirements of a zero emission
          heavy goods vehicle fleet and its likely refuelling and recharging
          requirements. Seizing opportunities to influence the development of
          strategy allows us to play our part in shaping the future of HGV
          fleets while making progress on our net zero pathway.
        </Typography>
      </>
    ),
  },
  {
    id: 25,
    date: 'October 2023',
    cardTitle: 'DPD scoops Sustainable Transport award',
    title: 'DPD scoops Sustainable Transport award',
    subtitle:
      'Beating stiff competition from five other companies, DPD won the Sustainable Transport award at the National Sustainability Awards!',
    image: article25,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Praise was given for the breadth of our sustainability commitments,
          going beyond electrification of our fleet and use of alternative
          fuels. Judges highlighted our achievements through innovation, such as
          robot deliveries, and the speed of our decarbonisation.
        </Typography>
        <Typography gutterBottom>
          We&apos;re very proud of our progress in decarbonisation. With an
          electric vehicle fleet of over 3,500 vans representing 32% of our
          final-mile delivery fleet, and being on track to hit 100% HVO in our
          transport fleet by the end of 2023, we have made significant progress
          towards our 2040 net zero target.
        </Typography>
        <Typography gutterBottom>
          Being at the forefront of innovation enables us to maintain the pace
          of our decarbonisation and we are further investing in this through
          our{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="https://dpdecolaunchpad.com"
          >
            EcoLaunchPad
          </ExternalLink>
          . We also recognise the importance of ensuring a just transition
          having recently surpassed 100,000 trees planted through our
          partnership with{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="/news/20"
          >
            Treeapp
          </ExternalLink>
          , directly benefiting local communities in Brazil, Indonesia, and
          Burundi.
        </Typography>
      </>
    ),
  },
  {
    id: 24,
    date: 'October 2023',
    cardTitle:
      'Over 100,000 trees planted through our partnership with Treeapp!',
    title: 'Over 100,000 trees planted through our partnership with Treeapp! ',
    subtitle: '',
    image: article24,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          In 2021 DPD partnered with Treeapp, allowing us to plant a tree for
          every 10 deliveries received by a customer in an electric vehicle -
          all part of our Go Zero Be a Hero campaign. We are proud to announce
          we have now planted over 100,000 trees through this initiative!
        </Typography>
        <Typography gutterBottom>
          Treeapp is a certified B corp, meaning it has been verified as meeting
          high standards of social and environmental performance, transparency,
          and accountability.
        </Typography>
        <Typography gutterBottom>
          The use of tree planting to combat climate change has been well
          documented thanks to the ability of trees to store large amounts of
          carbon. However, a tree must be planted for a considerable period of
          time before it starts functioning as an effective carbon store.
          Working with Treeapp enables reforestation efforts to be focused where
          there is a direct benefit to the local community, contracts are in
          place to guarantee land ownership for at least 15 years, and the tree
          species are selected with reference to native species and local seed
          providers. All of this contributes to over 85% of the trees planted
          reaching maturity and functioning effectively as a carbon store.
        </Typography>
        <Typography gutterBottom>
          As there are no monocultures or tree plantations for high volume
          production of wood supported by Treeapp, these trees do more than just
          sequester carbon. They help to restore ecosystems, stabilise soil,
          fight drought, and improve biodiversity.
        </Typography>
        <Typography gutterBottom>
          Our Treeapp partnership is supported by the{' '}
          <ExternalLink
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
            to="/social/eco-fund"
          >
            DPD Eco Fund
          </ExternalLink>
          , set up to utilise the revenue from recycling of our waste to support
          business and charitable endeavours in the fight against climate
          change.
        </Typography>
      </>
    ),
  },
  {
    id: 23,
    date: 'SEPTEMBER 2023',
    cardTitle: 'EcoLaunchpad pitch day',
    title: 'EcoLaunchpad pitch day',
    subtitle: '',
    image: article23,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD kicked off the EcoLaunchpad in June, an L Marks partnership where
          we look for companies to support us on our Net Zero 2040 journey. As
          part of our EcoLaunchpad, we specifically searched for companies
          specialising in decarbonisation of buildings, transport, and delivery.
          We also had a wildcard category where we invited companies to surprise
          us with technology and robotics that could challenge our thinking in
          this space and facilitate a digital transformation.
        </Typography>
        <Typography gutterBottom>
          After a fast-paced and informative pitch day at Hub 4 we are very
          excited to take six companies through to the next stage of the
          process. Within the categories of buildings, transport, and wildcard,
          each of these companies have a unique and innovative product to offer
          helping to solve some of DPD&apos;s biggest challenges on the road to
          Net Zero.
        </Typography>
        <Typography gutterBottom>
          The next stage will involve a deep-dive into these solutions and how
          they could add value for DPD, culminating in the successful companies
          completing a 10 week pilot programme with us.
        </Typography>
      </>
    ),
  },
  {
    id: 22,
    date: 'JULY 2023',
    cardTitle: 'DPD’s switch to HVO – all you need to know',
    title: 'DPD’s switch to HVO – all you need to know',
    subtitle: '',
    image: article22,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD has an all-electric delivery fleet of over 3,000 last-mile
          vehicles and is fully committed to being net zero by 2040, fully
          validated by the Science Based Target Initiative (SBTi). A key part of
          our strategy to decarbonise is to transition all our HGV fleet to
          Hydrogenated Vegetable Oil (HVO) by the end of 2023.
        </Typography>
        <Typography gutterBottom>
          This direct drop-in replacement for diesel, developed by Green
          Biofuels Limited, has been recognised as the lowest emission
          replacement fuel available made from renewable feedstocks. Together
          with energy provider Essar, 60% of DPD’s fleet moved to HVO by the end
          of 2022. Essar are committed to the future of decarbonisation and
          transforming for tomorrow and will be providing HVO directly to DPD’s
          onsite tanks. For DPD, this switch will lead to an 90% reduction in
          CO2 emissions across 1600 HGV’s, that’s a reduction of 70,282 from
          2021’s figures.
        </Typography>
        <Typography gutterBottom>What is HVO?</Typography>
        <Typography gutterBottom>
          Hydrogenated Vegetable Oil is a bio-based liquid fuel created using
          vegetable oils (including sunflower, soybean and rapeseed oil) and
          animal fats. By repurposing this waste, which is not suitable for
          human or animal consumption, we are striving to maximise the planet’s
          finite resources. Whilst many companies are currently embarking on the
          transition to HVO fleets, this synthetic fuel alternative has been a
          solution across various industries for the last 15 years, proving its
          viability as an environmentally friendly fix.
        </Typography>
        <BlogTable gutterBottom />
        <Typography gutterBottom>Key benefits of HVO include:</Typography>
        <ul>
          <li>
            <Typography>
              A specifically designed performance additive to ensure optimum
              combustion throughout the fuel system.
            </Typography>
          </li>
          <li>
            <Typography>
              It can be swapped with diesel immediately, without any need for
              modifications or any extra spending.
            </Typography>
          </li>
          <li>
            <Typography>
              It can be used in combination with petro-diesel without any
              depletion in performance.
            </Typography>
          </li>
          <li>
            <Typography>
              100% biodegradable, odourless and virtually non-toxic, meaning any
              potential leak or spillage of HVO would lead to extremely minimal
              environmental damage.
            </Typography>
          </li>
          <li>
            <Typography>
              Diesel operators making the switch to HVO reduce their greenhouse
              gas emissions by 90%, increasing local air quality and decreasing
              overall carbon footprint.
            </Typography>
          </li>
          <li>
            <Typography>
              Little to no environmental damage can be attributed to HVO
              production as the crops used in manufacturing do not impact the
              ecosystem.
            </Typography>
          </li>
        </ul>
        <Typography gutterBottom>
          Whilst different solutions and fuel technologies may be developed as
          the UK moves closer towards our net-zero targets, this move is
          integral to the immediate, urgently needed reduction in the
          environmental impact associated with HGV’s and the push towards
          cleaner delivery solutions. The switch to HVO (approved by the EU’s
          REDII) is the best solution currently available to make an immediate
          impact on local CO2 emissions and decarbonisation efforts. This is an
          essential step in DPD becoming the UK’s leader in sustainable
          delivery.
        </Typography>
      </>
    ),
  },
  {
    id: 21,
    date: 'JULY 2023',
    cardTitle: 'DPD partners with L Marks and launches new EcoLaunchpad',
    title: 'DPD partners with L Marks and launches new EcoLaunchpad',
    subtitle:
      'DPD is looking for companies that specialise in sustainable solutions to reduce carbon emissions in transport, delivery, and buildings for their first EcoLaunchPad.',
    image: '',
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD is partnering with L Marks to launch an EcoLaunchPad to discover
          additional sustainable solutions for their business. This initiative
          will be centered around carbon reduction in transport, delivery, and
          buildings, with the goal of minimising DPD's environmental impact.
        </Typography>
        <Typography gutterBottom>
          The EcoLaunchPad will bring together experts in sustainability,
          technology, and innovation to develop and test new ideas that can help
          DPD further reduce its carbon footprint. This partnership is a key
          step towards DPD's goal of reaching net zero by 2040.
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications &amp; Sustainability
          DPDgroup UK commented,"We are delighted to be partnering with L Marks
          to launch this EcoLaunchPad. The project will play a key part in
          shaping our sustainability strategy and helping achieve our stated aim
          of being the most sustainable parcel delivery company in the UK.
          Investing in and developing new sustainable technologies and
          innovations is crucial in the next few years to ensure we can reach
          net zero, and partnering with experts in this way can accelerate that
          progress."
        </Typography>
        <Typography gutterBottom>
          L Marks will scout and identify businesses to apply for a unique
          opportunity to take their innovative ideas to the next level. The
          EcoLaunchPad will be a ten-week immersive programme where participants
          will have the opportunity to validate their solutions, access to DPD's
          network and resources, and mentorship from industry experts.
        </Typography>
        <Typography gutterBottom>
          Applications open at 09:00am on the 12th of June 2023 seeking to find
          solutions in the following areas:
        </Typography>
        <Typography gutterBottom>
          Carbon Emission Reduction in Transport: Fleet Decarbonisation
        </Typography>
        <Typography gutterBottom>
          Carbon Emission Reduction in Collection &amp; Delivery
        </Typography>
        <Typography gutterBottom>
          In order to be the UK's most sustainable delivery company, DPD is
          investing heavily in their all-electric delivery fleet, which
          currently consists of 3,000 electric vehicles in their operation's
          Collection and Delivery area. DPD is looking for solutions to make
          driving electric vehicles easier for drivers. Solutions may include EV
          charging stations and alternative electric vehicles.
        </Typography>
        <Typography gutterBottom>
          Carbon Emission Reduction in Buildings: 'Hub of the Future'
        </Typography>
        <Typography gutterBottom>
          DPD operates across a network of more than 100 buildings, including
          depots, hubs, and offices. As this number is constantly growing,
          reducing the carbon footprint of these buildings and the operations
          that take place within them is essential. DPD wants to find the
          leading solutions and innovations that can be retrofitted into their
          current depots and/or installed in their future depots. Looking
          further out, DPD is also looking to design and build depots in the
          'greenest' way possible moving forward.
        </Typography>
        <Typography gutterBottom>
          "We are excited to be working with DPD on this important initiative,"
          said Daniel Saunders, CEO of L Marks. "The EcoLaunchPad is a testament
          to DPD's commitment to sustainability and their willingness to embrace
          new ideas and technologies to achieve their goals. As the world
          becomes more conscious of the impact of carbon emissions, companies
          like DPD are leading the way in finding innovative solutions that
          safeguard the planet for future generations''.
        </Typography>
        <Typography gutterBottom>
          Applications will close on 16/07/23.
        </Typography>
        <Typography gutterBottom>
          Further information on the EcoLaunchPad and how to apply can be found
          on the website:{' '}
          <ExternalLink
            to="https://dpdecolaunchpad.com/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            https://dpdecolaunchpad.com/
          </ExternalLink>
          .
        </Typography>
      </>
    ),
  },
  {
    id: 20,
    date: 'JULY 2023',
    cardTitle: 'DPD to roll-out robot deliveries to 10 UK towns and cities',
    title: 'DPD to roll-out robot deliveries to 10 UK towns and cities',
    subtitle:
      "Raunds becomes DPD's second robot base following successful Milton Keynes trial",
    image: article20,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery company DPD has confirmed plans to expand
          autonomous robot deliveries to 10 UK towns and cities in the next 12
          months, following a successful trial in Milton Keynes, starting with
          Raunds in Northamptonshire.
        </Typography>
        <Typography gutterBottom>
          The announcement extends DPD's relationship with AI-powered robotics
          and last mile delivery specialists, Cartken and follows work by DPD
          that identified up to 30 UK depot locations which could potentially
          deploy robots.
        </Typography>
        <Typography gutterBottom>
          Cartken's level 4 autonomy and leading micro-AV technology platform is
          regarded as one of the safest last mile delivery solutions on the
          market and enables full autonomy on footpaths with only cameras - one
          of the only companies in the autonomous vehicle delivery space to
          achieve this with a purely vision-based system. DPD first introduced
          the Cartken robots in Milton Keynes in July 2022 and they have since
          travelled almost 500 miles and successfully performed over 2,500 trips
          delivering parcels to DPD customers.
        </Typography>
        <Typography gutterBottom>
          Based at DPD's depot at Warth Park on the western edge of Raunds, the
          robots will deliver to nearby residential areas within a mile of the
          depot, via the existing network of footpaths. In Raunds, the robots
          will have three separate parcel compartments, instead of just one in
          the original trial, and an extended battery life of up to 12 hours.
        </Typography>
        <Typography gutterBottom>
          DPD parcel recipients in Raunds will be notified of a robot delivery
          in advance, and once they confirm that they are at home to accept the
          parcel, the robot will be dispatched.
        </Typography>
        <Typography gutterBottom>
          Customers will then be able to track the robot's progress on a map and
          are notified when it reaches their property. They then use a code to
          open the secure compartment and access their parcel. Once the
          compartment is closed, the robot will either continue to its next
          delivery or return to the depot.
        </Typography>
        <Typography gutterBottom>
          DPD has worked closely with North Northamptonshire Council on the
          introduction of the robots in Raunds. Cllr Graham Lawman, North
          Northamptonshire Council's Executive Member for Highways, Travel and
          Assets, said: "We are delighted that DPD is introducing delivery
          robots in Raunds. The council is committed to the environment and
          supporting clean and green alternatives to traditional forms of
          transport to reduce emissions. It is great to be able to help bring
          this innovation to North Northamptonshire."
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications &amp; Sustainability
          DPDgroup UK commented, "The trial in Milton Keynes has been a huge
          success for us. The robots have exceeded our expectations, and the
          feedback has been fantastic. We can see people's reactions when they
          meet them, and it is overwhelmingly positive. We set out to understand
          the role that delivery robots could play in certain locations in the
          UK and the trial has helped us identify more sites where we could
          deploy the robots successfully.
        </Typography>
        <Typography>
          "Our aim is to be the UK's most sustainable delivery company and that
          means investing in innovations and trying new things. We are well on
          our way to decarbonising our van fleet with EVs and we are also making
          a big step forward by switching our HGVs to renewable biofuel, but we
          are also interested in brand new solutions that can help us transition
          to a smarter, more sustainable future."
        </Typography>
        <Typography>
          Cartken Co-Founder and COO, Anjali Jindal Naik, commented, "Autonomous
          mobility will be a key lever for decarbonisation, as it transforms
          last-mile transportation into a much greener process. By partnering
          with delivery companies, such as DPD, we're propelling this
          transformation forward, making sustainable delivery the norm, rather
          than a nice to have.
        </Typography>
        <Typography>
          "2023 has been a year of tremendous growth for Cartken and we're only
          in Q2. Developing our partnership with DPD is a critical step in
          expanding our presence globally, beginning with the UK, while also
          being at the forefront of sustainable last-mile delivery by cutting
          down on C02 emissions."
        </Typography>
      </>
    ),
  },
  {
    id: 19,
    date: 'JULY 2023',
    cardTitle:
      'DPD UK to trial innovative mobile charging solution with OnCharge Energy',
    title:
      'DPD UK to trial innovative mobile charging solution with OnCharge Energy',
    subtitle:
      'Leading parcel delivery company DPD UK is set to trial an innovative, new mobile power bank charging concept that could provide a solution for commercial van drivers unable to charge their vehicles at home overnight.',
    image: article19,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          The OnCharge ZIR0 prototype charging system will be trialed at DPD's
          Bicester eco-depot - the most sustainable facility in the company’s UK
          network. The multi-van trial will allow drivers to collect a take-home
          powerbank from the depot, before driving home. The prototype power
          bank will charge the main traction battery of the van overnight,
          resulting in a 100% charge by the start of the driver’s day.
        </Typography>
        <Typography gutterBottom>
          The prototype is the world’s first purpose-built ruggedised powerbank,
          designed for fleet commercial vehicles. With a real operating capacity
          of 50kWh, the system is designed to improve the Electric Light
          Commercial Vehicle (eLCV) driver experience. The OnCharge system takes
          just 90 seconds to load in the evening, and unload in the morning.
        </Typography>
        <Typography gutterBottom>
          The OnCharge system has the potential to provide a solution to drivers
          that don’t have access to off-street parking and will help avoid
          reliance on public charging.
        </Typography>
        <Typography gutterBottom>
          The prototype trials will allow OnCharge and DPD engineers to gather
          field data and review driver feedback. It will also provide insight
          into potential integration with DPD’s Bicester on-site solar panel
          array.
        </Typography>
        <Typography gutterBottom>
          The trial is part of OnCharge Energy’s Field Test and Development
          programme and a key step towards scaling up customer production in
          late 2023.
        </Typography>
        <Typography gutterBottom>
          DPD's aim is to be the most sustainable parcel delivery company in the
          UK and the company is on track to have over 4,000 electric vehicles
          (EVs) on the road by the end of this year, when it will be delivering
          to 30 towns and cities using electric vehicles only. Nearby Oxford was
          DPD’s first ‘green city’, with all deliveries made by the Bicester
          eco-depot’s zero emission delivery vehicles.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability, DPD UK commented,
        </Typography>
        <Typography gutterBottom>
          "It’s fantastic to work with OnCharge to try this system in the
          real-world. Working together will give us the opportunity to really
          understand the impact it can have on our drivers first-hand. It allows
          both us and OnCharge to see how the system works under different
          scenarios and allows our transport and sustainability teams to explore
          how an agile powerbank model can amplify our investment in EV and
          on-site renewable generation.”
        </Typography>
        <Typography gutterBottom>
          Philip Clarke, CEO OnCharge Energy commented, “In terms of
          productivity, the most efficient time to charge a commercial EV is
          overnight, but around 70% of fleet van drivers can’t charge at
          home.&nbsp; We believe that a take-home powerbank that you can collect
          at the end of the working day could revolutionise commercial EV fleet
          management and help encourage more drivers to switch to EVs. DPD
          already have a huge amount of experience running a large EV fleet and
          the Bicester eco-depot is the perfect place to trial our solution. We
          are looking forward to working with the team and hopefully making
          their EV fleet even more efficient and effective.”
        </Typography>
        <Typography gutterBottom>
          Details on all of DPD’s sustainability initiatives can be found on its
          dedicated DPD green website.{' '}
          <ExternalLink
            to="https://green.dpd.co.uk/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            green.dpd.co.uk
          </ExternalLink>
        </Typography>
        <Typography gutterBottom>
          Details on the OnCharge Energy service can be found on its website at{' '}
          <ExternalLink
            to="https://www.oncharge.energy/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            www.oncharge.energy
          </ExternalLink>
        </Typography>
      </>
    ),
  },
  {
    id: 18,
    date: 'JULY 2023',
    cardTitle:
      'DPD sign for Forest Green Rovers with two-year partnership deal',
    title: 'DPD sign for Forest Green Rovers with two-year partnership deal',
    subtitle:
      "Partnership between two of the UK's green leaders Leading parcel delivery company DPD has signed a two-year partnership deal with football club Forest Green Rovers.",
    image: article18,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          The deal brings together two of the most sustainable brands in their
          respective fields. DPD's stated aim is to be the most sustainable
          delivery company in the UK, while Forest Green Rovers have been
          recognised by global footballing body FIFA as the greenest football
          club in the world.
        </Typography>
        <Typography gutterBottom>
          DPD, which has over 3,000 electric vehicles on the road in the UK, has
          been the club's parcel delivery partner for the past two seasons,
          delivering replica kit and merchandise for fans. Today's announcement
          will see that partnership extended with a new two-year deal through to
          the end of the 2024/25 season.
        </Typography>
        <Typography gutterBottom>
          The deal means that DPD's logo and branding will appear on the back of
          player's shirts for the 2023-24 and 2024-25 seasons. Perimeter
          advertising and hospitality packages are also included. progress."
        </Typography>
        <Typography gutterBottom>
          Forest Green Rovers (FGR) is known for being the only vegan football
          club in the world and in 2017, FGR chairman Dale Vince was appointed
          as a Climate Champion for the United Nations who also recognised FGR
          as the first Carbon Neutral football club in the world as part of the
          UN Climate Neutral Now pledge. The club's green initiatives cover
          every aspect of its operation including energy, transport, food, waste
          and supporting nature and biodiversity.
        </Typography>
        <Typography gutterBottom>
          Going forward, the club has planning permission to build a sustainable
          Eco Park complex which will include a new 5,000 capacity,
          state-of-the-art wooden stadium, and training facility.
        </Typography>
        <Typography gutterBottom>
          DPD opened the UK's first all-electric parcel delivery depot in 2018
          and by the end of this year will be delivering to 30 of the largest
          towns and cities in the UK using only all-electric final mile delivery
          vehicles. By that time, the DPD fleet will include 4,000 electric vans
          and all 1,600 of its HGVs will have been converted from diesel to
          renewable biofuel. Across the DPD network, buildings are powered by
          solar panels and renewable electricity, while 100% of waste avoids
          landfill. Recycling initiatives generate cash for the innovative DPD
          Eco Fund which awards funding to sustainable projects benefiting the
          community and the environment.
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications &amp; Sustainability
          DPDgroup UK commented, "We are absolutely delighted to be partnering
          up with Forest Green Rovers. The club has been such a leader on
          sustainability, not just within sport, they have shown all
          organisations what is possible. We are proud to be their delivery
          partner and just as proud to see our name on their shirts next season.
          Together we are reducing emissions, investing in green innovation and
          helping protect the planet for future generations. We wish them every
          success on and off the pitch in the next two seasons and hopefully
          together we can continue to raise awareness of what can be achieved."
        </Typography>
        <Typography gutterBottom>
          Forest Green Rovers chairman, Dale Vince, commented: "Transport is one
          of the big three drivers of the climate crisis - and a focus of our
          work at FGR. Delivering stuff is a key part of that and it's fantastic
          to see the work DPD are doing, specifically the electrification of
          parcel deliveries. This is how we get to a Green Britain - and at the
          same time clean up the air that we breathe. As a club we're very
          excited to have the opportunity to work with DPD."
        </Typography>
      </>
    ),
  },
  {
    id: 17,
    date: 'MARCH 2023',
    cardTitle: 'DPD supports RSPB with wetlands restoration project',
    title: 'DPD supports RSPB with wetlands restoration project',
    subtitle:
      "Parcel delivery firm DPD has donated £90,000 to the RSPB to support the nature conservation charity's work restoring a sand and gravel quarry on the banks of the River Trent, in Nottinghamshire.",
    image: article17,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Langford Lowfields is a flagship conservation project, created out of
          a partnership involving the RSPB and Tarmac. The reserve currently
          covers 120ha and provides a haven for nature. At the heart of the site
          sits the largest reedbed in the East Midlands, surrounded by
          wildflower-rich meadows, blocks of scrub and an area of mature
          woodland. Wildlife highlights include booming bitterns, brown hares,
          breeding bearded tits, marsh harriers, avocets, large flocks of
          wintering wildfowl and mesmerising starling murmurations.
        </Typography>
        <Typography gutterBottom>
          Managing water levels at Langford Lowfields is essential to keep the
          reedbed healthy and attractive to the range of wildlife that uses this
          threatened habitat, but this management is currently hindered by old,
          failing sluices.
        </Typography>
        <Typography gutterBottom>
          DPD has donated £90,000 from the firm’s innovative Eco Fund to help
          the RSPB install two new, double-leaf penstock sluices, to ensure
          water levels can be accurately and efficiently controlled, aiding the
          on-going establishment of the reedbed and increasing the biodiversity
          of the site. The funding will enable 18 hectares of wetland to be
          managed effectively for nature, equivalent to 144 Olympic-size
          swimming pools.
        </Typography>
        <Typography gutterBottom>
          The work will be carried out during the late summer, after the bird
          breeding season and once water levels are as low as possible, with the
          project due to complete by the end of Autumn 2023.
        </Typography>
        <Typography gutterBottom>
          DPD’s Eco Fund is part of the company’s wide-ranging sustainability
          programme and is funded solely by the firm’s circular economy
          initiatives. Plastic shrink wrap used throughout the operation is
          broken down, and then recycled to create new shrink wrap. The money
          generated from this, along with the recycling of wooden pallets, goes
          straight into the Eco Fund to fund sustainable projects benefiting the
          environment.
        </Typography>
        <Typography gutterBottom>
          Rebecca Munro, Director of Income and Conservation Investment, the
          RSPB commented; “Wetlands are some of the most biodiverse places on
          the planet and provide a vital habitat for wildlife. Globally,
          freshwater species have declined by 83% since the 1970s, so it’s
          essential we manage our existing wetlands in the best way for nature.
          We’re thrilled that DPD’s generous donation will help the RSPB to
          improve precious wetland habitat and boost biodiversity at Langford
          Lowfields. This is a fantastic demonstration of how businesses can act
          for nature, and I look forward to seeing the results over the year.”
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications &amp; Sustainability,
          DPDgroup UK commented; “We are absolutely delighted to be able to
          support the RSPB on this project. Our aim is to be the most
          sustainable parcel delivery company in the UK and as well as
          transforming our own operation, we want to support organisations able
          to directly protect and enhance the ecosystems we are all reliant on.
          Langford Lowfields is already a great example of how business and
          conservation can co-exist and we look forward to working together this
          year and to making a difference for many years to come.”
        </Typography>
        <Typography gutterBottom>
          DPD’s Eco Fund is also supporting WWF’s ground-breaking Seagrass Ocean
          Rescue project, which aims to help restore 15% of seagrass meadows
          across the UK by 2030, to capture and store hundreds of tonnes of
          carbon each year. While in 2022, DPD announced a four-year partnership
          with Forestry England to plant and restore four separate woodland
          sites across England.
        </Typography>
      </>
    ),
  },
  {
    id: 16,
    date: 'FEBRUARY 2023',
    cardTitle: '35,000,000 Million Electric Deliveries and counting…',
    title: '35,000,000 Million Electric Deliveries and counting…',
    subtitle:
      '2021 saw us deliver over 17,000,000 parcels on our all-electric final mile delivery fleet, in 2022 we smashed this delivering over 35,000,000 parcels on our all-electric final mile delivery fleet, more than double our previous best!',
    image: article16,
    mobileImage: '',
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          That’s over 9,000,000 kilograms of CO2e saved which is equal to the
          co2 absorption of 360,000 trees over a year.
        </Typography>
        <Typography gutterBottom>
          This year we are targeting 50,000,000 parcels delivered on our
          all-electric fleet. To achieve this we have placed an order for a
          further 1,000 Ford E-Transit delivery vans which are scheduled for
          delivery throughout 2023.
        </Typography>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          Other 2022 milestones
        </Typography>
        <Typography gutterBottom>
          Introduced the alternative fuel HVO (Hydrogenated Vegetable Oil) to
          60% of our Heavy Goods Vehicle (HGV) fleet. HVO is an alternative to
          diesel which is made from waste fryer oil, it is compatible with
          normal diesel engines in a 100% mix, and offers a reduction in CO2e of
          up to 90% compared to standard diesel.
        </Typography>
        <Typography gutterBottom>
          And we now plan to move 100% of our HGV fleet to HVO by the end of
          2023.
        </Typography>
        <Typography gutterBottom>
          We continued our journey of electrification as we expanded our
          electric fleet to 3000 vehicles with 1000 new Ford E-Transits, 500
          Maxus ED9s and 250 Maxus ED3s joining our fleet throughout 2022.
        </Typography>
        <Typography gutterBottom>
          Following the launch of our partnership with Tree App, we planted over
          61,000 trees on DPD app users behalf.
        </Typography>
        <Typography gutterBottom>
          We continued our partnership with forestry England which saw the
          continued replanting at Wareham Forest in Dorset and the planting of
          more than 4,000 trees at Colliers Wood an urban woodland covering 136
          hectares of a former coal mining site. We are supporting the WWF with
          £40,000 pledged to plant 1,600 m2 of seagrass in The Solent.
        </Typography>
        <Typography gutterBottom>
          Following on from the launch of our partnership with FareShare in
          2021, we donated 10,345 parcels to FareShare food bank locations
          across the country.
        </Typography>
        <Typography gutterBottom>
          In 2022 DPD Group submitted an ambition net zero target to the Science
          Based Target initiative. This target would commit DPD Group to a net
          zero target of 2040 and a short term reduction target of 2030 of 43%
          against a base year of 2020. This target was recently validated by
          SBTi in February 2023.
        </Typography>
        <Typography gutterBottom>
          In 2022 we generated over 5,000,000 KWH of renewable energy across our
          network of solar PV systems which we have installed across our hub and
          depot sites. We continued to operate our renewable only electricity
          tariff ensuring that our electricity is as green as possible.
        </Typography>
        <Typography gutterBottom>
          In 2022 we reused or recycled 90% of our waste whilst continuing to
          ensure 100% of our waste avoids landfill.
        </Typography>
        <Typography gutterBottom>
          We continued to offer carbon neutral deliveries at no additional cost
          to our customers.
        </Typography>
      </>
    ),
  },
  {
    id: 15,
    date: 'FEBRUARY 2023',
    cardTitle:
      'In July 2022 DPDgroup submitted a group wide net zero target to the Science Based Target initiative',
    title:
      'In July 2022 DPDgroup submitted a group wide net zero target to the Science Based Target initiative',
    subtitle:
      'We are delighted to announce that this target has now been validated by the SBTi.',
    image: article15,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Our commitment is made up of 2 targets both based on a 2020 base year.
          Our near term reduction target will see us reduce our greenhouse gas
          emissions by 43% by 2030 we will then push on to our 90% reduction net
          zero target of 2040.
        </Typography>
        <Typography gutterBottom>
          The Science Based Target initiative is a global body enabling
          businesses to set emissions reduction targets based on the latest
          climate science and is widely recognised as leading the way for
          setting corporate net-zero commitments.
        </Typography>
        <Typography gutterBottom>
          The SBTi is a collaboration between CDP, the United Nations Global
          Compact, the World Resources Institute and the WWF.
          <div>
            <a href={SBTiCertificate}>View our SBTi certificate here</a>
          </div>
        </Typography>
      </>
    ),
  },
  {
    id: 14,
    date: 'NOVEMBER 2022',
    cardTitle: 'DPD confirms engineering tests with Volta Zero prototype',
    title: 'DPD confirms engineering tests with Volta Zero prototype',
    subtitle:
      'Leading parcel delivery company DPD has completed a series of engineering tests with the full-electric Volta Zero, as part of wider Beta Trials for the new, 16-tonne vehicle.',
    image: article14,
    mobileImage: article14Mobile,
    embedImage: article14Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          The Design Verification prototype vehicle was based at the firm's
          Hinckley Superhub in Leicestershire. DPD's drivers were accompanied by
          Volta Trucks engineers and qualified drivers and, while no parcels
          were onboard, the truck was tested on a range of pre-determined routes
          including motorways, A-roads and urban centres.
        </Typography>
        <Typography gutterBottom>
          The Volta Zero is the world's first purpose-built full-electric
          16-tonne vehicle, designed for urban logistics. With an operating
          pure-electric range of 95 - 125 miles, the truck is designed to
          significantly reduce the environmental impact of freight deliveries in
          city centres.
        </Typography>
        <Typography gutterBottom>
          The truck has also been designed from the ground-up with vehicle,
          driver and pedestrian safety in mind. The combination of a central
          driving position with a much lower seat height and the glass
          house-style cab design gives the driver a wide 220-degrees of
          visibility, minimising dangerous blind spots.
        </Typography>
        <Typography gutterBottom>
          The Beta Trials are part of Volta's Test and Development programme and
          a key step towards the start of customer Production in early 2023.
        </Typography>
        <Typography gutterBottom>
          DPD's aim is to be the most sustainable parcel delivery company in the
          UK and the company is on track to have over 3,000 electric vehicles
          (EVs) on the road this year and 4,000 by 2023, when it will be
          delivering to 30 towns and cities using electric vehicles only.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability, DPD commented, “It was
          fantastic to get our hands on a Volta Zero and put it through its
          paces, with our own drivers alongside Volta Trucks' engineers. We had
          the truck for five days and it is very impressive. The Beta Trials are
          an opportunity for us to test the vehicle first-hand, while helping
          Volta Trucks see exactly how it performs in real-life scenarios and on
          typical routes.
        </Typography>
        <Typography gutterBottom>
          “Working with the Volta Trucks team in the cab gave us a great insight
          into what they are doing and how the truck could fit into the fleet.
          Increasingly we are operating in low or zero-emission zones in major
          cities, so with micro-depots and all-electric city centre van fleets
          we have already transformed our urban delivery strategy. But there is
          a real need for bigger, green vehicles that can fit into that
          strategy.”
        </Typography>
      </>
    ),
  },
  {
    id: 13,
    date: 'NOVEMBER 2022',
    cardTitle: 'DPD to switch all HGVs to renewable biofuel',
    title: 'DPD to switch all diesel HGVs to renewable biofuel by end 2023',
    subtitle:
      "87% reduction in CO2 emissions across 1,600 HGVs, 60% of DPD's HGV fleet will switch to biofuel this year.",
    image: article13,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery company DPD has announced that it is to switch
          its entire diesel HGV fleet to Gd+ HVO, an advanced hydrogenated
          vegetable oil (HVO) fuel, in conjunction with integrated energy
          provider, Essar and Green Biofuels Ltd.
        </Typography>
        <Typography gutterBottom>
          Gd+ HVO is a direct drop-in replacement for diesel, developed by Green
          Biofuels Ltd and is defined as renewable and sustainable under the
          EU's REDII legislation. It is the lowest-emission diesel replacement
          fuel available made from renewable feedstocks, and contains a
          specifically designed performance additive to ensure cleanliness and
          optimum combustion throughout the fuel system.
        </Typography>
        <Typography gutterBottom>
          Gd+ HVO can be used in any diesel engine without the need for
          modifications or capital expenditure. It is approved by vehicle
          manufacturers, and there is no degradation in performance.
        </Typography>
        <Typography gutterBottom>
          By using Gd+ HVO fuel, operators of diesel engines typically save
          lifecycle greenhouse gas emissions by 90%1, and consequently help
          improve local air quality2. In-field and controlled
          environment-independent tests have shown that compared to standard
          diesel emissions, Gd+ HVO achieves up to 80% reductions of
          particulates and up to 20% reductions of nitrogen oxides emissions.3
        </Typography>
        <Typography gutterBottom>
          DPD will begin switching its 1,600 strong HGV fleet to Gd+ HVO
          immediately, and following a four-month trial, the company aims to
          convert 60% of its vehicles within 2022 - reducing emissions by 70,282
          tonnes compared to 2021. The remaining vehicles will switch by the end
          of 2023. Gd+ HVO will be delivered directly to DPD's onsite tanks
          nationwide by existing bulk fuel supplier Essar.
        </Typography>
        <Typography gutterBottom>
          Justin Pegg, Chief Operating Officer at DPD commented, “This is a very
          significant step in the journey to decarbonising our entire fleet and
          achieving our aim of being the most sustainable parcel delivery
          company in the UK. While we are well on the way to electrifying our
          delivery van fleet, the HGV linehaul fleet has always been a very
          different challenge. We have assessed a range of options and it is
          clear now that this is the most effective and practical way to make a
          real difference. While there may be different solutions in years to
          come, we must start this process now by making our existing vehicles
          significantly cleaner.”
        </Typography>
        <Typography gutterBottom>
          Carlos Rojas, Head of Marketing & Logistics at Essar, said: “Essar is
          committed to playing a leading role in the decarbonisation of the UK
          economy and manufacturing the fuels of the future in a lower carbon
          way. As a key partner of choice, we have worked seamlessly to provide
          integrated energy, infrastructure, and logistical solutions to DPD
          nationally for over ten years and are proud to be its strategic supply
          partner as it decarbonises its fleet.”
        </Typography>
        <Typography gutterBottom>
          William Tebbit, CEO and Co-Founder of Green Biofuels, said: “We are
          delighted to be working with Essar and DPD. Our mission is to support
          the net-zero energy transition by providing a solution that that makes
          an immediate impact on local air quality, lowers CO2 emissions, and
          supports decarbonisation. The challenges faced by DPD are typical of
          most large fleets in the UK; however, Gd+ HVO provides results with no
          additional costs of transforming the engines.
        </Typography>
        <Typography gutterBottom>
          Green Biofuels offers the most effective solution available today - it
          is an essential ingredient for the transition to an environmentally
          friendly world fuelled by 100% renewable energy.”
        </Typography>
        <Typography gutterBottom>
          Details on all of DPD's sustainability initiatives can be found on its
          dedicated DPD green website and include plans to have over 3,000
          electric vehicles on the road in the UK this year and a total of 4,000
          by 2023, delivering to 30 towns and cities on all-electric vehicles
          only.
        </Typography>
      </>
    ),
  },
  {
    id: 12,
    date: 'NOVEMBER 2022',
    cardTitle: 'First Bus and DPD partnership',
    title:
      'First Bus and DPD launch inaugural partnership in bid to make Glasgow greener',
    subtitle:
      'One year on from COP26, First Bus sees its legacy ambitions come to life.',
    image: article12,
    embedImage: article12Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          First Bus is helping UK delivery company, DPD, to carry out more green
          journeys in Glasgow in a milestone move which sees the transport
          operator's ambitions from COP26 come to life.
        </Typography>
        <Typography gutterBottom>
          At the global climate change conference last year, First Bus set out
          its aim offer local businesses use of the extensive electric vehicle
          (EV) charging infrastructure at its Caledonia Depot.
        </Typography>
        <Typography gutterBottom>
          One year on, DPD has become the first company to officially sign up to
          the scheme. The agreement means that DPD drivers will now have access
          to the site to charge their electric vehicles whilst in Glasgow,
          enabling them to travel a greater distance while making deliveries -
          without impacting the environment.
        </Typography>
        <Typography gutterBottom>
          Through this arrangement, the green potential of First Bus's charging
          hub will be maximised while its own electric fleet is out in service.
        </Typography>
        <Typography gutterBottom>
          Graeme Macfarlan, Commercial Director at First Bus, said: “The
          partnership with DPD reflects the commitment we made during COP 26 to
          help other businesses to achieve their green ambitions. We hope that
          this marks the first of many valuable partnerships and demonstrates
          the power that comes from working together to reduce carbon emissions.
        </Typography>
        <Typography gutterBottom>
          “Electrifying fleets, and having the infrastructure in place to
          support them, is a mammoth undertaking, and it simply isn't feasible
          for each and every business to build its own charging station.
          Reducing carbon emissions is a priority for us - not just to meet our
          own targets but because we’re committed to delivering cleaner, greener
          journeys for Glasgow. We're already in conversation with other
          businesses and look forward to announcing further partnerships in the
          near future.”
        </Typography>
        <Typography gutterBottom>
          Located on Glasgow's southside, First Bus's Caledonia Depot has 160
          state-of-the art, rapid-charging points, supporting the operator's
          ambition to be emission-free by 2035.
        </Typography>
        <Typography gutterBottom>
          Minister for Transport, Jenny Gilruth, said: “We all have to work
          together to end our contribution to climate change, and by making its
          charging infrastructure available to DPD, First Bus are leading the
          way.
        </Typography>
        <Typography gutterBottom>
          “The Scottish Government provided over £5.9 million to help deliver
          the infrastructure at the depot, and I am delighted that it is being
          used to support decarbonisation of last mile delivery as well as
          public transport.
        </Typography>
        <Typography gutterBottom>
          “Our £500k Bus Market Transition Scheme, which aims to support
          businesses prepare for our next round of capital funding, is currently
          open for applications and I hope that this announcement today by First
          Bus encourages others to think creatively about how Government funding
          can be used to maximum effect.”
        </Typography>
        <Typography gutterBottom>
          DPD's aim is to be the most sustainable parcel delivery company in the
          UK and the company is on track to have over 3,000 EVs on the road this
          year and 4,000 by 2023, when it will be delivering to 30 towns and
          cities, including Glasgow, using EVs only.
        </Typography>
        <Typography gutterBottom>
          Initially, eight DPD EVs from the firm's Cambuslang depot will access
          the Caledonia site, as part of the trial period. The expectation is
          that this number will increase to help support DPD's growing EV fleet
          in the city, which is planned to total close to 200 electric vans by
          the end of next year.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability, DPD said: “The Caledonia depot
          is a fantastic facility and this is a really smart initiative between
          two brands that are investing in a greener future for Scotland. The
          partnership will be hugely beneficial as it will enable our drivers to
          access fast, reliable and secure charging stations in Glasgow during
          the day. Our plan is to be delivering in both Glasgow and Edinburgh
          city centres using only electric vehicles by the end of next year, and
          this is another step towards making that a reality.”
        </Typography>
      </>
    ),
  },
  {
    id: 11,
    date: 'OCTOBER 2022',
    cardTitle: 'Passionate about Parcels and our Planet!',
    title: 'Passionate about Parcels and our Planet!',
    subtitle:
      'DPD urges all companies to think big and help rewild Britain. Find the organisations that are helping protect our precious natural environment and work with them directly. Listen to your people who will get creative and find inventive ways of raising money and supporting local community rewilding projects too.',
    image: article11,
    mobileImage: '',
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          These projects don't have to cost the earth
        </Typography>
        <Typography gutterBottom>
          DPD has funded its 'Green' rewilding projects from the efforts it has
          put into upcycling its waste. DPD created an innovative Eco Fund,
          which receives money from circular economy initiatives, such as
          recycling shrink wrap and wooden pallets. The Eco Fund awards grants
          to projects such as the WWF's ground-breaking restoration of seagrass
          meadows in UK coastal waters, helping capture hundreds of tonnes of
          carbon each year around the UK.
        </Typography>
        <Typography gutterBottom>
          DPD is also working with Forestry England's regeneration of the vital
          habitats at Wareham Forest where in May 2020 a fire scorched
          heathland, destroyed woodlands and wiped-out critical habitats for
          wildlife across an area equivalent to the size of 350 football
          pitches.
        </Typography>
        <Typography gutterBottom>
          In February 2022, Forestry England and DPD announced a four-year
          partnership to plant and restore woodland across England to tackle
          climate change and create positive benefits for local wildlife and
          communities.
        </Typography>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          One final sobering statistic for you:
        </Typography>
        <Typography gutterBottom>
          The UK has lost almost half of its wildlife and plant species as a
          result of human and land development since the Industrial Revolution
        </Typography>
        <Typography gutterBottom>
          Biodiversity supports all life on Earth, including us, act now and
          help protect our precious planet.
        </Typography>
      </>
    ),
  },
  {
    id: 10,
    date: 'OCTOBER 2022',
    cardTitle: 'DPD supports seagrass restoration project',
    title: 'DPD supports ground-breaking WWF seagrass restoration project',
    subtitle:
      'The Seagrass Ocean Rescue programme is helping to re-establish seagrass meadows in UK coastal waters which will capture hundreds of tonnes of carbon each year.',
    image: article10,
    embedImage: article10Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Parcel delivery firm DPD has donated to leading independent
          conservation organisation WWF (World Wide Fund for Nature) to support
          the charity's ground-breaking seagrass restoration project - Seagrass
          Ocean Rescue, a collaboration with Swansea University and Project
          Seagrass.
        </Typography>
        <Typography gutterBottom>
          The Project aims to help restore 15% of seagrass meadows across the UK
          by 2030, to capture and store hundreds of tonnes of carbon each year
          and help play a role in the UK reaching its net-zero targets.
        </Typography>
        <Typography gutterBottom>
          Globally seagrass has been found to be more effective at absorbing and
          storing carbon than tropical rainforests. However, these sensitive
          seabed habitats are at risk from a variety of factors, including
          pollution and physical disturbance from human activities.
        </Typography>
        <Typography gutterBottom>
          In addition, planting seagrass meadows can significantly enhance the
          biodiversity and health of the UK's seas and coastal ecosystems.
        </Typography>
        <Typography gutterBottom>
          DPD has donated £40,000 from the firm's innovative Eco Fund to help
          support phase two of the programme in The Solent. The work between now
          and 2024 will include working in partnership with government agencies
          on ecological field trials on best planting methodologies for seagrass
          restoration, alongside community and stakeholder engagement and the
          delivery of innovative educational programmes.
        </Typography>
        <Typography gutterBottom>
          DPD's Eco Fund is part of the company's wide-ranging sustainability
          programme and is funded solely by the firm's circular economy
          initiatives. Plastic shrink wrap used throughout the operation is
          broken down, and then recycled to create new shrink wrap. The money
          generated from this, along with the recycling of wooden pallets, goes
          straight into the Eco Fund to fund sustainable projects benefiting the
          environment.
        </Typography>
        <Typography gutterBottom>
          Ricardo Zanre, Ocean Restoration Programme Manager at WWF-UK
          commented: "We're thrilled that DPD has chosen to donate £40,000
          towards the Seagrass Ocean Rescue project in The Solent. Thanks to
          this support we're able to deliver our ground-breaking restoration
          project, trialling the latest seagrass planting methodologies and
          building towards our long-term aim, which is to see 15% of seagrass
          meadows restored across the UK by 2030.
        </Typography>
        <Typography gutterBottom>
          "Seagrass is an incredible flowering marine plant which can absorb
          carbon up to 35 times faster than a tropical rainforest. It's vital
          that we continue to restore these ecosystems that also provide
          important habitat that supports biodiversity, including important UK
          fisheries, so that we can create a future where people and nature can
          thrive together. Thank you to DPD for playing a part in making this
          happen."
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications & Sustainability,
          DPDgroup UK commented: "This is a fabulous project and something we
          are very proud to be involved in. At DPD, our aim is to be the most
          sustainable parcel delivery company in the UK and that means running
          an ambitious programme to transform our own operation as well as
          working with those organisations able to directly protect and enhance
          the ecosystems we are all reliant on."
        </Typography>
      </>
    ),
  },
  {
    id: 9,
    date: 'SEPTEMBER 2022',
    cardTitle: 'DPDgroup Net-Zero Announcement',
    title: 'DPD UK welcomes GeoPost/DPDgroup net-zero announcement',
    subtitle:
      'Parent GeoPost/DPDgroup submits group-wide net-zero target to Science Based Target initiative.',
    image: '',
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery firm{' '}
          <ExternalLink
            to="https://green.dpd.co.uk/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            DPD UK
          </ExternalLink>{' '}
          has welcomed net-zero targets set by its parent GeoPost/DPDgroup and
          confirmed that the UK operation is on track to achieve these targets
          and, where possible, exceed them
        </Typography>
        <Typography gutterBottom>
          GeoPost/DPDgroup has submitted both a near-term decarbonisation and a
          long-term net-zero target for approval by the Science Based Target
          initiative (SBTi). The group is taking decisive action to achieve
          net-zero emissions by 2040 and has committed to reducing its scope 1,
          2 and 3 greenhouse gas (GHG) emissions by 43% by 2030 and 90% by 2040
          from a 2020 baseline - 10 years ahead of the Paris Agreement.
        </Typography>
        <Typography gutterBottom>
          SBTi provides companies with a clearly defined path to reduce
          emissions in line with the goals of the Paris Agreement.
          GeoPost/DPDgroup expects to achieve SBTi approval in early 2023.
        </Typography>
        <Typography gutterBottom>
          In the UK ,DPD is focusing on the rapid decarbonisation of its
          delivery fleet, with 3,000 electric vehicles on the road in 2022 and
          plans for 5,000 by the end of 2023. By the end of 2023, DPD will be
          delivering to 30 major towns and cities in the UK on electric vehicles
          only.
        </Typography>
        <Typography gutterBottom>
          DPD will also be announcing major decarbonisation initiatives in 2022
          for it's lorry fleet and company cars.
        </Typography>
        <Typography gutterBottom>
          In addition, DPD UK is powered by 100% renewable energy - only buying
          electricity from renewable sources - and DPD's largest sortation
          facilities now run fully on solar power during the day.
        </Typography>
        <Typography gutterBottom>
          Elaine Kerr, DPD UK CEO, comments: "We welcome this announcement from
          DPDgroup and are very proud to be part of a Europe-wide verified
          commitment to net zero. This is a key step on that road and
          demonstrates GeoPost/DPDgroup's ambition to lead on net-zero emissions
          in the global parcel delivery market. Our aim is to be the most
          sustainable parcel delivery company in the UK and we are delighted to
          confirm the pathway that we are taking to ensure we achieve that,
          while also fully supporting the group-wide net-zero initiative. We are
          confident that we are well on the way to a net-zero future in the UK."
        </Typography>
      </>
    ),
  },
  {
    id: 8,
    date: 'JULY 2022',
    cardTitle: 'Great British EV Rally',
    title:
      'DPD hails electric van fleet after completing the Great British EV Rally',
    subtitle:
      "Ford E-Transit and Maxus eDeliver 3 highlight capability of EVs in 1,400 mile John o'Groats to Land's End challenge.",
    image: article8,
    embedImage: article8Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery firm DPD has hailed its electric van fleet
          after two of its vehicles completed the 1,400 mile Great British EV
          Rally.
        </Typography>
        <Typography gutterBottom>
          The Great British EV Rally was staged over 5 days and saw more than 50
          electric vehicles traverse the country to demonstrate the capability
          of the vehicles themselves and show what is possible with fleet
          electrification.
        </Typography>
        <Typography gutterBottom>
          The{' '}
          <ExternalLink
            to="https://green.dpd.co.uk/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            DPD
          </ExternalLink>{' '}
          team of four drivers included the firm's Head of Sustainability Olly
          Craughan and Sustainability Manager Georgina Burgess, alongside senior
          managers Chris Betts and Rob Cheeseman from the wider operation.
        </Typography>
        <Typography gutterBottom>
          Together they marshalled the DPD branded Ford E-Transit and Maxus
          eDeliver 3 down through Scotland, through the North East to Leeds, and
          then on to Rockingham Race Circuit, before heading across to the
          GridServe charging facility in Braintree, Essex. The teams then headed
          west, calling at Bristol and Cardiff, before the final leg down to
          Land's End, via the Eden Project.
        </Typography>
        <Typography gutterBottom>
          Along the way, the Rally was designed to showcase motorway and
          roadside charging, including charging hubs, destination chargers and
          electric forecourts. The Rally also helps to highlight key issues such
          as how driver style affects the performance of an EV, with data on
          driver performance shared with the teams each evening.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability, DPDgroup UK commented, "The
          whole purpose of the Rally is to illustrate that operating a 100%
          electric fleet is no longer just an aspirational strategy. That's
          something that is hugely relevant to us as we look to double our
          electric fleet to 5,000 EVs. I was really impressed with the charging
          options we were able to access right across the country. I think we
          have certainly helped smash some of the myths around EVs and I've come
          away believing that we genuinely are on the cusp of a charging
          revolution in the UK.
        </Typography>
        <Typography gutterBottom>
          "We already knew how amazing these vehicles were in the context of
          DPD's day to day work, but this was an opportunity to really test them
          in different ways and they have been absolutely fantastic. As one of
          the most visible green brands on the road, we are aware that we have a
          role to play in terms of helping to raise awareness among the wider
          public about e-mobility and how we can transition to a cleaner,
          greener future. But I think this has been good for us too and will
          help us showcase to our own drivers just what is possible."
        </Typography>
        <Typography gutterBottom>
          DPD opened the UK's first all-electric parcel delivery depot in
          Westminster in 2018 and aims to be the UK's most sustainable delivery
          company. The firm will have over 3,000 electric vehicles on the road
          in the UK by the end of 2022 and 5,000 by 2023, when it will be
          delivering to 30 towns and cities on all-electric vehicles.
        </Typography>
      </>
    ),
  },
  {
    id: 7,
    date: 'JULY 2022',
    cardTitle: 'Autonomous Delivery Robots',
    title: 'DPD to launch autonomous delivery robots in Milton Keynes',
    subtitle:
      'Autonomous robots will each deliver up to 30 parcels a day, in two neighbourhoods.',
    image: article7,
    embedImage: article7Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery firm DPD is to commence autonomous robot
          deliveries in two Milton Keynes neighbourhoods, as part of a trial
          with AI-powered robotics and last mile delivery company Cartken.
        </Typography>
        <Typography gutterBottom>
          The{' '}
          <ExternalLink
            to="https://green.dpd.co.uk/"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            DPD
          </ExternalLink>{' '}
          branded robots, which will operate out of the firm's Knowlhill depot,
          will navigate the city's traffic-free Redway network to access the
          residential neighbourhoods of Shenley Church End and Shenley Lodge.
        </Typography>
        <Typography gutterBottom>
          Powered by AI technology, the robots are currently 'learning' the
          routes and will then be able to find their way to delivery addresses
          fully autonomously. Cartken's level 4 autonomy and leading navigation
          tech is regarded as one of the safest last mile delivery solutions on
          the market and is currently being used by automotive tech giant
          Mitsubishi Electric in Japan, and leading food delivery company
          Grubhub in the United States.
        </Typography>
        <Typography gutterBottom>
          <ExternalLink
            to="https://vimeo.com/dpdgroup/review/724861871/af6ab59b2b"
            styles={{ '&:hover': { color: theme.palette.primary.main } }}
          >
            Click here to see a video showing a DPD autonomous delivery robot in
            action in Milton Keynes
          </ExternalLink>
        </Typography>
        <Typography gutterBottom>
          DPD parcel recipients will be notified of a robot delivery in advance,
          and once they confirm that they are at home to accept the parcel, the
          robot will be dispatched.
        </Typography>
        <Typography gutterBottom>
          Customers will be able to track the robot's progress on a map and will
          be notified when it reaches their property. They will then use a code
          to open the secure compartment and access their parcel. Once the
          compartment is closed, the robot will return to the depot for its next
          delivery.
        </Typography>
        <Typography gutterBottom>
          If the trial is successful, DPD intends to extend the sustainable
          autonomous final mile solution across the city, which could enable it
          to start removing traditional delivery vans from the road network.
        </Typography>
        <Typography gutterBottom>
          Elaine Kerr, DPD UK CEO, comments: "This is a really important trial
          for us. We want to understand the role that delivery robots could play
          in certain locations in the UK. They are a sustainable solution, and
          we genuinely want to find out if they could help us take vans off the
          road in future. The technology is proven and, with the DPD branding,
          the robots look fantastic. The public's response to our EV fleet has
          been amazing, so I am confident these smart robots will be embraced by
          customers as part of the way forward.
        </Typography>
        <Typography gutterBottom>
          "Realistically, we aren't going to be completely replacing our
          delivery drivers any time soon. And we wouldn't want to. The service
          they provide is fantastic. But at DPD we've always led the way on
          innovation and investment in our network and this is the next logical
          step in terms of evolving delivery solutions and working towards our
          aim of being the UK's most sustainable delivery company."
        </Typography>
        <Typography gutterBottom>
          Cartken Co-Founder and COO Anjali Jindal Naik said of the project, "We
          are excited to collaborate with DPD for parcel delivery in the UK
          market. Our robots are designed to provide sustainable solutions that
          integrate into existing city infrastructure. We are thrilled to be a
          part of DPD's sustainability plan as they adopt more environmentally
          conscious solutions through the use of our delivery robots."
        </Typography>
        <Typography gutterBottom>
          The trial is part of series of innovative green initiatives from DPD.
          The firm has already announced plans to have over 3,000 electric
          vehicles on the road in the UK by the end of 2022 and 5,000 by 2023,
          when it will be delivering to 30 towns and cities on all-electric
          vehicles.
        </Typography>
      </>
    ),
  },
  {
    id: 6,
    date: 'JUNE 2022',
    cardTitle: 'DPD UK CEO wins coveted CEO Today award',
    title: 'DPD UK CEO wins coveted CEO Today award',
    subtitle:
      'A year in to her role as DPD UK Chief Executive Officer, Elaine Kerr has been awarded “UK Leading Logistics Firm Executive of the Year 2022” by CEO Today Magazine.',
    image: article6,
    mobileImage: '',
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Each year, CEO Today, recognises business leaders across every
          industry sector in the UK. Awards are given to the most successful,
          forward-thinking and innovative CEOs in business today.
        </Typography>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          From Customer Relationship Manager to DPD UK CEO
        </Typography>
        <Typography gutterBottom>
          A tremendous career with DPD began 29 years ago, when Elaine joined
          the company as a sales executive.
        </Typography>
        <Typography gutterBottom>
          And from a sales executive based in the North West, Elaine moved up to
          Head of CRM.
        </Typography>
        <Typography gutterBottom>
          By 2008, Elaine had reached the DPD UK senior management team before
          becoming Executive director Sales, CRM and Customer Services in 2020.
        </Typography>
        <Typography gutterBottom>
          All the way through this success story, Elaine has been key to the
          continued growth of DPD UK.
        </Typography>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          Customer experience key to success
        </Typography>
        <Typography gutterBottom>
          Elaine cites her customer experience as one of the most important
          lessons learned throughout her DPD success story. As customer
          relationship manager and head of CRM, she was able to build up a
          rounded overview of the whole business. This, she says, helped her to
          understand the opportunities and challenges.
        </Typography>
        <Typography gutterBottom>
          Following her award from CEO Today, which recognised her tremendous
          career with DPD, Elaine said in an interview with the magazine that
          becoming CEO has given her the opportunity to talk with a far wider
          range of people. She said: “More than anything, I'm keen for a wider
          variety of voices to be heard.”
        </Typography>
        <Typography gutterBottom>
          Elaine also pays tribute to her UK senior management team and their
          collective customer service experience, saying that since becoming
          CEO: “I've certainly appreciated how important the DPD UK senior
          management team is.
        </Typography>
        <Typography gutterBottom>
          “We have more than 20,000 people, a huge fleet on the road every day,
          significant nationwide real estate and we're a seven days per week
          operation. My job now is to ensure continued growth by working with
          this team.”
        </Typography>
        <Typography gutterBottom variant="h5Light" paddingTop={2}>
          Teamwork and challenges for the future
        </Typography>
        <Typography gutterBottom>
          Becoming CEO at such a critical time - half way through the pandemic,
          dealing with the changes wrought by Brexit - saw Elaine dealing with
          unprecedented challenges.
        </Typography>
        <Typography gutterBottom>
          She says: “We went through the equivalent of three years growth at the
          start of the pandemic, almost overnight… Similarly, Brexit is still an
          issue as most of our international traffic is to and from the EU. I
          think everyone found last year tough going, but we are seeing things
          improve. Now, we need to focus on rebuilding that traffic.”
        </Typography>
        <Typography gutterBottom>
          DPD UK continues to focus on innovative change around sustainability.
          For the future, Elaine plans to build on DPD's customer services and
          becoming the UK's most sustainable delivery company.
        </Typography>
        <Typography gutterBottom>
          When asked what the award means Elaine comments “I think it represents
          recognition for the whole team.
        </Typography>
        <Typography gutterBottom>
          “Part of my role is to make sure that this kind of recognition
          continues to be earned, and that our customers love what we do.”
        </Typography>
      </>
    ),
  },
  {
    id: 5,
    date: 'JUNE 2022',
    cardTitle: 'DPD Funding at Wareham Forest',
    title:
      'DPD funding of immense value - Forestry England on Wareham Forest two years on from devastating fire',
    subtitle:
      "Two years on from the devasting fire at Wareham Forest, money raised from parcel company DPD's recycling efforts is helping to regenerate vital habitats at the Site of Special Scientific Interest.",
    image: article5,
    embedImage: article5Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Since 2020, DPD has worked with Forestry England to help restore
          Dorset's Wareham Forest with support from its innovative Eco Fund.
          Funded solely by the firm's circular economy initiatives, such as
          recycling shrink wrap and wooden pallets, the Eco Fund awards grants
          to community groups, educational facilities and start-up companies to
          fund green, ecological or sustainable projects benefiting the
          environment.
        </Typography>
        <Typography gutterBottom>
          With the support from DPD, Forestry England has been able to replant
          with many young trees across the forest, considering carefully where
          these are located. The money also went towards the purchase of a
          specialist heavy-duty mower and collector that has been used to create
          firebreaks and gather up the vegetation to minimise the risk of future
          fires in the forest.
        </Typography>
        <Typography gutterBottom>
          Fire can literally leap from one area to another, jumping across roads
          and paths or using vegetation to creep further along. To help make the
          forest more resilient the team at Wareham have used the new equipment
          to create natural gaps between areas of forest by clearing areas of
          vegetation and planting new trees further back from the edge of the
          road.
        </Typography>
        <Typography gutterBottom>
          Linked to this work, they have also repaired and restored paths and
          tracks in the forest to ensure, if needed again, fire crews can get
          access into affected areas and fight the fire at its source.
        </Typography>
        <Typography gutterBottom>
          Some of the areas most badly affected by the fire have not been
          replanted and are being managed to help them return to heathland. But
          even here, Forestry England is reporting green shoots of new life.
        </Typography>
        <Typography gutterBottom>
          Mark Warn, Forestry England's Wareham Wildlife Ranger, said "The new
          machinery funded by DPD's Eco Fund has been of immense value, mowing
          17.5 kms of ride edges last winter, which improves biodiversity and
          wildfire management. This is a brilliant habitat for pollinating
          insects and species like the Silver Studded Blue butterfly.
        </Typography>
        <Typography gutterBottom>
          "Restoring Wareham Forest is a big job and will take many years to
          achieve. We are very grateful for DPD's support, which allows us to
          continue to work with supporters and volunteers and share with them
          the huge benefits of the restoration of this special place."
        </Typography>
        <Typography gutterBottom>
          Tim Jones, Director of Marketing, Communications & Sustainability,
          DPDgroup UK commented: "We are absolutely delighted to hear the good
          news from Wareham Forest. We have been on site and know the scale of
          the challenges they are facing, so to hear that our funding is making
          a real difference is fantastic. It is such an important environment,
          and while it may take decades to fully recover, our funds are also
          protecting the area against further harm.
        </Typography>
        <Typography gutterBottom>
          "Having our own circular economy means we can generate eco funds to
          directly back initiatives like this. It gives us a real focus to keep
          on challenging ourselves to be more sustainable at every level. The
          more we can raise from recycling, the more we can put back into
          ecological or sustainable projects that benefit everyone."
        </Typography>
        <Typography gutterBottom>
          Wareham Forest is a stronghold for wildlife and one of the few places
          where it is still possible to find all six of the UK's native
          reptiles, including the endangered smooth snake and sand lizard, and
          it is home to many rare species of birds and insects.
        </Typography>
      </>
    ),
  },
  {
    id: 4,
    date: 'MAY 2022',
    cardTitle: 'All-electric Delivery',
    title:
      "DPD confirms all-electric delivery status for 10 towns and cities and announces plans for five additional 'green' towns",
    subtitle:
      'All-electric target updated to 30 locations, two years earlier than planned.',
    image: article4,
    embedImage: article4Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery firm DPD has confirmed that it is now
          providing an all-electric delivery service to 10 UK towns and cities
          and is aiming for 30 all-electric locations by the end of 2023 -
          that's five more than originally planned and two years earlier than
          expected.
        </Typography>
        <Typography gutterBottom>
          In October 2020, DPD announced plans to create 25 all-electric towns
          and cities in the UK by 2025. In July 2021, Oxford became DPD's first
          'green' city, with a fleet of 40 electric vehicles based at the new
          DPD Bicester eco-depot, delivering over 15,000 parcels a week across
          the city.
        </Typography>
        <Typography gutterBottom>
          DPD has confirmed that nine additional UK locations have achieved the
          same all-electric delivery status. The 10 'green' towns and cities are
          Bradford, Bristol, Cardiff, Hull, Newcastle, Nottingham, Oxford,
          Reading, Southampton and Stoke.
        </Typography>
        <Typography gutterBottom>
          Progress is well underway at the remaining 15 original green delivery
          locations - Birmingham, Brighton and Hove, Cambridge, Coventry, Derby,
          Edinburgh, Glasgow, Leeds, Leicester, Liverpool, London, Manchester,
          Plymouth, Portsmouth and Sheffield.
        </Typography>
        <Typography gutterBottom>
          DPD now expects these locations to achieve all-electric status two
          years earlier than planned, by the end of 2023. In addition, the firm
          is now planning for five more towns, Birkenhead, Gateshead,
          Newcastle-under-Lyme, Rotherham and Shipley, to become all-electric by
          the end of 2023.
        </Typography>
        <Typography gutterBottom>
          To date, DPD has invested over £90m on all-electric vehicles in the
          UK, including the most recent order of 1,000 Ford E-Transit vans in
          May 2022, which will take DPD's electric vehicle fleet to over 2,500
          in total.
        </Typography>
        <Typography gutterBottom>
          The company, which hasn't bought a diesel van since summer 2020,
          expects to deliver 26 million parcels this year on electric vehicles,
          a saving of 7,200,000kg of CO2.
        </Typography>
        <Typography gutterBottom>
          Elaine Kerr, DPD UK CEO, commented, "I'm absolutely delighted to
          confirm that we now have 10 UK towns and cities being delivered by an
          all-electric DPD fleet. One of our big aims is to be the UK's most
          sustainable delivery company and, by the end of 2023, we will have
          over 5,000 EVs on the road and 30 urban centres delivered on
          all-electric vehicles.
        </Typography>
        <Typography gutterBottom>
          "Since opening the UK's first all-electric parcel depot in Westminster
          in 2018, we have grown our electric fleet year on year, despite some
          of the issues that have held us back, such as the lack of righthand
          drive EVs for the UK market. We are now seeing improved availability
          of larger electric vans, which is what we need. As a result, it is
          fantastic to be able to extend our original pledge to 30 locations and
          two years earlier than planned.
        </Typography>
        <Typography gutterBottom>
          "By targeting major town and city centres with our electric vehicles,
          we can make a significant difference to emissions in those locations
          and help improve air quality where it is most needed. In addition to
          buying the vehicles and investing in charging infrastructure, we
          continue to optimise our routing and operation, to create the smartest
          possible delivery system and reduce our overall miles per parcel."
        </Typography>
      </>
    ),
  },
  {
    id: 3,
    date: 'APRIL 2022',
    cardTitle: 'DPD teams up with Rock Fall',
    title: 'DPD teams up with Rock Fall for new sustainable footwear range',
    subtitle:
      '52,000 plastic recycled plastic bottles go into safety boots for drivers and warehouse staff.',
    image: article3,
    embedImage: article3Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          Leading parcel delivery firm DPD has partnered with innovative
          footwear brand Rock Fall to create a new range of sustainable safety
          boots for drivers and other workers that require protective footwear.
        </Typography>
        <Typography gutterBottom>
          Rock Fall is the first safety footwear provider to be verified by the
          Vegan Society. The two new styles - the Rock Fall 'Fly' and 'Bantam' -
          are S3 rated for safety but also contain up to 12 recycled plastic
          bottles in each pair. Both styles are equipped with fibreglass toecaps
          and a Kevlar midsole.
        </Typography>
        <Typography gutterBottom>
          The upper fabric, pull on loops, laces and moisture wicking lining are
          all 100% recycled plastic, while the bio EVA midsole is made from
          sugar cane oil rather than petroleum and the footbed uses recycled
          foam offcuts.
        </Typography>
        <Typography gutterBottom>
          In addition, both styles are delivered in shoe boxes made from FSC
          recycled card that is both 100% recycled and recyclable and printed
          using soybean ink. Pointless packaging such as card separators and
          swing tickets is minimised, and a QR code on the labels removes the
          need for unnecessary leaflets in the box.
        </Typography>
        <Typography gutterBottom>
          DPD has initially bought 5,000 pairs of the Rock Fall boots which will
          be added to the DPD footwear range this year and the firm hopes to
          grow that number year on year.
        </Typography>
        <Typography gutterBottom>
          Olly Craughan, Head of Sustainability, DPDgroup UK, commented "We are
          absolutely delighted to be able to add these two new sustainable
          options to our footwear range. We've challenged ourselves to be the
          UK's most sustainable parcel carrier, and that means going way beyond
          just buying electric vehicles. We are committed to looking at every
          aspect of what we do and asking, 'can we do that in a more sustainable
          way?' By working with like minds and fellow innovators, we are finding
          more and more ways to improve what we do. The boots are fantastic, and
          they are another great way of ensuring our drivers leave a footprint
          they can be proud of."
        </Typography>
        <Typography gutterBottom>
          Richard Noon, Director of Rock Fall UK, commented "We are at the very
          start of our journey into truly revolutionising the safety footwear
          industry, using the most sustainable materials and components,
          ensuring we are not only prioritising wearer comfort and longevity of
          our footwear, but also our planet. We are delighted that DPD will be
          adding this option to its PPE range."
        </Typography>
        <Typography gutterBottom>
          The new boots are part of DPD's pledge to be the greenest delivery
          company on the planet. In addition to running over 1,500 electric
          vehicles on UK roads every day, DPD has also successfully replaced its
          parcel bags with ones made from 80% reclaimed material and 100%
          recyclable, and recycles all plastic shrink wrap used throughout the
          operation to create new shrink wrap.
        </Typography>
        <Typography gutterBottom>
          The new initiative follows two recent high profile sustainable award
          wins for DPD, recognising green initiatives across the operation. DPD
          was named 'Sustainable Business of the Year' at the Business Champion
          Awards and won 'Waste and Resource Management Project of the Year' at
          the highly prestigious edie Sustainability Leaders Awards.
        </Typography>
      </>
    ),
  },
  {
    id: 2,
    date: 'APRIL 2022',
    cardTitle: 'DPD wins second green award',
    title: 'DPD wins second green award in two weeks',
    subtitle:
      "Firm honoured at the 'Sustainability Oscars' for waste reduction and recycling.",
    image: article2,
    embedImage: article2Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          DPD has scooped its second green award in a fortnight, winning the
          Waste and Resource Management Project of the Year at the highly
          prestigious edie Sustainability Leaders Awards, 2022.
        </Typography>
        <Typography gutterBottom>
          The award follows hot on the heels of DPD being named Sustainable
          Business of the Year at the Business Champion Awards in London.
        </Typography>
        <Typography gutterBottom>
          Now in their 15th year, the edie Sustainability Leaders Awards
          recognise excellence across the spectrum of green business and are
          widely seen as the 'Oscars' of the UK's sustainability sector.
        </Typography>
        <Typography gutterBottom>
          DPD collected the Waste and Resource Management Project of the Year
          Award at a gala ceremony in Westminster - hosted by TV presenter Chris
          Packham CBE. The judges praised DPD's thorough and innovative
          approach, which includes the creation of the DPD Eco Fund and
          implementing systems to recycle 70% of all waste.
        </Typography>
        <Typography gutterBottom>
          The DPD Eco Fund is funded solely by the firm's own circular economy
          initiatives. Plastic shrink wrap used throughout the operation is
          broken down and then recycled to create new shrink wrap. The money
          generated from this, along with the recycling of wooden pallets, goes
          straight into the Eco Fund to fund sustainable community projects
          benefiting the environment.
        </Typography>
        <Typography gutterBottom>
          The award is the second time that DPD has been honoured at the
          Sustainability Leaders Awards. In 2020, DPD won the Transport/Fleet
          Management Initiative of the Year for its innovative all-electric
          micro depots in central London.
        </Typography>
        <Typography gutterBottom>
          Elaine Kerr, CEO at DPD UK, commented: "Our aim is to be the UK's most
          sustainable parcel carrier and this award is further proof that our
          commitment is bearing fruit. This award in particular highlights for
          customers and consumers alike that there is much more to our green
          vision than simply having the industry's biggest fleet of EVs. Winning
          this award is independent recognition of the huge efforts made by DPD
          people right across the network to focus on waste reduction and
          recycling."
        </Typography>
      </>
    ),
  },
  {
    id: 1,
    date: 'APRIL 2022',
    cardTitle: 'DPD named sustainable Business of the Year',
    title:
      'DPD named Sustainable Business of the Year at Business Champion Awards',
    subtitle:
      'Leading parcel delivery firm DPD has been named Sustainable Business of the Year at the Business Champion Awards.',
    image: article1,
    embedImage: article1Embed,
    bannerImage: '',
    content: (
      <>
        <Typography gutterBottom>
          The firm was praised for its ability to adapt during the pandemic
          whilst continuing its sustainability mission.
        </Typography>
        <Typography gutterBottom>
          DPD has been a sector leader on fleet electrification, with over 1,500
          electric vehicles on the road in the UK every day, and incorporated a
          whole raft of changes into its operation to make it as sustainable as
          possible. Initiatives range from the pledge to deliver clean and green
          to 30 of the largest towns and cities in the UK by 2023 to changing
          DPD parcel bags to 80% reclaimed material and 100% recyclable. DPD has
          also created an Eco Fund to support community-based sustainable
          projects, funded solely by the firm's initiatives, which include
          recycling all plastic shrink wrap used throughout the operation to
          create new shrink wrap and the recycling of wooden pallets.
        </Typography>
        <Typography gutterBottom>
          DPD received the award at a special ceremony at Canary Wharf, London,
          hosted by BBC TV news presenter Huw Edwards.
        </Typography>
        <Typography gutterBottom>
          The awards evening was a carbon neutral event, while the awards
          process carbon footprint was kept to a minimum with the judges'
          presentation days held online via Zoom. To honour Her Majesty's
          Jubilee campaign to plant more trees in 2022, each winner will have
          ten trees planted in its geographical region within the UK.
        </Typography>
        <Typography gutterBottom>
          Elaine Kerr, CEO at DPD UK, comments: "This award is fantastic
          recognition for our people and our sustainability journey so far. We
          have challenged ourselves to be greener and more sustainable at every
          level and to create a truly joined-up strategy that goes way beyond
          simply buying electric vehicles. It includes an initiative to monitor
          air quality in five major cities and a four-year partnership with
          Forestry England. I'm incredibly proud of the progress we've made and
          the transformative impact our green initiatives are making, not only
          on our operation but on the environment."
        </Typography>
      </>
    ),
  },
];
