import { Typography, Container, Grid, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { ROAD_MAP } from '../../data/analytics';

import roadmapHeader from '../../images/RoadMap/roadmapHeader.png';

import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';
import theme from '../../theme.js';
import PageHeader from '../../components/pageHeader/PageHeader.jsx';
import RoadMapCard from '../../components/roadMapCard/RoadMapCard.jsx';

import { ROADMAP } from '../../data/roadmap.js';

const RoadMap = () => {
  return (
    <Trackable interfaceId={ROAD_MAP.INTERFACE_ID} loadId={ROAD_MAP.ON_LOAD}>
      <PageHeader image={roadmapHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Road Map
        </Typography>
        <Typography variant="h1" color="#FFF">
          Our Sustainable Journey
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          px: usePadding(),
          pt: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 20,
          pb: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 10,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.green}
            sx={{ pb: 4 }}
          >
            Our Road Map to a Sustainable Future:
          </Typography>
          <Grid
            container
            alignItems="flex-end"
            spacing={2}
            sx={{ width: '100% !important', maxWidth: usePageWidth() }}
          >
            {ROADMAP.map(card => (
              <RoadMapCard
                key={card.id}
                sizeLg={card.sizeLg}
                date={card.date}
                background={card.background}
                text={card.text}
                textVariant={card.textVariant}
                align={card.align}
                image={card.image}
              />
            ))}
          </Grid>
        </Box>
      </Container>
    </Trackable>
  );
};

export default RoadMap;
