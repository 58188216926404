import { Container, Grid, Typography, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { STRATEGY } from '../../../data/analytics';
import { STRATEGY_VIDEO } from '../../../data/links';

import strategyBackground1 from '../../../images/OurVision/strategyBackground1.png';
import strategyBackground2 from '../../../images/OurVision/strategyBackground2.png';
import strategyHeader from '../../../images/OurVision/strategyHeader.png';
import strategyHeaderSmall from '../../../images/OurVision/strategyHeaderSmall.png';
import elements1 from '../../../images/OurVision/elements1.png';
import elements2 from '../../../images/OurVision/elements2.png';
import elements3 from '../../../images/OurVision/elements3.png';
import elements4 from '../../../images/OurVision/elements4.png';
import strategyDelivery from '../../../images/OurVision/strategyDelivery.png';
import strategyDeliverySmall from '../../../images/OurVision/strategyDeliverySmall.png';

import theme from '../../../theme';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import VideoContainer from '../../../components/videoContainer/VideoContainer.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const Strategy = () => {
  const elements = [elements1, elements2, elements3, elements4];

  return (
    <Trackable interfaceId={STRATEGY.INTERFACE_ID} loadId={STRATEGY.ON_LOAD}>
      <Container
        disableGutters
        sx={{
          backgroundImage: `url(${strategyBackground1})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          minWidth: '100%',
          display: 'flex',
          justifyContent: 'center',
          py: !useMediaQuery(theme.breakpoints.down('md')) && 10,
          pt: useMediaQuery(theme.breakpoints.down('md')) && 3,
          pb: useMediaQuery(theme.breakpoints.down('md')) && 12,
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ py: 2, px: usePadding(), width: usePageWidth() }}
          direction={
            useMediaQuery(theme.breakpoints.down('md'))
              ? 'column-reverse'
              : 'row'
          }
        >
          <Grid item md={6}>
            <Typography variant="h5" color="#FFF">
              Our Vision
            </Typography>
            <Typography variant="h1" color="#FFF">
              Our 1-2-3-4 Strategy
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box
              component="img"
              src={
                useMediaQuery(theme.breakpoints.down('md'))
                  ? strategyHeaderSmall
                  : strategyHeader
              }
              sx={{ maxWidth: '100%' }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 5 : 20,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            sx={{ color: theme.palette.primary.main, pb: 2 }}
          >
            We have introduced a fourth element to our successful 1-2-3
            strategy:
          </Typography>
          <Typography
            variant="h3Light"
            sx={{ color: theme.palette.primary.main, pb: 2 }}
          >
            Aiming to be the UK&apos;s leader in sustainable delivery
          </Typography>
          <Grid container justifyContent="center" spacing="17.5px">
            {elements.map(element => (
              <Grid
                item
                key={element}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box component="img" src={element} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <BoxOnBackground
        background={strategyBackground2}
        boxSide="left"
        verticalPadding={20}
      >
        <Typography variant="h2Light" gutterBottom color="#FFF">
          We have always focused on providing the best service in our industry,
          utilising the best technology.
        </Typography>
        <Typography variant="body1" color="#FFF">
          Our inclusive and diverse culture allows us to recruit, retain and
          develop the most customer centric people. Now with our 4th pillar, we
          have set out how we will become the most sustainable delivery company.
        </Typography>
      </BoxOnBackground>
      <SideBySide
        image={
          useMediaQuery(theme.breakpoints.down('md'))
            ? strategyDeliverySmall
            : strategyDelivery
        }
        imageSide="left"
        background={theme.palette.secondary.backgroundWebApp}
        verticalPadding={25}
      >
        <Typography
          variant="h2Light"
          color="#000"
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          These four elements of our strategy are shared with all our people and
          shape how we all go about our day to day business.
        </Typography>
      </SideBySide>
      <VideoContainer
        text="Our 1-2-3-4 strategy"
        textColor={theme.palette.primary.main}
        background={theme.palette.primary.background}
        videoLink={STRATEGY_VIDEO}
      />
    </Trackable>
  );
};

export default Strategy;
