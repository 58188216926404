import { useState, useEffect } from 'react';
import { Container, Grid, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import { useTracker } from '@dpdgroupuk/react-event-tracker';

import btnLeft from '../../images/NewsArticles/btnLeft.png';
import btnRight from '../../images/NewsArticles/btnRight.png';
import ArticleCard from '../articleCard/ArticleCard.jsx';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';
import theme from '../../theme';

const NewsCarousel = ({
  items,
  itemsPerPage,
  background,
  nextScrollId,
  backScrollId,
  readMoreId,
  autoTransition = false,
  imgWidth,
  imgHeight,
}) => {
  const tracker = useTracker();

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
    setItemOffset(0);
  }, [items]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [items, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (autoTransition) {
      const interval = setInterval(() => {
        if (currentPage === pageCount - 1) {
          setCurrentPage(0);
          handlePageClick({ selected: 0 });
        } else {
          setCurrentPage(currentPage + 1);
          handlePageClick({ selected: currentPage + 1 });
        }
      }, 4000);

      return () => clearInterval(interval);
    }
  });

  const handlePageClick = selectedItem => {
    const newOffset = (selectedItem.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
    setCurrentPage(selectedItem.selected);
  };

  return (
    <Container
      disableGutters
      sx={{
        minWidth: '100%',
        px: usePadding(),
        py: 6,
        backgroundColor: background,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ width: usePageWidth() }}
        spacing={1}
      >
        {!useMediaQuery(theme.breakpoints.down('md')) && (
          <Grid item md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            {currentPage > 0 && (
              <button
                style={{ background: 'transparent' }}
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  handlePageClick({ selected: currentPage - 1 });
                  tracker.logEvent(backScrollId);
                }}
              >
                <Box component="img" src={btnLeft} sx={{ cursor: 'pointer' }} />
              </button>
            )}
          </Grid>
        )}
        <Grid item md={10} xs={12}>
          <Grid container spacing={2}>
            {currentItems?.map(article => (
              <Grid
                item
                md={4}
                xs={12}
                key={article.id}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <ArticleCard
                  article={article}
                  readMoreId={readMoreId}
                  imgWidth={imgWidth}
                  imgHeight={imgHeight}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        {!useMediaQuery(theme.breakpoints.down('md')) && (
          <Grid item md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            {currentPage < pageCount - 1 && (
              <button
                style={{ background: 'transparent' }}
                href="#/"
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  handlePageClick({ selected: currentPage + 1 });
                  tracker.logEvent(nextScrollId);
                }}
              >
                <Box
                  component="img"
                  src={btnRight}
                  sx={{ cursor: 'pointer' }}
                />
              </button>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default NewsCarousel;

NewsCarousel.propTypes = {
  background: PropTypes.string,
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  backScrollId: PropTypes.string,
  nextScrollId: PropTypes.string,
  readMoreId: PropTypes.string,
  autoTransition: PropTypes.bool,
  imgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
