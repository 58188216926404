import { Container, Grid, Box, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';
import usePadding from '../../utils/usePadding';
import usePageWidth from '../../utils/usePageWidth';

const SideBySide = ({
  image,
  imageSide,
  children,
  background,
  imageBackground,
  verticalPadding,
  radiusBorder,
  mobileDirection,
  mobileVerticalPadding,
}) => {
  return (
    <Container
      disableGutters
      sx={[
        {
          minWidth: '100%',
          backgroundColor: background,
          py: useMediaQuery(theme.breakpoints.down('md'))
            ? mobileVerticalPadding || 5
            : verticalPadding || 10,
          display: 'flex',
          justifyContent: 'center',
        },
        imageBackground && {
          backgroundImage: `url(${imageBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      ]}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ width: usePageWidth(), px: usePadding() }}
        direction={
          (useMediaQuery(theme.breakpoints.down('md'))
            ? mobileDirection
            : 'row') || 'row'
        }
      >
        {imageSide === 'left' && (
          <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={image}
              sx={[
                radiusBorder && { borderRadius: radiusBorder },
                { maxWidth: '100%' },
              ]}
            />
          </Grid>
        )}
        <Grid item md={6}>
          {children}
        </Grid>
        {imageSide === 'right' && (
          <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={image}
              sx={[
                radiusBorder && { borderRadius: radiusBorder },
                { maxWidth: '100%' },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default SideBySide;

SideBySide.propTypes = {
  image: PropTypes.string,
  imageSide: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node,
  background: PropTypes.string,
  imageBackground: PropTypes.string,
  verticalPadding: PropTypes.number,
  radiusBorder: PropTypes.number,
  mobileDirection: PropTypes.string,
  mobileVerticalPadding: PropTypes.number,
};
