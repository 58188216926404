import { useMediaQuery } from '@mui/material';
import theme from '../theme';

const usePadding = () => {
  if (useMediaQuery(theme.breakpoints.down('md'))) {
    return 4;
  } else {
    return 3;
  }
};

export default usePadding;
