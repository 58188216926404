import { Close } from '@mui/icons-material';
import {
  Backdrop,
  Box,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Trackable, useTracker } from '@dpdgroupuk/react-event-tracker';
import { VAN_STATS_DIALOG } from '../../data/analytics';

import theme from '../../theme';
import usePadding from '../../utils/usePadding';
import VanStatsTable from '../vanStatsTable/VanStatsTable.jsx';

const StyledBackdrop = () => {
  return <Backdrop sx={{ opacity: 0 }} open={false} />;
};

const VanStatsDialog = ({ selectedVan, modalOpen, handleCloseModal }) => {
  const tracker = useTracker();

  return (
    <Trackable
      interfaceId={VAN_STATS_DIALOG.INTERFACE_ID}
      loadId={VAN_STATS_DIALOG.ON_LOAD}
    >
      <Dialog
        open={modalOpen}
        onClose={() => {
          handleCloseModal();
          tracker.logEvent(VAN_STATS_DIALOG.CLICK_CLOSE);
        }}
        components={{ Backdrop: StyledBackdrop }}
        sx={{ px: usePadding() }}
        PaperProps={{
          style: {
            borderColor: theme.palette.secondary.infoGreen,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            transition: 'none',
            backgroundColor: theme.palette.primary.background,
            boxShadow: '0px 36px 142px rgba(56, 90, 79, 0.15)',
            minWidth: useMediaQuery(theme.breakpoints.down('md'))
              ? '100%'
              : '784px',
          },
        }}
      >
        <Box>
          <IconButton
            onClick={() => handleCloseModal()}
            sx={{
              color: theme.palette.primary.green,
              float: 'right',
              mr: 2,
              mt: 2,
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 6,
            pt: 2,
            pb: 6,
            borderColor: '#000',
            borderWidth: 1,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box component="img" src={selectedVan.image} />
          </Box>
          <Typography
            variant="body1"
            color={theme.palette.primary.accentGreen}
            textAlign="center"
            gutterBottom
          >
            {selectedVan.category}
          </Typography>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.green}
            textAlign="center"
            gutterBottom
          >
            {selectedVan.make} {selectedVan.model}
          </Typography>
          {/* Video embed here - add video for each van in van data */}
          <VanStatsTable stats={selectedVan.stats} />
        </Box>
      </Dialog>
    </Trackable>
  );
};

export default VanStatsDialog;

VanStatsDialog.propTypes = {
  selectedVan: PropTypes.object,
  modalOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
