import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
} from '@mui/material';

import dpdLogo from '../../images/Navigation/dpdLogo.png';
import mobileOpen from '../../images/Navigation/mobileOpen.png';
import mobileClose from '../../images/Navigation/mobileClose.png';

import theme from '../../theme';
import * as routes from '../../router/routes';
import { NAVIGATION } from '../../router/routes';
import NavBarLink from './NavBarLink.jsx';

const MobileNavbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateAndClose = link => {
    navigate(link);
    setMenuOpen(false);
  };

  return (
    <>
      <Toolbar
        disableGutters
        sx={{
          zIndex: theme => theme.zIndex.drawer + 1,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderColor: theme.palette.primary.lightGrey,
          backgroundColor: '#FFF',
          pl: 3,
          pr: 4,
          py: 3,
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '100%',
        }}
      >
        <NavLink to={routes.HOME}>
          <Box component="img" src={dpdLogo} />
        </NavLink>
        <IconButton onClick={() => setMenuOpen(!menuOpen)}>
          <Box component="img" src={menuOpen ? mobileClose : mobileOpen} />
        </IconButton>
      </Toolbar>
      <Drawer
        anchor="top"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        variant="temporary"
        hideBackdrop
        PaperProps={{ style: { boxShadow: 'none' } }}
      >
        <Toolbar sx={{ height: '80px' }} />
        <List sx={{ py: 0, maxHeight: 'calc(100vh - 240px)' }}>
          {NAVIGATION.map((nav, index) => (
            <>
              <ListItem
                key={nav.link}
                sx={[
                  {
                    pl: 3,
                    py: 2.25,
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderColor: theme.palette.primary.warmGrey,
                    backgroundColor:
                      pathname === nav.link ||
                      `/${pathname.split('/')[1]}` === nav.link
                        ? theme.palette.primary.lightGrey
                        : '#FFF',
                  },
                  index === 0 && {
                    borderTopWidth: 1,
                    borderTopStyle: 'solid',
                    borderTopColor: theme.palette.primary.lightGrey,
                  },
                ]}
                onClick={() => navigateAndClose(nav.link)}
              >
                <NavBarLink nav={nav} variant="h6" />
              </ListItem>
              {(pathname === nav.link ||
                `/${pathname.split('/')[1]}` === nav.link) &&
                nav.subLinks?.map(nav => (
                  <ListItem
                    key={nav.link}
                    sx={{
                      pl: 3,
                      py: 2.25,
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      borderColor: theme.palette.primary.warmGrey,
                      backgroundColor: theme.palette.primary.lightGreySubLink,
                    }}
                    onClick={() => navigateAndClose(nav.link)}
                  >
                    <NavBarLink nav={nav} variant="body2" />
                  </ListItem>
                ))}
            </>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
