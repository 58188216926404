import { Container, Divider, Grid, Box } from '@mui/material';
import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { NAVIGATION } from '../../router/routes';
import NavBarLink from './NavBarLink.jsx';
import * as routes from '../../router/routes';

import dpdLogo from '../../images/Navigation/dpdLogo.png';

const Navbar = () => {
  const { pathname } = useLocation();

  const subLinks = useMemo(() => {
    const baseUrl = `/${pathname.split('/')[1]}`;
    const currentNavLink = NAVIGATION.filter(nav => nav.link === baseUrl)[0];

    if (currentNavLink && 'subLinks' in currentNavLink) {
      const { subLinks } = currentNavLink;
      return subLinks;
    }

    return [];
  }, [pathname]);

  return (
    <Container sx={{ padding: 0, minWidth: '100%' }} disableGutters>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ py: 1 }}
      >
        <Grid item>
          <NavLink to={routes.HOME}>
            <Box component="img" src={dpdLogo} sx={{ pr: 4 }} />
          </NavLink>
        </Grid>
        {NAVIGATION.map(nav => (
          <Grid item key={nav.link}>
            <NavBarLink nav={nav} variant="h6" />
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ py: 1, minHeight: 55 }}
      >
        {subLinks?.map(nav => (
          <Grid item key={nav.link}>
            <NavBarLink nav={nav} variant="body2" />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Navbar;
