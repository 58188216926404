import axios from 'axios';

export const postContactUs = fields => {
  axios.post(
    `${process.env.REACT_APP_LIVE_STATS_API}/green/contact-us`,
    {
      contactSubject: fields.contactSubject,
      email: fields.email,
      subject: fields.subject,
      message: fields.message,
      recaptchaToken: fields.recaptcha,
    },
    { headers: { 'Content-Type': 'application/json' } }
  );
};

export const getCities = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_LIVE_STATS_API}/green/cities`
  );
  return res.data;
};

export const getStats = async () => {
  const res = await axios.get(
    `${process.env.REACT_APP_LIVE_STATS_API}/green/stats`
  );
  return res.data;
};
