import { IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../../theme';
import Union from '../../images/ContactUs/Union.svg';

const ContactButton = ({ setContactOpen }) => {
  return (
    <IconButton
      size="large"
      onClick={() => setContactOpen(true)}
      sx={{
        bottom: 50,
        right: 50,
        position: 'fixed',
        color: '#FFF',
        backgroundColor: theme.palette.primary.green,
        height: '48px',
        '&:hover': {
          backgroundColor: theme.palette.primary.green,
          opacity: 0.8,
        },
      }}
    >
      <Box component="img" src={Union} />
    </IconButton>
  );
};

export default ContactButton;

ContactButton.propTypes = {
  setContactOpen: PropTypes.func,
};
