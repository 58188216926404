import { Close } from '@mui/icons-material';
import {
  Dialog,
  Box,
  IconButton,
  useMediaQuery,
  Backdrop,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Trackable, useTracker } from '@dpdgroupuk/react-event-tracker';
import { SPEAKER_DIALOG } from '../../data/analytics';

import theme from '../../theme';
import usePadding from '../../utils/usePadding';

const StyledBackdrop = () => {
  return <Backdrop sx={{ opacity: 0 }} />;
};

const SpeakerDialog = ({ selectedSpeaker, setSelectedSpeaker }) => {
  const tracker = useTracker();

  return (
    <Trackable
      interfaceId={SPEAKER_DIALOG.INTERFACE_ID}
      loadId={SPEAKER_DIALOG.ON_LOAD}
    >
      <Dialog
        open={selectedSpeaker !== ''}
        onClose={() => {
          setSelectedSpeaker('');
          tracker.logEvent(SPEAKER_DIALOG.CLICK_CLOSE);
        }}
        components={{ Backdrop: StyledBackdrop }}
        sx={{ px: usePadding() }}
        PaperProps={{
          style: {
            borderColor: theme.palette.secondary.infoGreen,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            transition: 'none',
            backgroundColor: theme.palette.primary.background,
            boxShadow: '0px 36px 142px rgba(56, 90, 79, 0.15)',
            minWidth: useMediaQuery(theme.breakpoints.down('md'))
              ? '100%'
              : '740px',
          },
        }}
      >
        <Box>
          <IconButton
            onClick={() => setSelectedSpeaker('')}
            sx={{
              color: theme.palette.primary.green,
              float: 'right',
              mr: 2,
              mt: 2,
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            px: 6,
            pt: 2,
            pb: 6,
          }}
        >
          <Typography
            variant="h3Light"
            color={theme.palette.primary.green}
            textAlign="center"
            paddingBottom={2}
          >
            {selectedSpeaker.name}
          </Typography>
          <Typography
            variant="body1"
            color="#000"
            textAlign="center"
            paddingBottom={3}
          >
            {selectedSpeaker.title}
          </Typography>
          <iframe
            title={selectedSpeaker.name}
            src={selectedSpeaker.link}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={
              useMediaQuery(theme.breakpoints.down('md')) ? '167px' : '363px'
            }
            width="100%"
          />
        </Box>
      </Dialog>
    </Trackable>
  );
};

export default SpeakerDialog;

SpeakerDialog.propTypes = {
  selectedSpeaker: PropTypes.object,
  setSelectedSpeaker: PropTypes.func,
};
