import {
  Typography,
  Container,
  Grid,
  Box,
  Button,
  Card,
  useMediaQuery,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Trackable, useTracker } from '@dpdgroupuk/react-event-tracker';
import { useState, useEffect, useContext, useCallback } from 'react';
import { getStats } from '../../apis/green.js';
import { WASTE_RECYCLING } from '../../data/analytics.js';
import { StatsContext } from '../../context/statsContext.jsx';

import {
  REDUCE,
  REUSE,
  RECYCLE,
  AVOIDING_LANDFILL,
} from '../../router/routes.js';

import wasteRecyclingHeader from '../../images/WasteRecycling/wasteRecyclingHeader.png';
import reduceIcon from '../../images/WasteRecycling/reduceIcon.png';
import reuseIcon from '../../images/WasteRecycling/reuseIcon.png';
import recycleIcon from '../../images/WasteRecycling/recycleIcon.png';
import landfillIcon from '../../images/WasteRecycling/landfillIcon.png';

import theme from '../../theme';
import PageHeader from '../../components/pageHeader/PageHeader.jsx';
import usePageWidth from '../../utils/usePageWidth.js';
import usePadding from '../../utils/usePadding.js';

const WasteRecycling = () => {
  const tracker = useTracker();

  const statsContext = useContext(StatsContext);
  const [strategyStats, setStrategyStats] = useState([
    {
      title: '% of Waste Avoiding Landfill',
      stat: '00.00%',
    },
    {
      title: '% of Waste Reused',
      stat: '00.00%',
    },
    {
      title: '% of Waste Recycled',
      stat: '00.00%',
    },
    {
      title: 'Eco Fund Donations',
      stat: '£000,000',
    },
  ]);

  const getGreenStats = useCallback(async () => {
    const contextStats = statsContext.stats;
    const setContextStats = statsContext.setStats;
    if (!contextStats) {
      const newStats = await getStats();
      setContextStats(newStats);
      setStrategyStats([
        {
          title: '% of Waste Avoiding Landfill',
          stat: newStats.waste_avoiding_landfill,
        },
        {
          title: '% of Waste Reused',
          stat: newStats.waste_reused,
        },
        {
          title: '% of Waste Recycled',
          stat: newStats.waste_recycled,
        },
        {
          title: 'Eco Fund Donations',
          stat: newStats.eco_fund_donations,
        },
      ]);
    } else {
      setStrategyStats([
        {
          title: '% of Waste Avoiding Landfill',
          stat: contextStats.waste_avoiding_landfill,
        },
        {
          title: '% of Waste Reused',
          stat: contextStats.waste_reused,
        },
        {
          title: '% of Waste Recycled',
          stat: contextStats.waste_recycled,
        },
        {
          title: 'Eco Fund Donations',
          stat: contextStats.eco_fund_donations,
        },
      ]);
    }
  }, [statsContext]);

  useEffect(() => {
    getGreenStats();
  }, [getGreenStats]);

  const moreCards = [
    {
      icon: reduceIcon,
      title: 'Reduce',
      href: REDUCE,
      trackerId: WASTE_RECYCLING.CLICK_REDUCE,
    },
    {
      icon: reuseIcon,
      title: 'Reuse',
      href: REUSE,
      trackerId: WASTE_RECYCLING.CLICK_REUSE,
    },
    {
      icon: recycleIcon,
      title: 'Recycle',
      href: RECYCLE,
      trackerId: WASTE_RECYCLING.CLICK_RECYCLE,
    },
    {
      icon: landfillIcon,
      title: 'Avoiding Landfill',
      href: AVOIDING_LANDFILL,
      trackerId: WASTE_RECYCLING.CLICK_AVOIDING_LANDFILL,
    },
  ];

  const navigate = useNavigate();

  const goToPage = href => {
    navigate(href);
    window.scrollTo(0, 0);
  };

  return (
    <Trackable
      interfaceId={WASTE_RECYCLING.INTERFACE_ID}
      loadId={WASTE_RECYCLING.ON_LOAD}
    >
      <PageHeader image={wasteRecyclingHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Waste & Recycling
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Reduce, Reuse, Recycle
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 6 : 30,
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h3Light'
                : 'h2Light'
            }
            gutterBottom
            color={theme.palette.primary.green}
          >
            Our strategy includes recycling, responsible packaging and circular
            economy initiatives.
          </Typography>
          <Typography variant="h4Light" gutterBottom color="#000">
            We hate waste and ensure what little we do have is disposed of
            responsibly, with 100% of our waste avoiding landfill.
          </Typography>
          <Card
            sx={{
              backgroundColor: '#FFF',
              boxShadow: '0px 3px 5px 1px rgba(0, 0, 0, 0.2)',
              marginTop: 2,
            }}
          >
            <Grid
              container
              justifyContent="space-around"
              alignItems="flex-end"
              sx={{ py: 4, px: 2 }}
            >
              {strategyStats.map(stat => (
                <Grid item key={stat.title} xs={12} sm={6} md={3}>
                  <Typography
                    variant="h6"
                    color={theme.palette.primary.accentGreen}
                  >
                    {stat.title}
                  </Typography>
                  {stat.subtext && (
                    <Typography
                      variant="h6"
                      color={theme.palette.primary.accentGreen}
                    >
                      {stat.subtext}
                    </Typography>
                  )}
                  <Typography
                    variant="h3Light"
                    color={theme.palette.primary.citySelectorGreenOne}
                    sx={{
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.primary.accentGreen,
                      textDecorationThickness: 1,
                      textUnderlineOffset: 18,
                      pb: 2,
                      fontSize: '28px',
                    }}
                  >
                    {stat.stat}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant={
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'h3Light'
                : 'h2Light'
            }
            gutterBottom
            color={theme.palette.primary.green}
          >
            Find out more about what we're doing about waste & recycling
          </Typography>
          <Grid container spacing={2}>
            {moreCards.map(card => (
              <Grid item md={6} xs={12} key={card.title}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.citySelectorGreenOne,
                    borderRadius: 1,
                    px: 2,
                    py: 4,
                    height: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      component="img"
                      src={card.icon}
                      sx={{ height: 50, width: 50, mb: 2 }}
                    />
                  </Box>
                  <Typography
                    variant="h2Light"
                    color="#FFF"
                    textAlign="center"
                    gutterBottom
                  >
                    {card.title}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: '#FFF',
                        borderRadius: 1,
                        py: '15px',
                        px: '30px',
                        '&:hover': {
                          borderColor: '#FFF',
                          boxShadow: 1,
                        },
                      }}
                      onClick={() => {
                        goToPage(card.href);
                        tracker.logEvent(card.trackerId);
                      }}
                    >
                      <Typography variant="button" color="#FFF">
                        Find Out More
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Trackable>
  );
};

export default WasteRecycling;
