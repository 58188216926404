import { Typography, Container, Box, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { RECYCLE } from '../../../data/analytics';
import { RECYCLE_VIDEO } from '../../../data/links';

import recycleHeader from '../../../images/WasteRecycling/recycleHeader.png';
import recycleBoxes from '../../../images/WasteRecycling/recycleBoxes.png';
import recyclePallets from '../../../images/WasteRecycling/recyclePallets.png';
import recycleBoots from '../../../images/WasteRecycling/recycleBoots.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';
import BoxOnBackground from '../../../components/boxOnBackground/BoxOnBackground.jsx';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

const Recycle = () => {
  return (
    <Trackable interfaceId={RECYCLE.INTERFACE_ID} loadId={RECYCLE.ON_LOAD}>
      <PageHeader image={recycleHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Waste & Recycling
        </Typography>
        <Typography variant="h1" color="#FFF" gutterBottom>
          Recycle
        </Typography>
      </PageHeader>
      <SideBySide
        image={recycleBoxes}
        imageSide="right"
        background={theme.palette.primary.background}
        verticalPadding={25}
        mobileDirection="column-reverse"
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Through an industry leading initiative, all our shrink wrap used in
          our hubs is now collected and then reprocessed into raw plastic
          material.
        </Typography>
        <Typography variant="h4Light" color="#000">
          This is sold and the money generated is invested in a range of green
          initiatives through our Eco Fund. We have now passed £1,000,000 raised
          for the fund since its inception.
        </Typography>
      </SideBySide>
      <BoxOnBackground
        background={recyclePallets}
        boxSide="right"
        verticalPadding={30}
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color="#FFF"
          gutterBottom
        >
          Any damaged pallets that cannot be repaired from around our network
          are turned into animal bedding.
        </Typography>
        <Typography variant="h5Light" color="#FFF">
          The revenue from this waste stream also goes into the DPD Eco Fund.
        </Typography>
      </BoxOnBackground>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: 20,
          background: theme.palette.primary.citySelectorGreenOne,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h3Light"
            color="#FFF"
            textAlign="center"
            gutterBottom
          >
            We also operate circular economies in cardboard, rigid plastic,
            vehicle parts and oil, batteries, paper and furniture.
          </Typography>
          <Typography
            variant="body1"
            color="#FFF"
            textAlign="center"
            gutterBottom
          >
            These circular economies see our waste reprocessed back into the
            original material and account for 88% of our total recycling.
          </Typography>
          <iframe
            title={RECYCLE_VIDEO}
            src={RECYCLE_VIDEO}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={
              useMediaQuery(theme.breakpoints.down('md')) ? '200px' : '550px'
            }
            width="100%"
          />
        </Box>
      </Container>
      <SideBySide
        image={recycleBoots}
        imageSide="right"
        background={theme.palette.secondary.background}
        verticalPadding={25}
        mobileDirection="column-reverse"
      >
        <Typography
          variant={
            useMediaQuery(theme.breakpoints.down('md')) ? 'h3Light' : 'h2Light'
          }
          color={theme.palette.primary.green}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          Our recycling approach extends to our uniform, which includes a
          sustainable safety footwear option.
        </Typography>
        <Typography variant="h4Light" color="#000">
          This footwear has sustainability at its core. The range boasts upper
          textiles, lining and lace materials all made from recycled plastic
          bottles, each pair containing up to 12 bottles worth of recycled
          plastic.
        </Typography>
      </SideBySide>
    </Trackable>
  );
};

export default Recycle;
